import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getInternationalList } from "../adapter";
import { GetInternationalInProgress } from "../api";

export const useInternationalInProgressList = () => {
  const initialData = {
    data: getInternationalList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["in_progress_international"],
    queryFn: () => GetInternationalInProgress(),
    select: (data) => ({
      data: getInternationalList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
