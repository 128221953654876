import { Outlet, useLocation, useResolvedPath, Link } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "./ui/breadcrumb";

export default function createBreadcrumbs() {
  return [
    // {
    //   path: "/",
    //   element: <Breadcrumbs text="E'lonlar" />,
    //   children: [
    //     {
    //       path: "courses",
    //       element: <Breadcrumbs text="Videolar" />,
    //     },
    //     {
    //       path: "grandmasters",
    //       element: <Breadcrumbs text="Grandmasterlar" />,
    //     },
    //     {
    //       path: "/news",
    //       element: <Breadcrumbs text="Yangiliklar" />,
    //     },
    //     {
    //       path: "/books",
    //       element: <Breadcrumbs text="Kitoblar" />,
    //     },
    //     {
    //       path: "/tournaments",
    //       element: <Breadcrumbs text="Musobaqalar" />,
    //       children: [
    //         {
    //           path: ":tournamentId",
    //           element: <Breadcrumbs text="Musobaqa ishtirokchilari" />,
    //         },
    //       ],
    //     },
    //     {
    //       path: "/categories",
    //       element: <Breadcrumbs text="Video Kategoriylar" />,
    //     },
    //     {
    //       path: "/services",
    //       element: <Breadcrumbs text="Xizmatlar" />,
    //       children: [
    //         {
    //           path: "laboratoriyalar",
    //           element: <Breadcrumbs text="Biolaboratoriyalar" />,
    //         },
    //         {
    //           path: "telegram",
    //           element: <Breadcrumbs text="Telegram havolalar" />,
    //         },
    //         {
    //           path: "about",
    //           element: <Breadcrumbs text="Biz haqimizda" />,
    //         },
    //         {
    //           path: "tuproq",
    //           element: <Breadcrumbs text="Tuproq malumotlar" />,
    //         },
    //         {
    //           path: "eksportchi",
    //           element: <Breadcrumbs text="Eksportchi korxonalar" />,
    //         },
    //         {
    //           path: "inspektor",
    //           element: <Breadcrumbs text="Inspektorlar" />,
    //         },
    //       ],
    //     },
    //     {
    //       path: "/informations",
    //       element: <Breadcrumbs text="Ma'lumotlar" />,
    //       children: [
    //         {
    //           path: "/informations/file/:infoId",
    //           element: <Breadcrumbs text="File" />,
    //         },
    //         {
    //           path: "/informations/types/:infoId",
    //           element: <Breadcrumbs text="Turi" />,
    //         },
    //         {
    //           path: ":courseId",
    //           element: <Breadcrumbs text="Bo'limlar" />,
    //           children: [
    //             {
    //               path: ":moduleId",
    //               element: <Breadcrumbs text="Tavsiyalar" />,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
}

function Breadcrumbs({ text }: { text: string }) {
  const location = useLocation();
  const resolvedLocation = useResolvedPath("");
  const isActive = location.pathname === resolvedLocation.pathname;

  return (
    <div className="flex gap-1">
      <Breadcrumb>
        <BreadcrumbList>
          {isActive ? (
            <BreadcrumbItem>
              <BreadcrumbPage>{text}</BreadcrumbPage>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={resolvedLocation.pathname}>{text}</Link>
              </BreadcrumbLink>
              <BreadcrumbSeparator />
            </BreadcrumbItem>
          )}
        </BreadcrumbList>
      </Breadcrumb>
      <Outlet />
    </div>
  );
}

// function DynamicBreadcrumbs() {
//   const params = useParams();
//   const { organizationId } = params;
//   // fetch your data any way you want
//   // here I'm using react-query
//   const { data } = useQuery([{ id: organizationId }], api.fetchOrganization);
//   const organization = data || { name: '' };
//   const text = organization.name || '-';
//   return <Breadcrumbs text={text} />;
// }
