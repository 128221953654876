import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";

import { CreateGiantExporters } from "../api";
import { GiantExportersCreate } from "../type";

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateGiantExporters = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: GiantExportersCreate) => CreateGiantExporters(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: " muvaffaqiyatli yaratildi.",
      });
      queryClient.invalidateQueries({ queryKey: ["giant_exporters_list"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerGiantExportersCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
