import { LabAddress, LabGet, LabList } from './type';

export const getAddress = (item?: LabAddress) => {
  return {
    region: item?.region ?? '',
    district: item?.district ?? '',
    neighborhood: item?.neighborhood ?? '',
    address: item?.address ?? '',
    region_id: item?.region_id ?? 0,
    district_id: item?.district_id ?? 0,
    neighborhood_id: item?.neighborhood_id ?? 0,
  };
};

const getLab = (item?: LabList) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    region: item?.region ?? '',
    phone: item?.phone ?? '',
    trixogramma: item?.trixogramma ?? '',
    brakon: item?.brakon ?? '',
    address: getAddress(item?.address),
    owner: item?.owner ?? '',
    oltin_kuz: item?.oltin_kuz ?? '',
    tin_number: item?.tin_number ?? '',
  };
};

export const getLabData = (data?: LabList[]) => {
  return data?.length
    ? data.map(item => {
        return getLab(item);
      })
    : [];
};

export const getLabList = (data?: LabGet) => {
  return {
    count: data?.count ?? 0,
    total_pages: data?.total_pages ?? 0,
    current_page: data?.current_page ?? 1,
    data: getLabData(data?.data),
  };
};
