import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getInternationalList } from "../adapter";
import { GetInternationalReject } from "../api";

export const useInternationalRejectList = () => {
  const initialData = {
    data: getInternationalList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["reject_international"],
    queryFn: () => GetInternationalReject(),
    select: (data) => ({
      data: getInternationalList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
