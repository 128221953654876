import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { EditOurCustomers } from "../api";
import { OurCustomers, OurCustomersEditBody } from "../type";

interface IHook {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditOurCustomers = ({ id = "", setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: OurCustomersEditBody | OurCustomers | any) =>
      EditOurCustomers({ values, id }),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Yangilik muvaffaqiyatli tahrirlandi.",
      });
      queryClient.invalidateQueries({ queryKey: ["our_customers"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerOurCustomersEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
