import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FileField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";
import { Form } from "components/ui/form";
import useFileUploader from "hooks/useFileUploader";
import { OurCustomers } from "modules/our-customers/type";
import { useEditOurCustomers } from "modules/our-customers/hooks/useEditOurCustomers";
import { useCreateOurCustomers } from "modules/our-customers/hooks/useCreateOurCustomers";

const ourCustomersSchema = z.object({
  name: z.string(),
  image: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Rasm talab qilinadi",
    }),
    z.string().min(1, { message: "Rasm talab qilinadi" }),
  ]),
});

type ourCustomersFormSchema = z.infer<typeof ourCustomersSchema>;

interface IProps {
  ourCustomers?: OurCustomers;
  setSheetOpen: (state: boolean) => void;
}
export default function OurCustomersForm({
  ourCustomers,
  setSheetOpen,
}: IProps) {
  const { uploadFile } = useFileUploader();

  const { triggerOurCustomersCreate, isPending: isNewsCreatePending } =
    useCreateOurCustomers({
      setSheetOpen,
    });
  const { triggerOurCustomersEdit, isPending: isNewsEditPending } =
    useEditOurCustomers({
      id: ourCustomers?._id,
      setSheetOpen,
    });

  const form = useForm<ourCustomersFormSchema>({
    resolver: zodResolver(ourCustomersSchema),
    defaultValues: ourCustomers
      ? {
          name: ourCustomers.name,
          image: ourCustomers.image,
        }
      : {
          image: undefined,
        },
  });

  async function onSubmit(formValues: ourCustomersFormSchema) {
    const values = await uploadFile<any>(formValues, "image");

    if (ourCustomers) {
      triggerOurCustomersEdit(values);
    } else {
      await triggerOurCustomersCreate(values);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="name" label="Hamkor websiti" required />
          <FileField name="image" label="Hamkor rasmi" required />

          <LoadingButton isLoading={isNewsCreatePending || isNewsEditPending}>
            Saqlash
          </LoadingButton>
        </form>
      </Form>
    </>
  );
}
