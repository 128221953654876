import { useMutation } from "@tanstack/react-query";
import { queryClient } from "services/react-query";
import { useToast } from "components/ui/use-toast";
import { showErrorToast } from "utils/showErrorToast";
import { DelteLocalAds } from "../api";

export const useDeleteLocalAds = (id: string) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DelteLocalAds(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ["in_progress_locals"] });
      queryClient.invalidateQueries({ queryKey: ["reject_locals"] });
      queryClient.invalidateQueries({ queryKey: ["aprove_locals"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return { triggerLocalAdsDelete: mutate, isSuccess, isError };
};
