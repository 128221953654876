import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { CreateTopCountries, CreateTopProducts } from "../api";
import { TopCountriesCreate, TopProductsCreate } from "../types";

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

//top-products*******************************************************
export const useCreateTopProducts = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: TopProductsCreate) => CreateTopProducts(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success!",
        description: "The top product was successfully created.",
      });
      queryClient.invalidateQueries({ queryKey: ["top_products"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerTopProductsCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};

//top-countries*******************************************************
export const useCreateTopCountries = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: TopCountriesCreate) => CreateTopCountries(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success!",
        description: "The top countries was successfully created.",
      });
      queryClient.invalidateQueries({ queryKey: ["top_countries"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerTopCountriesCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
