import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getAboutList } from '../adapter';
import { GetAboutList } from '../api';

export const useAboutList = () => {
  const initialData = {
    data: getAboutList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['about_list'],
    queryFn: () => GetAboutList(),
    select: data => ({
      data: getAboutList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
