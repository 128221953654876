import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getInformationsList } from '../adapters';
import { GetInformationsList } from '../api';

export const useInformationList = () => {
  const initialData = {
    data: getInformationsList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['info_list'],
    queryFn: () => GetInformationsList(),
    select: data => ({
      data: getInformationsList(get(data, 'data.data')),
      paginationInfo: get(data, 'data.pagination'),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
