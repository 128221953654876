import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { Button } from 'components/ui/button';
import { Download } from 'lucide-react';
import { Book } from 'modules/books/types';
import normalizeFileUrl from 'utils/normalizeFileUrl';

interface IProps {
  getRowData: (book: Book) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createBookColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Book>[] => [
    {
      accessorKey: 'title',
      header: 'Kitob nomi',
    },
    {
      accessorKey: 'image',
      header: 'Kitob rasmi',
      cell: ({ row }) => {
        const imageUrl = row.getValue<string>('image');
        const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
        return imageUrl ? (
          <img src={fullImageUrl} alt="books" style={{ width: 50, height: 50 }} />
        ) : (
          <span>Rasm mavjud emas</span>
        );
      },
    },
    {
      accessorKey: 'file',
      header: "Fayl havolasi",
      cell: ({ row }) => (
        <a
          href={normalizeFileUrl(row.getValue('file'))}
          className="hover:underline text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button size="icon">
            <Download className="w-4 h-4 " />
          </Button>
        </a>
      ),
    },
    {
      accessorKey: 'rating',
      header: 'Reytingi',
    },
    {
      accessorKey: 'pages',
      header: 'Sahifasi',
    },
    {
      accessorKey: '_id',
      header: () => <span className="sr-only">Actions</span>,
      size: 50,
      cell: ({ row }) => (
        <DataTableRowActions
          row={row}
          getRowData={getRowData}
          setDialogOpen={setDialogOpen}
          setSheetOpen={setSheetOpen}
        />
      ),
    },
  ];
