import { AlertDialog } from "components/AlertDialog";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { Sheet } from "components/Sheet";
import { TableActions } from "components/TableActions";
import { useDeleteActivity } from "modules/activity/hooks/useDeleteActivity";
import { useGetActivityList } from "modules/activity/hooks/useGetActivityList";
import { Activity } from "modules/activity/type";

import { useState } from "react";
import { createActivityColumns } from "./Coulmns";
import ActivityForm from "./ActivityForm";

const Activities = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [activities, setActivities] = useState<Activity>();

  const { data: activityList, isLoading } = useGetActivityList();
  const { triggerActivityDelete } = useDeleteActivity(activities?._id!);

  const getRowData = (activityState: Activity) => {
    setActivities(activityState);
  };

  const columns = createActivityColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Faoliyatlar ({activityList?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Faoliyat turi qo'shish"
          sheetTitle="Yangi Faoliyat turi qo'shish."
          TableForm={ActivityForm}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={activityList} />
      )}

      <Sheet
        sheetTitle="malumot tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <ActivityForm setSheetOpen={setSheetOpen} activtiy={activities} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerActivityDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Activities;
