import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";
import { Form } from "components/ui/form";
import { useCreateTopCountries } from "modules/statistics/hooks/useCreateStatistics";
import { useEditTopCountries } from "modules/statistics/hooks/useEditStatistics";
import { TopCountries } from "modules/statistics/types";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import TopCountriesRichInputs from "../multiRichInputs/TopCountriesRichInput";

export const formSchema = z.object({
  export_year: z
    .number()
    .min(1900, { message: "Export year must be greater than or equal to 1900" })
    .max(new Date().getFullYear(), {
      message: "Export year cannot be in the future",
    })
    .nonnegative({ message: "Export year is required" }),

  export_quarter: z
    .number()
    .min(1, { message: "Quarter must be at least 1" })
    .max(4, { message: "Quarter must be at most 4" })
    .nonnegative({ message: "Export quarter is required" }),

  country: z
    .array(
      z.object({
        country_name: z
          .string()
          .min(1, { message: "Country name is required" }),
        country_quantity: z
          .number()
          .positive({ message: "Country quantity must be greater than zero" }),
        country_unit: z
          .string()
          .min(1, { message: "Country unit is required" }),
        export_amount: z
          .number()
          .positive({ message: "Export amount must be greater than zero" }),
        export_unit: z.string().min(1, { message: "Export unit is required" }),
      })
    )
    .min(1, { message: "At least one country is required" }),
});

type topCountriesFormSchema = z.infer<typeof formSchema>;

interface IProps {
  topCountries?: TopCountries;
  setSheetOpen: (state: boolean) => void;
}

export const TopCountriesForm = ({ topCountries, setSheetOpen }: IProps) => {
  const { triggerTopCountriesCreate, isPending: isTopCountriesCreatePending } =
    useCreateTopCountries({
      setSheetOpen,
    });

  const { triggerTopCountriesEdit, isPending: isTopCountriesEditPending } =
    useEditTopCountries({
      id: topCountries?._id,
      setSheetOpen,
    });

  const form = useForm<topCountriesFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: topCountries
      ? {
          export_year: topCountries.export_year,
          export_quarter: topCountries.export_quarter,
          country: topCountries.country.map((country: any) => ({
            country_name: country.country_name,
            country_quantity: country.country_quantity,
            country_unit: country.country_unit,
            export_amount: country.export_amount,
            export_unit: country.export_unit,
          })),
        }
      : {
          export_year: undefined,
          export_quarter: undefined,
          country: [
            {
              country_name: "",
              country_quantity: undefined,
              country_unit: "",
              export_amount: undefined,
              export_unit: "",
            },
          ],
        },
  });

  async function onSubmit(formValues: topCountriesFormSchema | any) {
    if (topCountries) {
      triggerTopCountriesEdit(formValues);
    } else {
      await triggerTopCountriesCreate(formValues);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <TextField
          type="number"
          name="export_year"
          label="Eksport yili"
          required
        />
        <TextField
          type="number"
          name="export_quarter"
          label="Eksport choragi"
          required
        />
        <TopCountriesRichInputs name="country" addBtnTitle="Add Country" />
        <LoadingButton
          isLoading={isTopCountriesCreatePending || isTopCountriesEditPending}
        >
          Save
        </LoadingButton>
      </form>
    </Form>
  );
};
