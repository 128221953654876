export interface International {
  _id: string; // Unique identifier
  name: string; // Country name
  url: string; // URL for more information
  flag: string; // URL for country flag image
}

export interface InternationalCreate {
  _id: string; // Unique identifier
  name: string; // Country name
  url: string; // URL for more information
  flag: string; // URL for country flag image
}

export interface InternationalEditBody {
  id: string; // ID of the international entry
  values: InternationalCreate; // Values for editing
}

export enum STATUS {
  ACTIVE = "approved",
  INPROGRESS = "in progress",
  REJECTED = "rejected",
}
