import { useState } from "react";
import { Course } from "modules/courses/types";
import { useCoursesList } from "modules/courses/hooks/useCoursesList";
import { useDeleteCourse } from "modules/courses/hooks/useDeleteCourse";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { TableActions } from "components/TableActions";
import { AlertDialog } from "components/AlertDialog";
import { Sheet } from "components/Sheet";
import CourseForm from "./CourseForm";
import { createCourseColumns } from "./Columns";

import { useParams } from "react-router-dom";

const Courses = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [course, setCourse] = useState<Course>();
  const [currentPage] = useState(1);

  const { moduleId } = useParams();
  const {
    data: coursesList,

    isLoading,
  } = useCoursesList(moduleId!, currentPage);
  const { triggerCourseDelete } = useDeleteCourse(course?._id!);

  const lastCourseOrder = coursesList.at(-1)?.order;

  const getRowData = (course: Course) => {
    setCourse(course);
  };

  const columns = createCourseColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Mahalliy E'lonlar ({coursesList?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Video qo'shish"
          sheetTitle="Yangi video qo'shish."
          lastDataOrder={lastCourseOrder}
          TableForm={CourseForm}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={coursesList} />
          {/* <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          /> */}
        </>
      )}

      <Sheet
        sheetTitle="Videoni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <CourseForm course={course} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerCourseDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Courses;
