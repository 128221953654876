import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Form } from "components/ui/form";
import { SelectField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";

import { categories } from "constants/general";
import { useEditInternational } from "modules/international-ads/hooks/useEditLocalAds";
import { useCreateInternational } from "modules/international-ads/hooks/useCreateLocalAds";

// Updated validation schema
export const internationalAdsSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Country name must be at least 3 characters" }),
  url: z.string().url().optional(), // Optional URL field
  flag: z.string().optional(), // Optional flag field
});

type internationalAdsFormSchema = z.infer<typeof internationalAdsSchema>;

interface IProps {
  internationalAds?: internationalAdsFormSchema | any;
  setSheetOpen: (state: boolean) => void;
}

export default function InternationalForm({
  internationalAds,
  setSheetOpen,
}: IProps) {
  const { triggerInternationalCreate, isPending: isCreatePending } =
    useCreateInternational({
      setSheetOpen,
    });

  const { triggerInternationalEdit, isPending: isEditPending } =
    useEditInternational({
      id: internationalAds?._id,
      setSheetOpen,
    });

  const form = useForm<internationalAdsFormSchema>({
    resolver: zodResolver(internationalAdsSchema),

    defaultValues: internationalAds
      ? {
          name: internationalAds.name ?? "",
          url: internationalAds.url ?? "",
          flag: internationalAds.flag ?? "",
        }
      : {
          name: "",
          url: "",
          flag: "",
        },
  });

  async function onSubmit(formValues: internationalAdsFormSchema | any) {
    if (internationalAds) {
      triggerInternationalEdit({
        ...formValues,
      });
    } else {
      triggerInternationalCreate({
        ...formValues,
      });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="name" label="Kompaniya nomi" required />
        <TextField name="url" label="URL" />
        <TextField name="flag" label="Flag URL" />

        {internationalAds ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
