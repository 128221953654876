import { getAddress } from "modules/lab/adapter";
import { PackingCompaniesData, PackingCompaniesList } from "./type";

export const getPackingCompanies = (item?: PackingCompaniesList) => {
  return {
    _id: item?._id ?? "",
    name: item?.name ?? "",
    owner: item?.owner ?? "",
    phone: item?.phone ?? "",
    product_name: item?.product_name ?? "",
    sorting_line_availability: item?.sorting_line_availability ?? false,
    warehouse: {
      is_available: item?.warehouse?.is_available ?? false,
      volume: item?.warehouse?.volume ?? "",
      unit: item?.warehouse?.unit ?? "",
    },
    refrigerator: {
      is_available: item?.refrigerator?.is_available ?? false,
      volume: item?.refrigerator?.volume ?? "",
      unit: item?.refrigerator?.unit ?? "",
    },
    address: getAddress(item?.address),
  };
};

export const getPackingCompaniesList = (data?: PackingCompaniesList[]) => {
  return data?.length
    ? data.map((item) => {
        return getPackingCompanies(item);
      })
    : [];
};

export const getPackingCompaniesData = (data?: PackingCompaniesData) => {
  return {
    count: data?.count ?? 0,
    current_page: data?.current_page ?? 0,
    total_pages: data?.total_pages ?? 0,
    data: getPackingCompaniesList(data?.data),
  };
};
