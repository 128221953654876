import { useDeleteFermerFeedbacks } from "modules/fermer-feedbacks/hooks/useDeleteFermerFeedbacks";
import { useGetFermerFeedbacksList } from "modules/fermer-feedbacks/hooks/useFermerFeedbacksList";
import { FermerFeedbacks } from "modules/fermer-feedbacks/type";
import { useState } from "react";
import { createFermerFeedbacksColumns } from "./Columns";
import { TableActions } from "components/TableActions";
import { AlertDialog } from "components/AlertDialog";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import FermerFeedbacksForm from "./FermerFeedbacksForm";

export default function FermerFeedbacksPage() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [fermerFeedbacks, setFermerFeedbacks] = useState<FermerFeedbacks>();

  const { data: fermerFeedbacksList, isLoading } = useGetFermerFeedbacksList();
  const { triggerFermerFeedbacksDelete } = useDeleteFermerFeedbacks(
    fermerFeedbacks?._id!
  );

  const getRowData = (fermerFeedbacks: FermerFeedbacks) => {
    setFermerFeedbacks(fermerFeedbacks);
  };

  const columns = createFermerFeedbacksColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Video Qo'shish ({fermerFeedbacksList?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Video qo'shish"
          sheetTitle="Video qo'shish."
          TableForm={FermerFeedbacksForm}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={fermerFeedbacksList} />
        </>
      )}
      <Sheet
        sheetTitle="Videoni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <FermerFeedbacksForm
          fermerFeedbacks={fermerFeedbacks}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerFermerFeedbacksDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
}
