import http from 'services/api';
import { TelegramCreate, TelegramEdit } from './type';

export const GetTelegramList = async () => {
  return await http.get(`agro-service/tg-groups`);
};

export const DelteTelegram = async (id: string) => {
  return await http.delete(`agro-service/tg-group/${id}`);
};

export const CreateTelegram = async (values: TelegramCreate) => {
  return await http.post(`agro-service/tg-group`, values);
};
export const EditTelegram = async ({ id, values }: TelegramEdit) => {
  return await http.patch(`/agro-service/tg-groups/${id}`, values);
};
