import http from "services/api";
import { InternationalCreate, InternationalEditBody, STATUS } from "./type";

export const GetInternationalInProgress = async () => {
  return await http.get(
    `/market/ads/international?status=${STATUS.INPROGRESS}`
  );
};
export const InternationalAcept = async (id: string) => {
  return await http.patch(`market/ads/international/${id}/approve`);
};
export const CreateInternational = async (data: InternationalCreate) => {
  return await http.post(`/market/ads/international`, data);
};
export const InternationalReject = async (
  id: string,
  reject_reason: string
) => {
  return await http.patch(`market/ads/international/${id}/reject`, {
    reject_reason: reject_reason,
  });
};
export const GetInternationalAprove = async () => {
  return await http.get(`market/ads/international?status=${STATUS.ACTIVE}`);
};
export const DelteInternational = async (id: string) => {
  return await http.delete(`market/ads/international/${id}`);
};
export const EditInternational = async ({
  id,
  values,
}: InternationalEditBody) => {
  return await http.patch(`market/ads/international/${id}`, values);
};
export const GetInternationalReject = async () => {
  return await http.get(`/market/ads/international?status=${STATUS.REJECTED}`);
};
