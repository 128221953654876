import { ColumnDef } from '@tanstack/react-table';
import { Users } from 'modules/user/type';

export const createUsersColumns = (): ColumnDef<Users>[] => [
  {
    accessorKey: 'fio',
    header: 'Ismi',
  },
  {
    accessorKey: 'phone',
    header: 'Telefon raqami',
  },
  {
    accessorKey: 'sex',
    header: 'Jinsi',
  },
  {
    accessorKey: 'birth_date',
    header: `Tug'ilgan sanasi`,
  },
];
