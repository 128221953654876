import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getMarketList } from '../adapter';
import { GetMarketInProgress } from '../api';

export const useMarketInProgressList = () => {
  const initialData = {
    data: getMarketList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['in_progress'],
    queryFn: () => GetMarketInProgress(),
    select: data => ({
      data: getMarketList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
