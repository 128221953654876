import http from "services/api";
import { GiantExportersCreate } from "./type";

export const GetGiantExportersList = async (currentPage: number) => {
  return await http.get(
    `agro-service/giant-exporter-companies?page=${currentPage}&size=10`
  );
};

export const DelteGiantExporters = async (id: string) => {
  return await http.delete(`agro-service/giant-exporter-companies/${id}`);
};

export const CreateGiantExporters = async (values: GiantExportersCreate) => {
  return await http.post(`agro-service/giant-exporter-companies`, values);
};
