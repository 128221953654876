import http from 'services/api';
import { SoilCreate, SoilEdit } from './type';

export const GetSoilList = async (currentPage: number) => {
  return await http.get(
    `agro-service/tuproq-tahlili?page=${currentPage}&size=10`
  );
};
export const CreateSoil = async (values: SoilCreate) => {
  return await http.post(`agro-service/tuproq-tahlili`, values);
};

export const EditSoil = async ({ values, id }: SoilEdit) => {
  return await http.patch(`agro-service/tuproq-tahlili/${id}`, values);
};

export const DeleteSoil = async (id: string) => {
  return await http.delete(`agro-service/tuproq-tahlili/${id}`);
};
