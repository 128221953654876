import { SoilData, SoilList } from './type';
import { getAddress } from '../lab/adapter';

export const getSoil = (item?: SoilList) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    owner: item?.owner ?? '',
    phone: item?.phone ?? '',
    address: getAddress(item?.address),
  };
};

export const getSoilList = (data?: SoilList[]) => {
  return data?.length
    ? data.map(item => {
        return getSoil(item);
      })
    : [];
};

export const getSoilData = (data?: SoilData) => {
  return {
    count: data?.count ?? 0,
    current_page: data?.current_page ?? 0,
    total_pages: data?.total_pages ?? 0,
    data: getSoilList(data?.data),
  };
};
