import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { VideoCreate } from '../type';
import { EditVideoCategory } from '../api';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHooks {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditVideoCategory = ({ id = '', setSheetOpen }: IHooks) => {
  const { toast } = useToast();
  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: VideoCreate) => EditVideoCategory({ id, values }),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Muvaffaqiyatli tahrirlandi.',
      });
      queryClient.invalidateQueries({ queryKey: ['video_category'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });
  return {
    triggerEditVideoCategory: mutate,
    isError,
    isPending,
    isSuccess,
  };
};
