import { GrandInfoHeadline, Grandinfo } from './types';

const getHeadlines = (item?: GrandInfoHeadline) => {
  return {
    title: item?.title ?? '',
    description: item?.description ?? '',
  };
};

export const getGrandInfo = (item?: Grandinfo) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    description: item?.description ?? '',
    images: item?.images ?? '',
    headlines: item?.headlines.map(item => getHeadlines(item)) ?? [],
    parent_information: item?.parent_information ?? '',
  };
};

export const getGrandInfoList = (data?: Grandinfo[]) => {
  return data?.length
    ? data.map(item => {
        return getGrandInfo(item);
      })
    : [];
};
