import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Form } from "components/ui/form";
import { TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";

import { GiantExportersList } from "modules/giant-exporters/type";

import { useGetReegionList } from "modules/region/hooks/useGetRegionList";
import AddressFilterField from "components/fields/AddressFilter";
import { useGetDistrictsList } from "modules/districts/hooks/useGetDistrictsList";
import { useGetNeighborhoodList } from "modules/neighborhood/hooks/useGetNeighborhoodList";
import { useCreateGiantExporters } from "modules/giant-exporters/hooks/useCreateInspector";

export const addressSchema = z.object({
  region: z.string().min(1, { message: "Region is required" }),
  district: z.string().min(1, { message: "District is required" }),
  address: z.string().min(3, { message: "Address is required" }),
  neighborhood: z.string().min(1, { message: "Neighborhood is required" }),
  region_id: z.number().min(1),
  district_id: z.number().min(1),
  neighborhood_id: z.number().min(1),
});
export const editAdrressSvhema = z.object({
  name: z.string().optional(),
  code: z.number().optional(),
});

const giantExportersSchema = z.object({
  address: addressSchema,
  name: z.string().min(3, { message: "Company name is required" }),
  phone: z
    .string()
    .min(9, { message: "Phone number is required" })
    .max(12, { message: "Phone number must be between 9 and 12 characters" }),
  owner: z.string(),
  product_name: z.string(),
  export_country: z.string(),
  regionEdit: editAdrressSvhema.optional(),
  districtEdit: editAdrressSvhema.optional(),
  neigborhoodEdit: editAdrressSvhema.optional(),
});

type giantExportersFormSchema = z.infer<typeof giantExportersSchema>;

interface IProps {
  giantExporters?: GiantExportersList;
  setSheetOpen: (state: boolean) => void;
}

export default function GiantExportersForm({
  giantExporters,
  setSheetOpen,
}: IProps) {
  const { triggerGiantExportersCreate, isPending: isCreatePending } =
    useCreateGiantExporters({
      setSheetOpen,
    });

  const form = useForm<giantExportersFormSchema>({
    resolver: zodResolver(giantExportersSchema),
    defaultValues: giantExporters
      ? {
          name: giantExporters.name ?? "",
          phone: giantExporters.phone ?? "",
          address: {
            region: giantExporters.address.region ?? "",
            region_id: giantExporters.address.region_id ?? 0,
            district: giantExporters.address.district ?? "",
            district_id: giantExporters.address.district_id ?? 0,
            neighborhood: giantExporters.address.neighborhood ?? "",
            neighborhood_id: giantExporters.address.neighborhood_id ?? 0,
            address: giantExporters.address.address ?? "",
          },
          product_name: giantExporters.product_name ?? "",
          export_country: giantExporters.export_country ?? "",
          owner: giantExporters.owner ?? "",
          regionEdit: {
            name: giantExporters.address.region ?? "",
            code: giantExporters?.address.region_id ?? 0,
          },
          districtEdit: {
            name: giantExporters.address.district ?? "",
            code: giantExporters.address.district_id ?? 0,
          },
          neigborhoodEdit: {
            name: giantExporters.address.neighborhood ?? "",
            code: giantExporters.address.neighborhood_id ?? 0,
          },
        }
      : {
          name: "",
          phone: "",
          address: undefined,
          owner: "",
          product_name: "",
          export_country: "",
        },
  });

  const { data: region } = useGetReegionList();
  const regionId = form.watch("address.region_id");
  const districtId = form.watch("address.district_id");

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: giantExportersFormSchema) {
    delete formValues.regionEdit;
    delete formValues.districtEdit;
    delete formValues.neigborhoodEdit;

    await triggerGiantExportersCreate(formValues);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="name" label="Korhona nomi" required />

        <AddressFilterField
          name="regionEdit"
          label="Korhona viloyat nomi"
          data={region}
          required
          regionFieldName="address.region"
          regionIdFieldName="address.region_id"
        />
        <AddressFilterField
          name="districtEdit"
          label="Korhona tuman nomi"
          data={districts}
          required
          regionFieldName="address.district"
          regionIdFieldName="address.district_id"
        />
        <AddressFilterField
          name="neigborhoodEdit"
          label="Korhona mahalla nomi"
          data={neighborhood}
          required
          regionFieldName="address.neighborhood"
          regionIdFieldName="address.neighborhood_id"
        />
        <TextField name="address.address" label="Manzili" required />
        <TextField name="phone" label="Korhona raqami" required />
        <TextField
          name="product_name"
          label="Export qiluvchi to'liq nomi"
          required
        />
        <TextField name="export_country" label="Export qiluvchi" required />
        <TextField name="owner" label="Korhona raxbari" required />

        <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
      </form>
    </Form>
  );
}
