import { Button } from 'components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Plus, Trash2 } from 'lucide-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface IProps {
  name: string;
  objName: string;
  objDesc: string;
  addBtnTitle: string;
  label?: string;
  descriptionOne: string;
  descriptionTwo: string;
  required?: boolean;
  typeOne: string;
}

export default function MultiInputField({
  name,
  objDesc,
  objName,
  label,
  descriptionOne,
  descriptionTwo,
  required,
  addBtnTitle,
  typeOne,
}: IProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name, control });

  return (
    <div>
      {label && (
        <FormLabel>
          {`${label} `}
          {required && (
            <span className="text-red-500 dark:text-red-900">*</span>
          )}
        </FormLabel>
      )}

      {fields.map((field, index) => (
        <div key={field.id} className="mb-4">
          <FormField
            control={control}
            name={`${name}[${index}].${objName}`}
            render={({ field }) => (
              <FormItem>
                <FormDescription>{descriptionOne}</FormDescription>
                <div className="relative flex items-center">
                  <FormControl className="w-full ">
                    <Input
                      value={field.value}
                      onChange={event => {
                        if (typeOne === 'number') {
                          field.onChange(Number(event.target.value));
                        } else {
                          field.onChange(event.target.value);
                        }
                      }}
                      type={typeOne}
                    />
                  </FormControl>
                  {fields.length >= 1 && (
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      className="absolute right-2 text-red-500 p-2  bg-transparent hover:bg-transparent focus:bg-transparent"
                    >
                      <Trash2 className="size-5" />
                    </Button>
                  )}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={`${name}[${index}].${objDesc}`}
            render={({ field }) => (
              <FormItem>
                <FormDescription>{descriptionTwo}</FormDescription>
                <FormControl className="w-full ">
                  <Input value={field.value} onChange={field.onChange} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}

      <Button
        variant="ghost"
        type="button"
        onClick={() => append({ name: '', descName: '' })}
        className="flex items-center"
      >
        <Plus className="size-5 mr-2 text-blue-500 dark:text-white" />
        {addBtnTitle}
      </Button>
    </div>
  );
}
