import { FermerFeedbacks } from "./type";

const getFermerFeedbacks = (item?: FermerFeedbacks) => {
  return {
    _id: item?._id ?? "",
    link: item?.link ?? "",
    title: item?.title ?? "",
    image: item?.image ?? "",
    description: item?.description ?? "",
  };
};

export const getFermerFeedbacksList = (data?: FermerFeedbacks[]) => {
  return data?.length
    ? data.map((item) => {
        return getFermerFeedbacks(item);
      })
    : [];
};
