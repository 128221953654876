import { ColumnDef } from '@tanstack/react-table';
import { Course } from 'modules/courses/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { timeConverter } from 'utils/timeConverter';
import { Link } from 'react-router-dom';

interface IProps {
  getRowData: (course: Course) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createCourseColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Course>[] => [
    {
      accessorKey: 'title',
      header: 'Video nomi',
    },
    {
      accessorKey: 'thumbnail',
      header: 'Video rasmi',
      cell: ({ row }) => {
        const imageUrl = row.getValue<string>('thumbnail');
        const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
        return imageUrl ? (
          <img src={fullImageUrl} alt="news" style={{ width: 50, height: 50 }} />
        ) : (
          <span>Rasm mavjud emas</span>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Tavsifi',
    },
    {
      accessorKey: 'video',
      header: 'Video havolasi',
      cell: ({ row }) => (
        <Link
          to={row.getValue('video')}
          className="hover:underline text-blue-500"
          target="_blank"
          onClick={e => e.stopPropagation()}
        >
          Havola
        </Link>
      ),
    },
    {
      accessorKey: 'duration',
      header: 'Davomiyligi',
      cell: ({ row }) => {
        const duration = row.getValue('duration') as number;
        return <span>{timeConverter(duration)}</span>;
      },
    },
    {
      accessorKey: '_id',
      header: () => <span className="sr-only">Actions</span>,
      size: 50,
      cell: ({ row }) => (
        <DataTableRowActions
          row={row}
          getRowData={getRowData}
          setDialogOpen={setDialogOpen}
          setSheetOpen={setSheetOpen}
        />
      ),
    },
  ];
