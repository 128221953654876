import { ForeignGrainList, Informations, LocalGrainList } from "./types";

export const getInformation = (item?: Informations) => {
  return {
    _id: item?._id ?? "",
    title: item?.title ?? "",
    image: item?.image ?? "",
    types: item?.types ?? [],
    file: item?.file ?? "",
  };
};

export const getTypePlant = (item?: LocalGrainList) => {
  return {
    company_name: item?.company_name || "",
    company_owner: item?.company_owner || "",
    company_owner_phone: item?.company_owner_phone || "",
    created_at: item?.created_at || "",
    for_where: item?.for_where || "",
    parent_information: item?.parent_information || "",
    plant_type: item?.plant_type || "",
    planting_time: item?.planting_time || "",
    reccomendation_region: item?.reccomendation_region || "",
    spending: item?.spending || "",
    type_of: item?.type_of || "",
  };
};

export const getForeignGrain = (item?: any): ForeignGrainList => {
  return {
    address: item?.address || "",
    company_name: item?.company_name || "",
    country: item?.country || "",
    created_at: item?.created_at || "",
    inn: item?.inn || "",
    parent_information: item?.parent_information || "",
    phone_number: item?.phone_number || "",
    product_name: item?.product_name || "",
    sort_type: item?.sort_type || "",
    type_of: item?.type_of || "",
  };
};

export const getLocalGrainList = (data?: LocalGrainList[]) => {
  return data?.length
    ? data.map((item) => {
        return getTypePlant(item);
      })
    : [];
};

export const getForeignGrainList = (data?: ForeignGrainList[]) => {
  return data?.length
    ? data.map((item) => {
        return getForeignGrain(item);
      })
    : [];
};

export const getInformationsList = (data?: Informations[]) => {
  return data?.length
    ? data.map((item) => {
        return getInformation(item);
      })
    : [];
};
