import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getActivityList } from '../adapter';
import { GetActivityList } from '../api';

export const useGetActivityList = () => {
  const initData = {
    data: getActivityList(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['activity_list'],
    queryFn: () => GetActivityList(),
    select: data => ({
      data: getActivityList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
