import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import {
  getForeignGrainList,
  getInformation,
  getLocalGrainList,
} from "../adapters";
import { GetInformation } from "../api";

export const useGetInformation = (id: string) => {
  const initialData = {
    data: getInformation(),
    data_plant_info: getLocalGrainList(),
    data_import_info: getForeignGrainList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["info"],
    queryFn: () => GetInformation(id),
    select: (data) => ({
      data: getInformation(get(data, "data.information")),
      data_plant_info: getLocalGrainList(get(data, "data.data[0].plant_info")),
      data_import_info: getForeignGrainList(
        get(data, "data.data[1].import_info")
      ),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
