import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { AboutList } from 'modules/about/type';

interface IProps {
  getRowData: (module: AboutList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createModuleColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<AboutList>[] => [
  {
    accessorKey: 'image',
    header: "Bo'lim rasmi",
    cell: ({ row }) => {
      const imageUrl = row.getValue<string>('image');
      const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
      return imageUrl ? (
        <img src={fullImageUrl} alt="news" style={{ width: 50, height: 50 }} />
      ) : (
        <span>Rasm yuklanmagan</span>
      );
    },
  },
  {
    accessorKey: 'title',
    header: 'Sarlavha',
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
