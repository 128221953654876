import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'services/react-query';

import { useToast } from 'components/ui/use-toast';
import { showErrorToast } from 'utils/showErrorToast';
import { DelteAbout } from '../api';

export const useDeleteAbout = (id: string) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DelteAbout(id),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ['about_list'] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return { triggerAboutDelete: mutate, isSuccess, isError };
};
