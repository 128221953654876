import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { PackingCompaniesList } from "modules/packing-companies/type";

interface IProps {
  getRowData: (data: PackingCompaniesList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createPackingCompaniesColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<PackingCompaniesList>[] => [
  {
    accessorKey: "name",
    header: "Korhona nomi",
  },
  {
    accessorKey: "owner",
    header: "Rahbar",
  },
  {
    accessorKey: "product_name",
    header: "Mahsulot nomi",
  },
  {
    accessorKey: "phone",
    header: "Telfon raqami",
  },
  {
    accessorKey: "address.region",
    header: "Viloyat",
  },
  {
    accessorKey: "address.district",
    header: "Tuman",
  },
  {
    accessorKey: "address.neighborhood",
    header: "Mahalla",
  },

  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
