import http from 'services/api';
import { LabCreate, LabEdit } from './type';

export const GetLabList = async (currentPage: number) => {
  return await http.get(
    `agro-service/biolaboratories?page=${currentPage}&size=10`
  );
};

export const DelteLab = async (id: string) => {
  return await http.delete(`agro-service/biolaboratories/${id}`);
};

export const CreateLab = async (values: LabCreate) => {
  return await http.post(`agro-service/biolaboratories`, values);
};

export const EditLab = async ({ id, values }: LabEdit) => {
  return await http.patch(`agro-service/biolaboratories/${id}`, values);
};
