import React, { useState } from "react";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "components/ui/tabs";

import { useMarketInProgressList } from "modules/market/hooks/useMarketInProgresList";
import { Market, STATUS } from "modules/market/type";
import { useAceptStatus } from "modules/market/hooks/useAceptMarket";
import { useRejectStatus } from "modules/market/hooks/useRejectMarket";
import { useMarketAproveList } from "modules/market/hooks/useMarketAproveList";
import { useMarketRejectList } from "modules/market/hooks/useMarketRejectList";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Form } from "components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "components/LoadingButton";
import { TextField } from "components/fields";
import { createCustomColumns } from "./Coulmns";

// Schema definition for form validation
const lessonSchema = z.object({
  rejection_reason: z
    .string()
    .min(3, "Rejection reason must be at least 3 characters long"),
});

type lessonFormSchema = z.infer<typeof lessonSchema>;

const MarketTable = () => {
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [market, setMarket] = useState<Market>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: in_progress, isLoading: isIn_progress } =
    useMarketInProgressList();
  const { data: approve, isLoading: isApprove } = useMarketAproveList();
  const { data: reject, isLoading: isReject } = useMarketRejectList();

  const { triggerAceptStatus } = useAceptStatus();
  const { triggerRejectStatus, isPending } = useRejectStatus();

  const form = useForm<lessonFormSchema>({
    resolver: zodResolver(lessonSchema),
    defaultValues: {
      rejection_reason: "",
    },
  });
  const { reset } = form;

  const updateStatus = (row: string, newStatus: string) => {
    if (newStatus === STATUS.ACTIVE) {
      triggerAceptStatus(row);
    }
  };

  const columns = createCustomColumns({
    setIsOpen,
    setMarket,
    updateStatus,
  });

  async function onSubmit(formValues: lessonFormSchema) {
    triggerRejectStatus({
      id: market?._id!,
      reject_reason: formValues.rejection_reason,
    });
    setIsOpen(false);
    reset();
  }

  return (
    <>
      <h1 className="text-xl mb-3 font-medium">
        Bozor ({in_progress?.length})
      </h1>
      <Tabs value={selectedTab} onValueChange={setSelectedTab}>
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="tab1">Jarayonda</TabsTrigger>
            <TabsTrigger value="tab2">Faol qilish</TabsTrigger>
            <TabsTrigger value="tab3">Bekor qilingan</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="tab1">
          {isIn_progress ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={in_progress} />
          )}
        </TabsContent>

        <TabsContent value="tab2">
          {isApprove ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={approve} />
          )}
        </TabsContent>

        <TabsContent value="tab3">
          {isReject ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={reject} />
          )}
        </TabsContent>
      </Tabs>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Bekor qilish</DialogTitle>
            <DialogDescription>
              Iltimos, bekor qilish sababini kiriting.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-2"
            >
              <div className="grid gap-4 py-4">
                <TextField
                  {...form.register("rejection_reason")}
                  name="rejection_reason"
                  label="Sababi"
                  required
                />
              </div>
              <DialogFooter>
                <LoadingButton isLoading={isPending}>Saqlash</LoadingButton>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MarketTable;
