import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { useFormContext } from 'react-hook-form';

interface IProps {
  name: string;
  placeholder?: string;
  data: { name: string; code: string; disabled?: boolean }[];
  label?: string;
  required?: boolean;
  regionFieldName: string;
  regionIdFieldName: string;
}

export default function SelectField({
  data,
  placeholder,
  name,
  label,
  required,
  regionFieldName,
  regionIdFieldName,
}: IProps) {
  const { control, setValue } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel>
              {`${label} `}
              {required && (
                <span className="text-red-500 dark:text-red-900">*</span>
              )}
            </FormLabel>
          )}
          <FormControl>
            <Select
              onValueChange={value => {
                const parsedValue = JSON.parse(value);
                setValue(regionFieldName, parsedValue.name);
                setValue(regionIdFieldName, parsedValue.code);
              }}
              defaultValue={JSON.stringify(field.value)}
            >
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent>
                {data.map(item => (
                  <SelectItem
                    value={JSON.stringify({ name: item.name, code: item.code })}
                    key={item.code}
                    disabled={item.disabled}
                  >
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
