import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

import { useEditInspector } from 'modules/inspektor/hooks/useEditInstpector';
import { useCreateInspector } from 'modules/inspektor/hooks/useCreateInspector';
import { InspektorList } from 'modules/inspektor/type';
import { addressSchema, editAdrressSvhema } from 'pages/Lab/LabForm';
import { useGetReegionList } from 'modules/region/hooks/useGetRegionList';
import { useGetDistrictsList } from 'modules/districts/hooks/useGetDistrictsList';
import { useGetNeighborhoodList } from 'modules/neighborhood/hooks/useGetNeighborhoodList';
import AddressFilterField from 'components/fields/AddressFilter';

const soilSchema = z.object({
  phone_number: z
    .string()
    .min(9, { message: 'Phone number is required' })
    .max(12, { message: 'Phone number is required' }),
  post_address: z.string().min(3),
  address: addressSchema,
  inspector_full_name: z.string().min(3),
  position: z.string().min(3),
  regionEdit: editAdrressSvhema.optional(),
  districtEdit: editAdrressSvhema.optional(),
  neigborhoodEdit: editAdrressSvhema.optional(),
});

type soilFormSchema = z.infer<typeof soilSchema>;

interface IProps {
  inspekt?: InspektorList;
  setSheetOpen: (state: boolean) => void;
}

export default function InspectorForm({ inspekt, setSheetOpen }: IProps) {
  const { triggerInspectorCreate, isPending: isCreatePending } =
    useCreateInspector({
      setSheetOpen,
    });
  const { triggerInspectorEdit, isPending: isEditPending } = useEditInspector({
    id: inspekt?._id,
    setSheetOpen,
  });

  const form = useForm<soilFormSchema>({
    resolver: zodResolver(soilSchema),
    defaultValues: inspekt
      ? {
          inspector_full_name: inspekt.inspector_full_name ?? '',
          phone_number: inspekt.phone_number ?? '',
          position: inspekt.position ?? '',
          address: {
            region: inspekt.address.region ?? '',
            region_id: inspekt.address.region_id ?? 0,
            district: inspekt.address.district ?? '',
            district_id: inspekt.address.district_id ?? 0,
            neighborhood: inspekt.address.neighborhood ?? '',
            neighborhood_id: inspekt.address.neighborhood_id ?? 0,
            address: inspekt.address.address ?? '',
          },
          regionEdit: {
            name: inspekt.address.region ?? '',
            code: inspekt?.address.region_id ?? 0,
          },
          districtEdit: {
            name: inspekt.address.district ?? '',
            code: inspekt.address.district_id ?? 0,
          },
          neigborhoodEdit: {
            name: inspekt.address.neighborhood ?? '',
            code: inspekt.address.neighborhood_id ?? 0,
          },
          post_address: inspekt.post_address ?? '',
        }
      : {
          address: undefined,
          inspector_full_name: '',
          phone_number: '',
          post_address: '',
          position: '',
        },
  });

  const { data: region } = useGetReegionList();
  const regionId = form.watch('address.region_id');
  const districtId = form.watch('address.district_id');

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: soilFormSchema) {
    delete formValues.regionEdit;
    delete formValues.districtEdit;
    delete formValues.neigborhoodEdit;
    if (inspekt) {
      triggerInspectorEdit(formValues);
    } else {
      triggerInspectorCreate(formValues);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="post_address" label="Zip code" required />
          <TextField name="inspector_full_name" label="Raxbari" required />
          <TextField name="phone_number" label="Raqami" required />
          <TextField name="position" label="Lavozim" required />
          <TextField name="address.address" label="Manzili" required />
          <AddressFilterField
            name="regionEdit"
            label="Labaratoriya viloyat nomi"
            data={region}
            required
            regionFieldName="address.region"
            regionIdFieldName="address.region_id"
          />
          <AddressFilterField
            name="districtEdit"
            label="Labaratoriya tuman nomi"
            data={districts}
            required
            regionFieldName="address.district"
            regionIdFieldName="address.district_id"
          />
          <AddressFilterField
            name="neigborhoodEdit"
            label="Labaratoriya mahalla nomi"
            data={neighborhood}
            required
            regionFieldName="address.neighborhood"
            regionIdFieldName="address.neighborhood_id"
          />

          {inspekt ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
