import http from 'services/api';

export const GetNeighborhoodList = async (
  region_code: number,
  district_code: number
) => {
  return await http.get(
    `areas/regions/${region_code}/districts/${district_code}/neighborhoods`
  );
};
