import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getCoursesList } from '../adapters';
import { GetCoursesList } from '../api';

export const useCoursesList = (moduleId: string, currentPage: number) => {
  const initialData = {
    data: getCoursesList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['courses_list', moduleId, currentPage],
    queryFn: () => GetCoursesList(moduleId, currentPage),
    select: data => {
      const coursesList = getCoursesList(get(data, 'data.data'));
      const sortedCoursesList = coursesList.sort((a, b) => a.order - b.order);

      return {
        data: sortedCoursesList,
        paginationInfo: get(data, 'data.pagination'),
      };
    },
  });

  return {
    ...data,
    ...args,
  };
};
