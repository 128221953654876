import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";

import { getNewsList } from "../adapters";
import { GetNewsList } from "../api";

export const useNewsList = () => {
  const initialData = {
    data: getNewsList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["news_list"],
    queryFn: () => GetNewsList(),
    select: (data) => ({
      data: getNewsList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
