import { OurCustomers } from "./type";

const getOurCustomers = (item?: OurCustomers) => {
  return {
    _id: item?._id ?? "",
    name: item?.name ?? "",
    image: item?.image ?? "",
  };
};

export const getOurCustomersList = (data?: OurCustomers[]) => {
  return data?.length
    ? data.map((item) => {
        return getOurCustomers(item);
      })
    : [];
};
