import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

import { EksportList } from 'modules/eksport/type';
import { useEksportCreate } from 'modules/eksport/hooks/useCreateEksport';
import { useEditEksport } from 'modules/eksport/hooks/useEditEksport';
import { addressSchema, editAdrressSvhema } from 'pages/Lab/LabForm';
import { useGetReegionList } from 'modules/region/hooks/useGetRegionList';
import { useGetDistrictsList } from 'modules/districts/hooks/useGetDistrictsList';
import { useGetNeighborhoodList } from 'modules/neighborhood/hooks/useGetNeighborhoodList';
import AddressFilterField from 'components/fields/AddressFilter';

const eksportSchema = z.object({
  name: z.string().min(3, { message: ' nomi talab qilinadi' }),
  title: z.string().min(3),
  owner: z.string().min(3),
  product_name: z.string().min(3),
  address: addressSchema,
  inn: z
    .string()
    .min(14, { message: 'Inn number is required' })
    .max(14, { message: 'Inn number is required' }),
  phone: z.string().min(9),

  website: z.string().min(3),
  regionEdit: editAdrressSvhema.optional(),
  districtEdit: editAdrressSvhema.optional(),
  neigborhoodEdit: editAdrressSvhema.optional(),
});

type eksportFormSchema = z.infer<typeof eksportSchema>;

interface IProps {
  eksport?: EksportList;
  setSheetOpen: (state: boolean) => void;
}

export default function EksportForm({ eksport, setSheetOpen }: IProps) {
  const { triggerEksportCreate, isPending: isCreatePending } = useEksportCreate(
    {
      setSheetOpen,
    }
  );
  const { triggerEksportEdit, isPending: isEditPending } = useEditEksport({
    id: eksport?._id!,
    setSheetOpen,
  });

  const form = useForm<eksportFormSchema>({
    resolver: zodResolver(eksportSchema),
    defaultValues: eksport
      ? {
          title: eksport.title ?? '',
          product_name: eksport.product_name ?? '',
          owner: eksport.owner ?? '',
          website: eksport.website ?? '',
          inn: eksport.inn ?? '',
          name: eksport.name ?? '',
          phone: eksport.phone ?? '',
          address: {
            region: eksport.address.region ?? '',
            region_id: eksport.address.region_id ?? 0,
            district: eksport.address.district ?? '',
            district_id: eksport.address.district_id ?? 0,
            neighborhood: eksport.address.neighborhood ?? '',
            neighborhood_id: eksport.address.neighborhood_id ?? 0,
            address: eksport.address.address ?? '',
          },
          regionEdit: {
            name: eksport.address.region ?? '',
            code: eksport?.address.region_id ?? 0,
          },
          districtEdit: {
            name: eksport.address.district ?? '',
            code: eksport.address.district_id ?? 0,
          },
          neigborhoodEdit: {
            name: eksport.address.neighborhood ?? '',
            code: eksport.address.neighborhood_id ?? 0,
          },
        }
      : {
          title: '',
          product_name: '',
          owner: '',
          website: '',
          inn: '',
          phone: '',
          name: '',
          address: undefined,
        },
  });
  const { data: region } = useGetReegionList();
  const regionId = form.watch('address.region_id');
  const districtId = form.watch('address.district_id');

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: eksportFormSchema) {
    delete formValues.regionEdit;
    delete formValues.districtEdit;
    delete formValues.neigborhoodEdit;
    if (eksport) {
      triggerEksportEdit(formValues);
    } else {
      triggerEksportCreate(formValues);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Sarlavha" required />
          <TextField name="website" label="Linki" required />
          <TextField name="inn" label="INN" required />
          <TextField name="address.address" label="Manzili" required />
          <TextField name="name" label="Nomi" required />
          <TextField name="product_name" label="Maxsulot nomi" required />
          <TextField name="owner" label="Raxbar" required />
          <TextField name="phone" label="Telefon raqami" required />

          <AddressFilterField
            name="regionEdit"
            label="Labaratoriya viloyat nomi"
            data={region}
            required
            regionFieldName="address.region"
            regionIdFieldName="address.region_id"
          />
          <AddressFilterField
            name="districtEdit"
            label="Labaratoriya tuman nomi"
            data={districts}
            required
            regionFieldName="address.district"
            regionIdFieldName="address.district_id"
          />
          <AddressFilterField
            name="neigborhoodEdit"
            label="Labaratoriya mahalla nomi"
            data={neighborhood}
            required
            regionFieldName="address.neighborhood"
            regionIdFieldName="address.neighborhood_id"
          />

          {eksport ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
