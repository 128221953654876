import { useQuery } from "@tanstack/react-query";

import { get } from "lodash";
import { getGiantExportersData } from "../adapter";
import { GetGiantExportersList } from "../api";

export const useGetGiantExportersList = (currentPage: number) => {
  const initData = {
    data: getGiantExportersData(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ["giant_exporters_list", currentPage],
    queryFn: () => GetGiantExportersList(currentPage),
    select: (data) => ({ data: getGiantExportersData(get(data, "data")) }),
  });
  return {
    ...data,
    ...args,
  };
};
