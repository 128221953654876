import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { FileField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import useFileUploader, {
  useMultiFileObjectUploader,
} from 'hooks/useFileUploader';
import { AboutCreate, AboutList } from 'modules/about/type';
import { useCreateAbout } from 'modules/about/hooks/useCreateAbout';
import { useEditAbout } from 'modules/about/hooks/useEditAbout';
import MultiTextAreaField from 'components/fields/MultiTextArea';
import MultiTextFieldInObj from 'components/fields/MultiTextObj';

const aboutSchema = z.object({
  title: z.string().min(3),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
  phone: z.string().min(3),
  socials: z.array(
    z.object({
      image: z.union([
        z.custom<File>(file => file instanceof File, {
          message: 'Rasm talab qilinadi',
        }),
        z.string().min(1, { message: 'Rasm talab qilinadi' }),
      ]),
      link: z.string().min(3),
    })
  ),
  content: z.array(z.string().min(10)),
});

type aboutFormSchema = z.infer<typeof aboutSchema>;

interface IProps {
  about?: AboutList;
  setSheetOpen: (state: boolean) => void;
}

export default function AboutForm({ about, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { uploadFiles: linkUploder } = useMultiFileObjectUploader();
  const { triggerAboutCreate, isPending: isCreatePending } = useCreateAbout({
    setSheetOpen,
  });
  const { triggerAboutEdit, isPending: isEditPendint } = useEditAbout({
    id: about?._id,
    setSheetOpen,
  });
  const form = useForm<aboutFormSchema>({
    resolver: zodResolver(aboutSchema),
    defaultValues: about
      ? {
          title: about.title,
          image: about.image,
          phone: about.phone,
          socials: about.socials,
          content: about.content,
        }
      : {
          title: '',
          image: undefined,
          phone: '',
          socials: [],
          content: [],
        },
  });
  console.log(form.formState.errors);

  async function onSubmit(formValues: aboutFormSchema) {
    const values = await uploadFile<AboutCreate>(formValues, 'image');
    const finalValues = await linkUploder<AboutCreate>(
      values,
      'socials',
      'image'
    );

    if (about) {
      triggerAboutEdit({ ...finalValues });
    } else {
      triggerAboutCreate({ ...finalValues });
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          <div className="flex gap-4 flex-col my-4">
            <TextField name="title" label="Nomi" required />
            <FileField name="image" label="Rasmi" required />
            <MultiTextAreaField
              name="content"
              label="Paragrof"
              required
              addBtnTitle="Paragraf qoshish"
            />
            <TextField name="phone" required label="raqam" />
            <MultiTextFieldInObj
              keyName="link"
              name="socials"
              imgObjName="image"
              label="Linklar"
              required
              addBtnTitle="Qo'shish"
            />
          </div>
          {about ? (
            <LoadingButton isLoading={isEditPendint}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
