import { useServicesList } from "modules/sevices/hooks/useGetServicesList";
import { ServicesList } from "modules/sevices/type";
import { useState } from "react";
import { createNewsColumns } from "./Columns";
import { TableActions } from "components/TableActions";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import ServicesForm from "./ServicesForm";
import { AlertDialog } from "components/AlertDialog";
import { useDeleteService } from "modules/sevices/hooks/useDeleteServices";

const Seriveces = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [services, setServices] = useState<ServicesList>();
  const { data: servicesList, isLoading } = useServicesList();
  const { triggerServiceDelete } = useDeleteService(services?._id!);

  const getRowData = (news: ServicesList) => {
    setServices(news);
  };

  const columns = createNewsColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <h1 className="text-xl mb-3 font-medium">
        Xizmatlar ({servicesList?.length})
      </h1>
      {/* <TableActions
        sheetTriggerTitle="Xizmat qo'shish"
        sheetTitle="Xizmat qo'shish."
        TableForm={ServicesForm}
      /> */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={servicesList} navigateWithType />
        </>
      )}
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <ServicesForm services={services} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerServiceDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};
export default Seriveces;
