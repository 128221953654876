import { useState } from "react";
import { LabList } from "../../modules/lab/type";
import { useLabList } from "modules/lab/hooks/useGetLabList";
import { createNewsColumns } from "./Column";
import { useDeleteLab } from "modules/lab/hooks/useDeleteLab";
import { TableActions } from "components/TableActions";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import { AlertDialog } from "components/AlertDialog";
import LabForm from "./LabForm";
import { Pagination } from "components/Pagination";

const Lab = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [lab, setLab] = useState<LabList>();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: labList, isLoading } = useLabList(currentPage);

  const { triggerLabDelete } = useDeleteLab(lab?._id!);
  const getRowData = (labState: LabList) => {
    setLab(labState);
  };

  const columns = createNewsColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Xizmat qo'shish"
        sheetTitle="Xizmat qo'shish."
        TableForm={LabForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={labList.data} />
          <Pagination
            next_page={currentPage + 1}
            currentPage={currentPage}
            prev_page={currentPage - 1}
            setCurrentPage={setCurrentPage}
            total_pages={labList.total_pages}
            total_records={labList.count}
            className="mt-10"
          />
        </>
      )}
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <LabForm lab={lab} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerLabDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Lab;
