import http from 'services/api';
import { CreateActivity, EditActivity } from './type';

export const GetActivityList = async () => {
  return await http.get(`/category`);
};

export const CreateActivities = async (values: CreateActivity) => {
  return await http.post(`category`, values);
};
export const EditActivities = async ({ id, values }: EditActivity) => {
  return await http.patch(`/category/${id}`, values);
};

export const DeleteActivities = async (id: string) => {
  return await http.delete(`/category/${id}`);
};
