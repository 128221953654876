import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { EksportList } from 'modules/eksport/type';
import { Link } from 'react-router-dom';

interface IProps {
  getRowData: (data: EksportList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const creatEksportColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<EksportList>[] => [
  {
    accessorKey: 'name',
    header: 'Ismi',
  },

  {
    accessorKey: 'website',
    header: 'Web sahifa',
    cell: ({ row }) => (
      <Link
        to={row.getValue('website')}
        className="hover:underline text-blue-500"
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        Havola
      </Link>
    ),
  },
  {
    accessorKey: 'phone',
    header: 'Telfon raqami',
  },
  {
    accessorKey: 'address.region',
    header: 'Viloyat',
  },
  {
    accessorKey: 'address.district',
    header: 'Tuman',
  },
  {
    accessorKey: 'address.neighborhood',
    header: 'Mahalla',
  },

  {
    accessorKey: 'inn',
    header: 'INN',
  },

  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
