import { Button } from 'components/ui/button';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { X as CloseIcon, FileText, Plus, Trash2 } from 'lucide-react';
import { FileUploader } from 'react-drag-drop-files';
import { useFieldArray, useFormContext } from 'react-hook-form';
import calculateFileSize from 'utils/calculateFileSize';
import normalizeFileUrl from 'utils/normalizeFileUrl';

interface IProps {
  name: string;
  addBtnTitle: string;
  label?: string;
  description?: string;
  required?: boolean;
  keyName: string;
  imgObjName: string;
  isFileUpload?: boolean;
}

export default function MultiTextFieldInObj({
  name,
  keyName,
  label,
  description,
  required,
  addBtnTitle,
  imgObjName,

  isFileUpload,
}: IProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  return (
    <div>
      {label && (
        <FormLabel>
          {`${label} `}
          {required && (
            <span className="text-red-500 dark:text-red-900">*</span>
          )}
        </FormLabel>
      )}
      <FormDescription>{description}</FormDescription>
      {fields.map((field, index) => (
        <div key={field.id}>
          <FormField
            key={field.id}
            control={control}
            name={`${name}[${index}].${keyName}`} // Adjusted to match the "socials" array
            render={({ field }) => (
              <FormItem className="mb-4">
                <div className="relative flex items-center">
                  <FormControl className="w-full mt-2">
                    <Input {...field} />
                  </FormControl>
                  {fields.length >= 1 && (
                    <Button
                      type="button"
                      onClick={() => remove(index)}
                      className="absolute right-2 text-red-500 p-1 bg-transparent hover:bg-transparent focus:bg-transparent"
                    >
                      <Trash2 className="size-5" />
                    </Button>
                  )}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`${name}[${index}].${imgObjName}`}
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormControl>
                  {value ? (
                    <div className="relative p-1 border rounded-md max-w-fit">
                      {value?.type?.includes('pdf') ? (
                        <div className="flex items-center flex-1 gap-2">
                          <FileText className="size-8 stroke-1" />
                          <span className="truncate text-sm flex-[0.65]">
                            {value.name}
                          </span>
                          <span className="text-sm flex-[0.2]">
                            {calculateFileSize(value.size)}
                          </span>
                        </div>
                      ) : (
                        <>
                          {value?.url?.includes('pdf') ? (
                            <div className="flex items-center flex-1 gap-2 w-[380px]">
                              <FileText className="size-8 stroke-1" />
                              <span className="truncate text-sm flex-[0.8]">
                                {value}
                              </span>
                            </div>
                          ) : (
                            <div className="max-w-96">
                              <img
                                src={
                                  typeof value === 'string'
                                    ? normalizeFileUrl(value)
                                    : URL.createObjectURL(value)
                                }
                                alt="preview"
                                className="min-w-28"
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div
                        className="absolute top-2 right-2 cursor-pointer bg-slate-300/70"
                        onClick={() => onChange('')}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ) : (
                    <FileUploader
                      handleChange={onChange}
                      name="file"
                      types={isFileUpload ? ['PDF'] : ['PNG', 'JPG']}
                    />
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
      <Button
        variant="ghost"
        type="button"
        onClick={() => append({ keyName: '' })}
        className="flex items-center"
      >
        <Plus className="size-5 mr-2 text-blue-500 dark:text-white" />
        {addBtnTitle}
      </Button>
    </div>
  );
}
