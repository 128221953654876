import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/ui/form";
import LoadingButton from "components/LoadingButton";
import { FileField } from "components/fields";
import useFileUploader from "hooks/useFileUploader";
import { useEditInformation } from "modules/information/hooks/useEditInformations";
import { useGetInformation } from "modules/information/hooks/useGetInformation";
import { InformationsInput } from "modules/information/types";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import Loader from "../../components/Loader";

const FileSchema = z.object({
  file: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Fayli kiritilishi kerak",
    }),
    z.string().min(1, { message: "Fayli kiritilishi kerak" }),
  ]),
});

type FileFormSchema = z.infer<typeof FileSchema>;

const InformationFile = () => {
  const { uploadFile } = useFileUploader();
  const { infoId } = useParams();

  const { data, isLoading } = useGetInformation(infoId!);
  const { triggerInformationEdit, isPending: isEditPending } =
    useEditInformation({
      id: infoId,
      setSheetOpen: () => false,
    });

  const form = useForm<FileFormSchema>({
    resolver: zodResolver(FileSchema),
    defaultValues: {
      file: data.file,
    },
  });

  async function onSubmit(formValues: FileFormSchema) {
    const values = await uploadFile<InformationsInput>(formValues, "file");
    triggerInformationEdit(values);
  }

  console.log(data);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-2"
          >
            <div className="flex gap-4 flex-col my-4">
              <FileField
                name="file"
                label="Ma'lumot rasmi"
                isFileUpload
                required
              />
            </div>

            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          </form>
        </Form>
      )}
    </>
  );
};

export default InformationFile;
