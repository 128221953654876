import { RegionList } from './type';

const getRegion = (item?: RegionList) => {
  return {
    type: item?.code ?? 0,
    name: item?.name ?? '',
  };
};

export const getRegionList = (data?: RegionList[]) => {
  return data?.length
    ? data.map(item => {
        return getRegion(item);
      })
    : [];
};
