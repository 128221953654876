import { useQuery } from "@tanstack/react-query";

import { get } from "lodash";
import { getPackingCompaniesData } from "../adapter";
import { GetPackingCompaniesList } from "../api";

export const useGetPackingCompaniesList = (currentPage: number) => {
  const initData = {
    data: getPackingCompaniesData(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ["packing_companies", currentPage],
    queryFn: () => GetPackingCompaniesList(currentPage),
    select: (data) => ({ data: getPackingCompaniesData(get(data, "data")) }),
  });
  return {
    ...data,
    ...args,
  };
};
