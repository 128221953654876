import http from 'services/api';
import { STATUS } from './type';

export const GetMarketInProgress = async () => {
  return await http.get(`/market/ads?status=${STATUS.INPROGRESS}`);
};
export const MarketAcept = async (id: string) => {
  return await http.patch(`market/ads/${id}/approve`);
};
export const MarketReject = async (id: string, reject_reason: string) => {
  return await http.patch(`market/ads/${id}/reject`, {
    reject_reason: reject_reason,
  });
};
export const GetMarketAprove = async () => {
  return await http.get(`/market/ads?status=${STATUS.ACTIVE}`);
};

export const GetMarketReject = async () => {
  return await http.get(`/market/ads?status=${STATUS.REJECTED}`);
};
