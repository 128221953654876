import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getTopCountryList, getTopProductList } from "../adapters";
import { GetTopCountries, GetTopProducts } from "../api";

//top-products*******************************************************
export const useTopProductsList = () => {
  const initialData = {
    data: getTopProductList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["top_products"],
    queryFn: () => GetTopProducts(),
    select: (data) => ({
      data: getTopProductList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};

//top-countries*******************************************************
export const useTopCountriesList = () => {
  const initialData = {
    data: getTopCountryList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["top_countries"],
    queryFn: () => GetTopCountries(),
    select: (data) => ({
      data: getTopCountryList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
