import { AlertDialog } from "components/AlertDialog";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { Sheet } from "components/Sheet";
import { TableActions } from "components/TableActions";
import { useDelteVideoCategory } from "modules/video-category/hooks/useDeleteVideoCategory";
import { useGetVideoCategoryList } from "modules/video-category/hooks/useGetVideoCategoryList";
import { VideoCategory } from "modules/video-category/type";
import { useState } from "react";
import { createCategoriesColumns } from "./Columns";
import CategoriesForm from "./CategoryForm";

const Categories = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [categories, setCategories] = useState<VideoCategory>();

  const { data: categoriesList, isLoading } = useGetVideoCategoryList();
  const { triggerDeleteVideocategory } = useDelteVideoCategory(
    categories?._id!
  );

  const getRowData = (categoriesState: VideoCategory) => {
    setCategories(categoriesState);
  };

  const columns = createCategoriesColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Video Kategoriyalar ({categoriesList?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Biz haqimiza malumot qo'shish"
          sheetTitle="Yangi haqimiza malumot qo'shish."
          TableForm={CategoriesForm}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={categoriesList} />
      )}

      <Sheet
        sheetTitle="malumot tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <CategoriesForm setSheetOpen={setSheetOpen} categories={categories} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerDeleteVideocategory}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Categories;
