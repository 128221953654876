import http from 'services/api';
import { GrandinfoCreateInput, GrandinfoEditBody } from './types';

export const GetGrandinfosList = async (moduleId: string) => {
  return await http.get(`information/subinformation/${moduleId}/`);
};

export const CreateGrandinfo = async (values: GrandinfoCreateInput) => {
  return await http.post(`/information/grandinformation/`, values);
};

export const EditGrandinfo = async ({ values, id }: GrandinfoEditBody) => {
  return await http.patch(`/information/grandinformation/${id}`, values);
};

export const DeleteGrandinfo = async (id: string) => {
  return await http.delete(`/information/grandinformation/${id}`);
};
