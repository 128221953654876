import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { CreateFermerFeedbacks } from "../api";
import { FermerFeedbacks, FermerFeedbacksCreate } from "../type";

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateFermerFeedbacks = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: FermerFeedbacks | FermerFeedbacksCreate) =>
      CreateFermerFeedbacks(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Yangilik muvaffaqiyatli yaratildi.",
      });
      queryClient.invalidateQueries({ queryKey: ["fermer_feedbacks"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerFermerFeedbacksCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
