import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";

import { CreateLocalAds } from "../api";
import { LocalAdsCreate } from "../type";

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateLocalAds = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: LocalAdsCreate) => CreateLocalAds(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: " muvaffaqiyatli yaratildi.",
      });
      queryClient.invalidateQueries({ queryKey: ["in_progress_locals"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerLocalAdsCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
