import { Activity, SubActivity } from './type';

export const getSubActivity = (item?: SubActivity) => {
  return {
    id: item?.id ?? 0,
    name: item?.name ?? '',
    slug: item?.slug ?? '',
    image: item?.image ?? '',
  };
};

export const getActivity = (item?: Activity) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    slug: item?.slug ?? '',
    image: item?.image ?? '',
    subcategories: item?.subcategories.map(item => getSubActivity(item)) ?? [],
  };
};
export const getActivityList = (data?: Activity[]) => {
  return data?.length
    ? data.map(item => {
        return getActivity(item);
      })
    : [];
};
