import http from "services/api";
import {
  FermerFeedbacks,
  FermerFeedbacksCreate,
  FermerFeedbacksEditBody,
} from "./type";

export const FermerFeedbacksList = async () => {
  return await http.get(`/landing/farmer-feedbacks`);
};

export const CreateFermerFeedbacks = async (values: FermerFeedbacksCreate) => {
  return await http.post<{ data: FermerFeedbacks }>(
    `/landing/farmer-feedbacks`,
    values
  );
};

export const EditFermerFeedbacks = async ({
  values,
  id,
}: FermerFeedbacksEditBody) => {
  return await http.patch(`/landing/farmer-feedbacks/${id}`, values);
};

export const DeleteFermerFeedbacks = async (id: string) => {
  return await http.delete(`/landing/farmer-feedbacks/${id}`);
};
