import React, { useState } from "react";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "components/ui/tabs";

import { LocalAds, STATUS } from "modules/local-ads/type";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Form } from "components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "components/LoadingButton";
import { TextField } from "components/fields";
import { useLocalAdsInProgressList } from "modules/local-ads/hooks/useInProgresListLocalAds";
import { useLocalAdsAproveList } from "modules/local-ads/hooks/useAproveListLocalAds";
import { useLocalAdsRejectList } from "modules/local-ads/hooks/useRejectListLocalAds";
import { useAceptStatus } from "modules/local-ads/hooks/useAceptLocalAds";
import { useRejectStatus } from "modules/local-ads/hooks/useRejectLocalAds";
import { AlertDialog } from "components/AlertDialog";
import { useDeleteLocalAds } from "modules/local-ads/hooks/useDeleteLocalAds";
import { Sheet } from "components/Sheet";
import { createCustomColumns } from "pages/LocalAds/Coulmns";
import LocalAdsForm from "pages/LocalAds/LocalAdsForm";
import { TableActions } from "components/TableActions";

const lessonSchema = z.object({
  rejection_reason: z
    .string()
    .min(3, "Rejection reason must be at least 3 characters long"),
});

type lessonFormSchema = z.infer<typeof lessonSchema>;

const LocalAdsTable = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState("tab1");
  const [localAds, setLocalAds] = useState<LocalAds | any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: in_progress, isLoading: isIn_progress } =
    useLocalAdsInProgressList();
  const { data: approve, isLoading: isApprove } = useLocalAdsAproveList();
  const { data: reject, isLoading: isReject } = useLocalAdsRejectList();

  const { triggerAceptStatus } = useAceptStatus();
  const { triggerRejectStatus, isPending } = useRejectStatus();

  const form = useForm<lessonFormSchema>({
    resolver: zodResolver(lessonSchema),
    defaultValues: {
      rejection_reason: "",
    },
  });
  const { reset } = form;

  const updateStatus = (row: string, newStatus: string) => {
    if (newStatus === STATUS.ACTIVE) {
      triggerAceptStatus(row);
    }
  };

  const { triggerLocalAdsDelete } = useDeleteLocalAds(localAds?._id!);

  const getRowData = (localAdsState: LocalAds) => {
    setLocalAds(localAdsState);
  };

  const columns = createCustomColumns({
    setIsOpen,
    setLocalAds,
    updateStatus,
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  async function onSubmit(formValues: lessonFormSchema) {
    triggerRejectStatus({
      id: localAds?._id!,
      reject_reason: formValues.rejection_reason,
    });
    setIsOpen(false);
    reset();
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Mahalliy E'lonlar ({in_progress?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Mahalliy E'lon qo'shish"
          sheetTitle="Xizmat qo'shish."
          TableForm={LocalAdsForm}
        />
      </div>
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <LocalAdsForm localAd={localAds} setSheetOpen={setSheetOpen} />
      </Sheet>
      <Tabs value={selectedTab} onValueChange={setSelectedTab}>
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="tab1">Jarayonda</TabsTrigger>
            <TabsTrigger value="tab2">Faol qilish</TabsTrigger>
            <TabsTrigger value="tab3">Bekor qilingan</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="tab1">
          {isIn_progress ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={in_progress} />
          )}
        </TabsContent>

        <TabsContent value="tab2">
          {isApprove ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={approve} />
          )}
        </TabsContent>

        <TabsContent value="tab3">
          {isReject ? (
            <Loader />
          ) : (
            <DataTable columns={columns} data={reject} />
          )}
        </TabsContent>
      </Tabs>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Bekor qilish</DialogTitle>
            <DialogDescription>
              Iltimos, bekor qilish sababini kiriting.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-2"
            >
              <div className="grid gap-4 py-4">
                <TextField
                  {...form.register("rejection_reason")}
                  name="rejection_reason"
                  label="Sababi"
                  required
                />
              </div>
              <DialogFooter>
                <LoadingButton isLoading={isPending}>Saqlash</LoadingButton>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerLocalAdsDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </>
  );
};

export default LocalAdsTable;
