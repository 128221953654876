import { ColumnDef } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { convertDate } from "utils/time";
import { DataTableRowActions } from "components/DataTableRowActions";
import { International, STATUS } from "modules/international-ads/type";

interface IProps {
  setIsOpen: any;
  setInternational: any;
  getRowData: (data: International) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
  updateStatus: (rowId: string, status: string, reason?: string) => void;
}

const status: any = {
  [STATUS.ACTIVE]: "Tastiqlangan",
  [STATUS.INPROGRESS]: "Jarayonda",
  [STATUS.REJECTED]: "Rad etilgan",
};

export const createCustomColumns = ({
  setIsOpen,
  setInternational,
  updateStatus,
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<International>[] => {
  return [
    {
      accessorKey: "_id",
      header: "ID", // Optionally include an ID column
    },
    {
      accessorKey: "created_at",
      header: "Jo'natilgan sana",
      cell: ({ row }) => <span>{convertDate(row.getValue("created_at"))}</span>,
    },
    {
      accessorKey: "name",
      header: "Kompaniya nomi", // You can change this header to reflect the actual data
    },
    {
      accessorKey: "status",
      header: "Holati",
      cell: ({ row }) => {
        const statusKey = row.getValue("status") as keyof typeof status;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">{status[statusKey]}</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={() => updateStatus(row.original._id, STATUS.ACTIVE)}
              >
                Faol qilish
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  setIsOpen(true);
                  setInternational(row.original);
                }}
              >
                Bekor qilish
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "_id",
      header: () => <span className="sr-only">Actions</span>,
      size: 50,
      cell: ({ row }) => (
        <DataTableRowActions
          row={row}
          getRowData={getRowData}
          setDialogOpen={setDialogOpen}
          setSheetOpen={setSheetOpen}
        />
      ),
    },
  ];
};
