import { useState } from 'react';

import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';

import { useDeleteSoil } from 'modules/soil/hooks/useDelteSoil';
import { SoilList } from 'modules/soil/type';
import { useGetSoilList } from 'modules/soil/hooks/useGetSoilList';
import { creatSoilColumns } from './Columns';
import SoilForm from './SoilForm';
import { Pagination } from 'components/Pagination';

const Soil = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [soil, setSoil] = useState<SoilList>();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: soilList, isLoading } = useGetSoilList(currentPage);
  const { triggerSoilDelete } = useDeleteSoil(soil?._id!);

  const getRowData = (soilState: SoilList) => {
    setSoil(soilState);
  };

  const columns = creatSoilColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Biz haqimiza malumot qo'shish"
        sheetTitle="Tuproq malumot qo'shish."
        TableForm={SoilForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={soilList.data} />
          <Pagination
            next_page={currentPage + 1}
            currentPage={currentPage}
            prev_page={currentPage - 1}
            setCurrentPage={setCurrentPage}
            total_pages={soilList.total_pages}
            total_records={soilList.count}
            className="mt-10"
          />
        </>
      )}

      <Sheet
        sheetTitle="malumot tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <SoilForm setSheetOpen={setSheetOpen} soil={soil} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerSoilDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Soil;
