import http from "services/api";
import { PackingCompaniesCreate } from "./type";

export const GetPackingCompaniesList = async (currentPage: number) => {
  return await http.get(
    `agro-service/packing-companies?page=${currentPage}&size=10`
  );
};

export const DeltePackingCompanies = async (id: string) => {
  return await http.delete(`agro-service/packing-company/${id}`);
};

export const CreatePackingCompanies = async (
  values: PackingCompaniesCreate
) => {
  return await http.post(`agro-service/packing-companies`, values);
};
