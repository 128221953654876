import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getSubinfoList } from '../adapters';
import { GetSubinfoList } from '../api';

export const useSubinfosList = (courseId: string) => {
  const initialData = {
    data: getSubinfoList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['subinfo_list', courseId],
    queryFn: () => GetSubinfoList(courseId),
    select: sub_information => ({
      data: getSubinfoList(get(sub_information, 'data.sub_information')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
