import { useGetUserList } from 'modules/user/hooks/useGetUsers';
import { createUsersColumns } from './Coulmns';
import { DataTable } from 'components/DataTable';
import { Loader } from 'lucide-react';

const BasicUsers = () => {
  const { data: userList, isLoading } = useGetUserList();

  const coulmn = createUsersColumns();
  return (
    <>
      {isLoading ? <Loader /> : <DataTable columns={coulmn} data={userList} />}
    </>
  );
};
export default BasicUsers;
