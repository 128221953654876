import http from 'services/api';
import { VideoCreate, VideoEdit } from './type';

export const GetVideoCategory = async () => {
  return await http.get(`videos/video-categories`);
};

export const CreateVideoCategory = async (values: VideoCreate) => {
  return await http.post(`videos/video-category`, values);
};

export const EditVideoCategory = async ({ id, values }: VideoEdit) => {
  return await http.patch(`videos/video-category/${id}`, values);
};

export const DeleteVideoCategory = async (id: string) => {
  return await http.delete(`videos/video-category/${id}`);
};
