import { useState } from "react";
import { createGiantExportColumns } from "./Column";
import { TableActions } from "components/TableActions";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import { Pagination } from "components/Pagination";
import GiantExportersForm from "./GiantExportersForm";
import { GiantExportersList } from "modules/giant-exporters/type";
import { useGetGiantExportersList } from "modules/giant-exporters/hooks/useGetInspectorList";
import { AlertDialog } from "components/AlertDialog";
import { useDeleteGiantExporters } from "modules/giant-exporters/hooks/useDeleteGiant";

const GiantExporters = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [giantExporters, setGiantExporters] = useState<GiantExportersList>();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: giantExportersList, isLoading } =
    useGetGiantExportersList(currentPage);

  const { triggerGiantExportersDelete } = useDeleteGiantExporters(
    giantExporters?._id!
  );

  const getRowData = (giantExportState: GiantExportersList) => {
    setGiantExporters(giantExportState);
  };

  const columns = createGiantExportColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Korhona qo'shish"
        sheetTitle="Xizmat qo'shish."
        TableForm={GiantExportersForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={giantExportersList.data} />
          <Pagination
            next_page={currentPage + 1}
            currentPage={currentPage}
            prev_page={currentPage - 1}
            setCurrentPage={setCurrentPage}
            total_pages={giantExportersList.total_pages}
            total_records={giantExportersList.count}
            className="mt-10"
          />
        </>
      )}
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <GiantExportersForm
          giantExporters={giantExporters}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerGiantExportersDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default GiantExporters;
