import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getOurCustomersList } from "../adapter";
import { OurCustomersList } from "../api";

export const useGetOurCustomersList = () => {
  const initialData = {
    data: getOurCustomersList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["our_customers"],
    queryFn: () => OurCustomersList(),
    select: (data) => ({
      data: getOurCustomersList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
