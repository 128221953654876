import { useState } from "react";
import { createPackingCompaniesColumns } from "./Column";
import { TableActions } from "components/TableActions";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import { Pagination } from "components/Pagination";
import PackingCompaniesForm from "./PackingCompaniesForm";
import { PackingCompaniesList } from "modules/packing-companies/type";
import { useGetPackingCompaniesList } from "modules/packing-companies/hooks/useGetPackingCompanies";
import { AlertDialog } from "components/AlertDialog";
import { useDeletePackingCompanies } from "modules/packing-companies/hooks/useDeletePackingCompanies";

const PackingCompanies = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);

  const [PackingCompanies, setPackingCompanies] =
    useState<PackingCompaniesList>();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: PackingCompaniesList, isLoading } =
    useGetPackingCompaniesList(currentPage);

  const { triggerPackingCompaniesDelete } = useDeletePackingCompanies(
    PackingCompanies?._id!
  );

  const getRowData = (packingCompaniesState: PackingCompaniesList) => {
    setPackingCompanies(packingCompaniesState);
  };

  const columns = createPackingCompaniesColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Korhona qo'shish"
        sheetTitle="Xizmat qo'shish."
        TableForm={PackingCompaniesForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={PackingCompaniesList.data} />
          <Pagination
            next_page={currentPage + 1}
            currentPage={currentPage}
            prev_page={currentPage - 1}
            setCurrentPage={setCurrentPage}
            total_pages={PackingCompaniesList.total_pages}
            total_records={PackingCompaniesList.count}
            className="mt-10"
          />
        </>
      )}
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <PackingCompaniesForm
          packingCompanies={PackingCompanies}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerPackingCompaniesDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default PackingCompanies;
