import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Subinfo } from 'modules/subinfo/types';

import { Form } from 'components/ui/form';
import { FileField, SelectField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { useParams } from 'react-router-dom';
import { categories, categoryType } from 'constants/general';
import useFileUploader from 'hooks/useFileUploader';
import { useCreateSubinfo } from 'modules/subinfo/hooks/useCreateSubinfo';
import { useEditSubinfo } from 'modules/subinfo/hooks/useEditSubinfo';

const subInfoSchema = z.object({
  activity_type: z.string().min(3),
  crop_type: z.string().min(3),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type subInfoFormSchema = z.infer<typeof subInfoSchema>;

interface IProps {
  subInfo?: Subinfo;
  lastDataOrder?: number;
  setSheetOpen: (state: boolean) => void;
}

export default function SubInfoForm({
  subInfo,
  lastDataOrder: lastModuleOrder,
  setSheetOpen,
}: IProps) {
  const { courseId } = useParams();
  const { uploadFile } = useFileUploader();
  const { triggerSubinfoCreate, isPending: isCreatePending } = useCreateSubinfo(
    { setSheetOpen }
  );
  const { triggerSubinfoEdit, isPending: isEditPending } = useEditSubinfo({
    id: subInfo?._id,
    setSheetOpen,
  });

  const form = useForm<subInfoFormSchema>({
    resolver: zodResolver(subInfoSchema),
    defaultValues: subInfo
      ? {
          activity_type: subInfo.activity_type,
          crop_type: subInfo.crop_type,
          image: subInfo.image,
        }
      : {
          activity_type: '',
          crop_type: '',
          image: undefined,
        },
  });

  async function onSubmit(formValues: subInfoFormSchema) {
    const values = await uploadFile<Subinfo>(formValues, 'image');
    if (subInfo) {
      triggerSubinfoEdit({
        ...values,
        parent_information: courseId!,
      });
    } else {
      triggerSubinfoCreate({ ...values, parent_information: courseId! });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <FileField name="image" label="Ma'lumot rasmi" required />
          <SelectField
            name="activity_type"
            label="Bo'lim nomi"
            data={categories}
            required
          />
          {form.watch('activity_type') && (
            <SelectField
              name="crop_type"
              label="Bo'lim rasmi"
              required
              data={categoryType[form.watch('activity_type')]}
            />
          )}
        </div>
        {module ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
