import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { DeleteEksport } from '../api';

export const useDeleteEksport = (id: string) => {
  const { toast } = useToast();
  const { mutate, isError, isPending, isSuccess } = useMutation({
    mutationFn: () => DeleteEksport(id),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ['eksport_list'] });
    },
    onError: (error: Error) => showErrorToast(error),
  });
  return {
    triggerEksportDelete: mutate,
    isPending,
    isError,
    isSuccess,
  };
};
