import { useQuery } from '@tanstack/react-query';

import { get } from 'lodash';
import { getInspectorData } from '../adapter';
import { GetInspectorsList } from '../api';

export const useGetInspectorList = (currentPage: number) => {
  const initData = {
    data: getInspectorData(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['inspector_list', currentPage],
    queryFn: () => GetInspectorsList(currentPage),
    select: data => ({ data: getInspectorData(get(data, 'data')) }),
  });
  return {
    ...data,
    ...args,
  };
};
