import http from 'services/api';
import { AboutCreate, AboutEdit } from './type';

export const GetAboutList = async () => {
  return await http.get(`agro-service/about-us`);
};
export const CreateAbout = async (values: AboutCreate) => {
  return await http.post(`agro-service/about-us`, values);
};
export const EditAbout = async ({ id, values }: AboutEdit) => {
  return await http.patch(`agro-service/about-us/${id}`, values);
};
export const DelteAbout = async (id: string) => {
  return await http.delete(`agro-service/about-us/${id}`);
};
