import { NeighborhoodList } from './type';

const getNeighborhood = (item?: NeighborhoodList) => {
  return {
    type: item?.code ?? 0,
    name: item?.name ?? '',
  };
};

export const getNeighborhoodList = (data?: NeighborhoodList[]) => {
  return data?.length
    ? data.map(item => {
        return getNeighborhood(item);
      })
    : [];
};
