import { AboutList, Seocials } from './type';

const getSocails = (item?: Seocials) => {
  return {
    image: item?.image ?? '',
    link: item?.link ?? '',
  };
};

const getAbout = (item?: AboutList) => {
  return {
    _id: item?._id ?? '',
    image: item?.image ?? '',
    title: item?.title ?? '',
    content: item?.content ?? [],
    phone: item?.phone ?? '',
    socials: item?.socials.map(link => getSocails(link)) ?? [],
  };
};

export const getAboutList = (data?: AboutList[]) => {
  return data?.length
    ? data.map(item => {
        return getAbout(item);
      })
    : [];
};
