import { ColumnDef } from '@tanstack/react-table';
import { Grandinfo } from 'modules/grandInformation/types';
import { DataTableRowActions } from 'components/DataTableRowActions';

interface IProps {
  getRowData: (lesson: Grandinfo) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createLessonColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<any>[] => [
  {
    accessorKey: 'title',
    header: 'Sarlavha',
  },
  {
    accessorKey: 'description',
    header: 'Tavsifi',
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
