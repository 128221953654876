import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Form } from "components/ui/form";
import { FileField, SelectField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";

import { useCreateLocalAds } from "modules/local-ads/hooks/useCreateLocalAds";
import { categories, categoryType } from "constants/general";
import { useEditLocalAds } from "modules/local-ads/hooks/useEditLocalAds";
import useFileUploader from "hooks/useFileUploader";
import AddressFilterField from "components/fields/AddressFilter";
import { useGetReegionList } from "modules/region/hooks/useGetRegionList";
import { useGetDistrictsList } from "modules/districts/hooks/useGetDistrictsList";
import { useGetNeighborhoodList } from "modules/neighborhood/hooks/useGetNeighborhoodList";

export const addressSchema = z.object({
  region: z.string().min(1, { message: "Region is required" }),
  district: z.string().min(1, { message: "District is required" }),
  address: z.string().min(3, { message: "Address is required" }),
  neighborhood: z.string().min(1, { message: "Neighborhood is required" }),
  region_id: z.number().min(1),
  district_id: z.number().min(1),
  neighborhood_id: z.number().min(1),
});

export const localAdsFormSchema = z.object({
  address: addressSchema,
  company_name: z
    .string()
    .min(3, { message: "Company name is required" })
    .optional(),
  ad_owner: z.string().optional(),
  category: z.string().optional(),
  product: z.string().optional(),
  phone: z
    .string()
    .max(12, { message: "Phone number must be between 9 and 12 characters" })
    .optional(),
  quantity: z.union([
    z.string({ required_error: "Quantity is required!" }),
    z.number().optional(),
  ]),
  unit: z.string().optional(),
  comment: z.string().optional(),
  ad_type: z.string().optional(),
  image: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Rasm talab qilinadi",
    }),
    z.string().min(1, { message: "Rasm talab qilinadi" }),
  ]),
});

type localAdsFormSchema = z.infer<typeof localAdsFormSchema>;

interface IProps {
  localAd?: localAdsFormSchema | any;
  setSheetOpen: (state: boolean) => void;
}

export default function LocalAdsForm({ localAd, setSheetOpen }: IProps) {
  const { triggerLocalAdsCreate, isPending: isCreatePending } =
    useCreateLocalAds({
      setSheetOpen,
    });

  const { uploadFile, isPending: isFileUploadPending } = useFileUploader();

  const { triggerLocalAdsEdit, isPending: isEditPending } = useEditLocalAds({
    id: localAd?._id,
    setSheetOpen,
  });

  const form = useForm<localAdsFormSchema | any>({
    resolver: zodResolver(localAdsFormSchema),
    defaultValues: localAd
      ? {
          company_name: localAd.company_name ?? "",
          ad_owner: localAd.ad_owner ?? "",
          category: localAd.category ?? "",
          product: localAd.product ?? "",
          phone: localAd.phone ?? "",
          quantity: localAd.quantity ?? 0,
          unit: localAd.unit ?? "",
          comment: localAd.comment ?? "",
          image: localAd.image ?? "",
          address: {
            region: localAd.address.region ?? "",
            region_id: localAd.address.region_id ?? 0,
            district: localAd.address.district ?? "",
            district_id: localAd.address.district_id ?? 0,
            neighborhood: localAd.address.neighborhood ?? "",
            neighborhood_id: localAd.address.neighborhood_id ?? 0,
            address: localAd.address.address ?? "",
          },
        }
      : {
          company_name: "",
          ad_owner: "",
          category: "",
          product: "",
          phone: "",
          quantity: 0,
          unit: "",
          comment: "",
          ad_type: "",
          image: "",
          address: undefined,
        },
  });

  const { data: region } = useGetReegionList();
  const regionId = form.watch("address.region_id");
  const districtId = form.watch("address.district_id");

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: localAdsFormSchema | any) {
    try {
      const valuesWithImage = await uploadFile<any>(formValues, "image");

      if (localAd) {
        triggerLocalAdsEdit({
          ...valuesWithImage,
          quantity: Number(valuesWithImage.quantity),
        });
      } else {
        await triggerLocalAdsCreate({
          ...valuesWithImage,
          quantity: Number(valuesWithImage.quantity),
        });
      }

      setSheetOpen(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="company_name" label="Kompaniya nomi" />
        <AddressFilterField
          name="regionEdit"
          label="Korhona viloyati"
          data={region}
          required
          regionFieldName="address.region"
          regionIdFieldName="address.region_id"
        />
        <AddressFilterField
          name="districtEdit"
          label="Korhona tumani"
          data={districts}
          required
          regionFieldName="address.district"
          regionIdFieldName="address.district_id"
        />
        <AddressFilterField
          name="neigborhoodEdit"
          label="Korhona mahallasi"
          data={neighborhood}
          required
          regionFieldName="address.neighborhood"
          regionIdFieldName="address.neighborhood_id"
        />
        <TextField name="address.address" label="Manzil" required />
        <TextField name="ad_owner" label="Reklama egasi" />
        <SelectField
          name="category"
          label="Kategoriya nomi"
          data={categories}
          required
        />
        {form.watch("category") && (
          <SelectField
            name="product"
            label="Mahsulot nomi"
            required
            data={categoryType[form.watch("category")]}
          />
        )}
        <TextField name="phone" label="Telefon raqami" />
        <TextField name="quantity" label="Miqdori" />
        <TextField name="unit" label="Birligi" />
        <TextField name="comment" label="Izoh" />
        <FileField name="image" label="Kitob rasmi" />

        {localAd ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
