import { VideoCategory } from './type';

export const getVideoCategory = (item?: VideoCategory) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
  };
};

export const getVideoCategoryList = (data?: VideoCategory[]) => {
  return data?.length
    ? data?.map(item => {
        return getVideoCategory(item);
      })
    : [];
};
