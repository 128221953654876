import { useQuery } from '@tanstack/react-query';
import { getVideoCategoryList } from '../adapter';
import { GetVideoCategory } from '../api';
import { get } from 'lodash';

export const useGetVideoCategoryList = () => {
  const initData = {
    data: getVideoCategoryList(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['video_category'],
    queryFn: () => GetVideoCategory(),
    select: data => ({ data: getVideoCategoryList(get(data, 'data.data')) }),
  });
  return {
    ...data,
    ...args,
  };
};
