import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { useState } from "react";
import { AlertDialog } from "components/AlertDialog";
import { TopCountries, TopProducts } from "modules/statistics/types";
import {
  useTopCountriesList,
  useTopProductsList,
} from "modules/statistics/hooks/useGetStatistics";
import { createTopProductsColumns } from "./columns/TopProductsColumn";
import { createTopCountriesColumns } from "./columns/TopCountriesColumn";
import { DataTable } from "components/DataTable";
import { TableActions } from "components/TableActions";
import {
  useDeleteTopCountries,
  useDeleteTopProducts,
} from "modules/statistics/hooks/useDeleteStatistics";
import { TopProductsForm } from "./forms/TopProductsForm";
import { Sheet } from "components/Sheet";
import { TopCountriesForm } from "./forms/TopCountriesForm";

const Statistics = () => {
  const [topProducts, setTopProducts] = useState<TopProducts | any>();
  const [topCountries, setTopCountries] = useState<TopCountries | any>();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("tab1");

  const { data: TopProductsList } = useTopProductsList();
  const { data: TopCountriesList } = useTopCountriesList();

  const getRowDataTopProducts = (topProductsState: TopProducts) => {
    setTopProducts(topProductsState);
  };

  const getRowDataTopCountries = (topCountriesState: TopCountries) => {
    setTopCountries(topCountriesState);
  };

  const { triggerTopProductsDelete } = useDeleteTopProducts(topProducts?._id!);
  const { triggerTopCountriesDelete } = useDeleteTopCountries(
    topCountries?._id!
  );

  const columnsTopProducts = createTopProductsColumns({
    getRowDataTopProducts,
    setDialogOpen,
    setSheetOpen,
  });

  const columnsTopCountries = createTopCountriesColumns({
    getRowDataTopCountries,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      {selectedTab === "tab1" ? (
        <div className="flex items-center justify-between">
          <h1 className="text-xl mb-3 font-medium">
            Top Mahsulotlar ({TopProductsList?.length})
          </h1>
          <TableActions
            sheetTriggerTitle="Mahsulot qo'shish"
            sheetTitle="Mahsulot qo'shish."
            TableForm={TopProductsForm}
          />
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <h1 className="text-xl mb-3 font-medium">
            Top Eksport ({TopCountriesList?.length})
          </h1>
          <TableActions
            sheetTriggerTitle="Export qo'shish"
            sheetTitle="Export qo'shish."
            TableForm={TopCountriesForm}
          />
        </div>
      )}
      <Tabs value={selectedTab} onValueChange={setSelectedTab}>
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="tab1">Meva va Sabzavotlar eksporti</TabsTrigger>
            <TabsTrigger value="tab2">
              Meva va Sabzavotlar eksport qilingan davlatlar
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="tab1">
          <DataTable columns={columnsTopProducts} data={TopProductsList} />
        </TabsContent>
        <TabsContent value="tab2">
          <DataTable columns={columnsTopCountries} data={TopCountriesList} />
        </TabsContent>
      </Tabs>
      <Sheet
        sheetTitle="Xizmat tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        {selectedTab === "tab1" ? (
          <TopProductsForm
            topProducts={topProducts}
            setSheetOpen={setSheetOpen}
          />
        ) : (
          <TopCountriesForm
            topCountries={topCountries}
            setSheetOpen={setSheetOpen}
          />
        )}
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={
          selectedTab === "tab1"
            ? triggerTopProductsDelete
            : triggerTopCountriesDelete
        }
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Statistics;
