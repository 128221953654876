import { LabAddress } from "modules/lab/type";

export interface LocalAds {
  _id: string;
  company_name?: string;
  ad_owner?: string;
  category?: string;
  product?: string;
  phone?: string;
  quantity?: number;
  unit?: string;
  comment?: string;
  ad_type?: string;
  image: string;
  address: LabAddress;
}

export interface LocalAdsCreate {
  ad_owner?: string;
  ad_type?: string;
  category?: string;
  comment?: string;
  company_name?: string;
  phone?: string;
  product?: string;
  quantity?: number;
  unit?: string;
  image: string;
  address: LabAddress;
}

export interface LocalAdsEditBody {
  id: string;
  values: LocalAdsCreate;
}

export enum STATUS {
  ACTIVE = "approved",
  INPROGRESS = "in progress",
  REJECTED = "rejected",
}
