import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { LabCreate } from '../type';
import { CreateLab } from '../api';

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateLab = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: LabCreate) => CreateLab(values),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: "Biolaboratoriyalar ro'yxati muvaffaqiyatli yaratildi.",
      });
      queryClient.invalidateQueries({ queryKey: ['lab_list'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerLabCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
