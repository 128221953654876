import { useQuery } from '@tanstack/react-query';
import { getRegionList } from '../adapter';
import { GetRegion } from '../api';

export const useGetReegionList = () => {
  const initData = {
    data: getRegionList(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['region_list'],
    queryFn: () => GetRegion(),
  });
  return {
    ...data,
    ...args,
  };
};
