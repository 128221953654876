import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { TopProducts } from "modules/statistics/types";
import React from "react";

interface IProps {
  getRowDataTopProducts: (data: TopProducts) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createTopProductsColumns = ({
  getRowDataTopProducts,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<TopProducts>[] => [
  {
    accessorKey: "export_year",
    header: "Eksport Yili",
  },
  {
    accessorKey: "export_quarter",
    header: "Eksport choragi",
  },
  {
    accessorKey: "product",
    header: "Mahsulot nomi",
    cell: ({ row }) => (
      <div>
        {row.original.product.map((p, index) => (
          <div key={index}>{p.product_name}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "product",
    header: "Mahsulot soni",
    cell: ({ row }) => (
      <div>
        {row.original.product.map((p, index) => (
          <div key={index}>{p.product_quantity}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "product",
    header: "Mahsulot hajmi",
    cell: ({ row }) => (
      <div>
        {row.original.product.map((p, index) => (
          <div key={index}>{p.product_unit}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "product",
    header: "Eksport hajmi (dollarda)",
    cell: ({ row }) => (
      <div>
        {row.original.product.map((p, index) => (
          <div key={index}>
            {p.export_amount} {p.export_unit}
          </div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowDataTopProducts}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
