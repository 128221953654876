import { useMutation } from "@tanstack/react-query";

import { useToast } from "components/ui/use-toast";

import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { LocalAdsAcept } from "../api";

export const useAceptStatus = () => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (id: string) => LocalAdsAcept(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Elon muvaffaqiyatli ozgardi.",
      });
      queryClient.invalidateQueries({ queryKey: ["in_progress_locals"] });
      queryClient.invalidateQueries({ queryKey: ["aprove_locals"] });
      queryClient.invalidateQueries({ queryKey: ["reject_locals"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerAceptStatus: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
