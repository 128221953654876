import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";
import { Form } from "components/ui/form";
import { useCreateTopProducts } from "modules/statistics/hooks/useCreateStatistics";
import { useEditTopProducts } from "modules/statistics/hooks/useEditStatistics";
import { TopProducts } from "modules/statistics/types";
import React from "react";
import { useForm } from "react-hook-form";

import { z } from "zod";
import TopProductsRichInputs from "../multiRichInputs/TopProductsRichInput";

export const formSchema = z.object({
  export_year: z
    .number()
    .min(1900, { message: "Export year must be greater than or equal to 1900" })
    .max(new Date().getFullYear(), {
      message: "Export year cannot be in the future",
    })
    .nonnegative({ message: "Export year is required" }),

  export_quarter: z
    .number()
    .min(1, { message: "Quarter must be at least 1" })
    .max(4, { message: "Quarter must be at most 4" })
    .nonnegative({ message: "Export quarter is required" }),

  product: z
    .array(
      z.object({
        product_name: z
          .string()
          .min(1, { message: "Product name is required" }),
        product_quantity: z
          .number()
          .positive({ message: "Product quantity must be greater than zero" }),
        product_unit: z
          .string()
          .min(1, { message: "Product unit is required" }),
        export_amount: z
          .number()
          .positive({ message: "Export amount must be greater than zero" }),
        export_unit: z.string().min(1, { message: "Export unit is required" }),
      })
    )
    .min(1, { message: "At least one product is required" }),
});

type topProductsFormSchema = z.infer<typeof formSchema>;

interface IProps {
  topProducts?: TopProducts;
  setSheetOpen: (state: boolean) => void;
}

export const TopProductsForm = ({ topProducts, setSheetOpen }: IProps) => {
  const { triggerTopProductsCreate, isPending: isTopProductsCreatePending } =
    useCreateTopProducts({
      setSheetOpen,
    });

  const { triggerTopProductsEdit, isPending: isTopProductsEditPending } =
    useEditTopProducts({
      id: topProducts?._id,
      setSheetOpen,
    });

  const form = useForm<topProductsFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: topProducts
      ? {
          export_year: topProducts.export_year,
          export_quarter: topProducts.export_quarter,
          product: topProducts.product.map((product: any) => ({
            product_name: product.product_name,
            product_quantity: product.product_quantity,
            product_unit: product.product_unit,
            export_amount: product.export_amount,
            export_unit: product.export_unit,
          })),
        }
      : {
          export_year: undefined,
          export_quarter: undefined,
          product: [
            {
              product_name: "",
              product_quantity: undefined,
              product_unit: "",
              export_amount: undefined,
              export_unit: "",
            },
          ],
        },
  });

  async function onSubmit(formValues: topProductsFormSchema | any) {
    if (topProducts) {
      triggerTopProductsEdit(formValues);
    } else {
      await triggerTopProductsCreate(formValues);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <TextField
            type="number"
            name="export_year"
            label="Eksport yili"
            required
          />
          <TextField
            type="number"
            name="export_quarter"
            label="Eksport choragi"
            required
          />
          <TopProductsRichInputs
            name="product"
            addBtnTitle="Mahsulot Qo'shish"
          />
          <LoadingButton
            isLoading={isTopProductsCreatePending || isTopProductsEditPending}
          >
            Saqlash
          </LoadingButton>
        </form>
      </Form>
    </>
  );
};
