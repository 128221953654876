import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from 'components/LoadingButton';
import { TextField } from 'components/fields';
import { Form } from 'components/ui/form';
import { useVideoCategoryCreate } from 'modules/video-category/hooks/useCreateVideoCategory';
import { useEditVideoCategory } from 'modules/video-category/hooks/useEditCideoCategory';
import { VideoCategory } from 'modules/video-category/type';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const categoriesSchema = z.object({
  name: z.string().min(3, { message: ' nomi talab qilinadi' }),
});

type categoriesFormSchema = z.infer<typeof categoriesSchema>;

interface IProps {
  categories?: VideoCategory;
  setSheetOpen: (state: boolean) => void;
}

export default function CategoriesForm({ categories, setSheetOpen }: IProps) {
  const { triggerCreateVideoCategory, isPending: isCreatePending } =
    useVideoCategoryCreate({ setSheetOpen });

  const { triggerEditVideoCategory, isPending: isEditPending } =
    useEditVideoCategory({
      id: categories?._id,
      setSheetOpen,
    });
  const form = useForm<categoriesFormSchema>({
    resolver: zodResolver(categoriesSchema),
    defaultValues: categories
      ? {
          name: categories?.name ?? '',
        }
      : {
          name: '',
        },
  });

  async function onSubmit(formValues: categoriesFormSchema) {
    if (categories) {
      triggerEditVideoCategory(formValues);
    } else {
      triggerCreateVideoCategory(formValues);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="name" label="Kategoriya nomi" required />
        {categories ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
