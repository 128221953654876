import { getAddress } from 'modules/lab/adapter';
import { EksportData, EksportList } from './type';

const getEksport = (item?: EksportList) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    inn: item?.inn ?? '',
    website: item?.website ?? '',
    product_name: item?.product_name ?? '',
    owner: item?.owner ?? '',
    title: item?.title ?? '',
    address: getAddress(item?.address),
    phone: item?.phone ?? '',
  };
};

export const getEksportList = (data?: EksportList[]) => {
  return data?.length
    ? data.map(item => {
        return getEksport(item);
      })
    : [];
};
export const getEksportData = (data?: EksportData) => {
  return {
    count: data?.count ?? 0,
    current_page: data?.current_page ?? 0,
    total_pages: data?.total_pages ?? 0,
    data: getEksportList(data?.data),
  };
};
