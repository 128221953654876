import { DistrictsList } from './type';

const getDistrics = (item?: DistrictsList) => {
  return {
    type: item?.code ?? 0,
    name: item?.name ?? '',
  };
};

export const getDistrictsList = (data?: DistrictsList[]) => {
  return data?.length
    ? data.map(item => {
        return getDistrics(item);
      })
    : [];
};
