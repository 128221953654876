import { TelegramList } from './type';

const getTelegram = (item?: TelegramList) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    image: item?.image ?? '',
    link: item?.link ?? '',
  };
};

export const getTelegramList = (data?: TelegramList[]) => {
  return data?.length
    ? data.map(item => {
        return getTelegram(item);
      })
    : [];
};
