import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { FermerFeedbacks } from "modules/fermer-feedbacks/type";

interface IProps {
  getRowData: (data: FermerFeedbacks) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createFermerFeedbacksColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<FermerFeedbacks>[] => [
  {
    accessorKey: "link",
    header: "Video ID",
  },
  {
    accessorKey: "title",
    header: "Video sarlavha",
  },
  {
    accessorKey: "description",
    header: "Video haqida malumoti",
  },
  {
    accessorKey: "image",
    header: "Rasm",
    cell: ({ row }) => {
      const imageUrl = row.getValue<string>("image");
      const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
      return imageUrl ? (
        <img src={fullImageUrl} alt="news" style={{ width: 50, height: 50 }} />
      ) : (
        <span>Rasm mavjud emas</span>
      );
    },
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
