import { useState } from "react";
import { TableActions } from "components/TableActions";
import { AlertDialog } from "components/AlertDialog";
import Loader from "components/Loader";
import { DataTable } from "components/DataTable";
import { Sheet } from "components/Sheet";
import FermerFeedbacksForm from "./OurCustomersForm";
import { OurCustomers } from "modules/our-customers/type";
import { useGetOurCustomersList } from "modules/our-customers/hooks/useOurCustomersList";
import { useDeleteOurCustomers } from "modules/our-customers/hooks/useDeleteOurCustomers";
import { createOurCustomersColumns } from "./Columns";
import OurCustomersForm from "./OurCustomersForm";

export default function OurCustomersPage() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [ourCustomers, setOurCustomers] = useState<OurCustomers>();

  const { data: fermerFeedbacksList, isLoading } = useGetOurCustomersList();
  const { triggerOurCustomersDelete } = useDeleteOurCustomers(
    ourCustomers?._id!
  );

  const getRowData = (OurCustomers: OurCustomers) => {
    setOurCustomers(OurCustomers);
  };

  const columns = createOurCustomersColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-xl mb-3 font-medium">
          Bizning Hamkorlarimiz ({fermerFeedbacksList?.length})
        </h1>
        <TableActions
          sheetTriggerTitle="Hamkor qo'shish"
          sheetTitle="Hamkor qo'shish."
          TableForm={FermerFeedbacksForm}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={fermerFeedbacksList} />
        </>
      )}
      <Sheet
        sheetTitle="Videoni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <OurCustomersForm
          ourCustomers={ourCustomers}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerOurCustomersDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
}
