import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { TopCountries } from "modules/statistics/types";
import React from "react";

interface IProps {
  getRowDataTopCountries: (data: TopCountries) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createTopCountriesColumns = ({
  getRowDataTopCountries,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<TopCountries>[] => [
  {
    accessorKey: "export_year",
    header: "Eksport yili",
  },
  {
    accessorKey: "export_quarter",
    header: "Eksport choragi",
  },
  {
    accessorKey: "country",
    header: "Shahar nomi",
    cell: ({ row }) => (
      <div>
        {row.original.country.map((c, index) => (
          <div key={index}>{c.country_name}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "country",
    header: "Soni",
    cell: ({ row }) => (
      <div>
        {row.original.country.map((c, index) => (
          <div key={index}>{c.country_quantity}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "country",
    header: "Hajmi",
    cell: ({ row }) => (
      <div>
        {row.original.country.map((c, index) => (
          <div key={index}>{c.country_unit}</div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "country",
    header: "Eksport hajmi (dollarda)",
    cell: ({ row }) => (
      <div>
        {row.original.country.map((c, index) => (
          <div key={index}>
            {c.export_amount} {c.export_unit}
          </div>
        ))}
      </div>
    ),
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowDataTopCountries}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
