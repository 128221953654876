import { FileUploader } from 'react-drag-drop-files';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { X as CloseIcon, FileText } from 'lucide-react';
import calculateFileSize from 'utils/calculateFileSize';

interface IProps {
  name: string;
  label?: string;
  required?: boolean;
}

export default function MultiFileField({ name, label, required }: IProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name, control });

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel>
              {`${label} `}
              {required && (
                <span className="text-red-500 dark:text-red-900">*</span>
              )}
            </FormLabel>
          )}
          <FormControl>
            <>
              <FileUploader
                handleChange={(fileList: FileList) => {
                  Array.from(fileList).forEach(file => {
                    append({
                      file,
                    });
                  });
                }}
                name="files"
                types={['PNG', 'JPG']}
                multiple={true}
              />
              {fields.length > 0 && (
                <div className="flex flex-wrap gap-4">
                  {fields.map((file, index) => (
                    <div
                      key={file.id}
                      className="relative p-1 border rounded-md max-w-fit"
                    >
                      <div className="flex items-center flex-1 gap-2">
                        <FileText className="size-8 stroke-1" />
                        <span className="truncate text-sm flex-[0.65]">
                          {/* @ts-expect-error */}
                          {file.file?.name}
                        </span>
                        <span className="text-sm flex-[0.2]">
                          {/* @ts-expect-error */}
                          {calculateFileSize(file.file?.size)}
                        </span>
                      </div>
                      <div
                        className="absolute top-2 right-2 cursor-pointer bg-slate-300/70"
                        onClick={() => remove(index)}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
