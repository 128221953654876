import { useState } from 'react';

import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';
import { createModuleColumns } from './Columns';

import AboutForm from './AboutForm';
import { AboutList } from 'modules/about/type';
import { useDeleteAbout } from 'modules/about/hooks/useDeleteAbout';
import { useAboutList } from 'modules/about/hooks/useGetAboutList';

const About = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [about, setAbout] = useState<AboutList>();
  const { data: aboutList, isLoading } = useAboutList();
  const { triggerAboutDelete } = useDeleteAbout(about?._id!);

  const getRowData = (aboutState: AboutList) => {
    setAbout(aboutState);
  };

  const columns = createModuleColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Biz haqimiza malumot qo'shish"
        sheetTitle="Yangi haqimiza malumot qo'shish."
        TableForm={AboutForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={aboutList} navigateTable />
      )}

      <Sheet
        sheetTitle="malumot tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <AboutForm setSheetOpen={setSheetOpen} about={about} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerAboutDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default About;
