import { useMutation } from "@tanstack/react-query";
import { queryClient } from "services/react-query";
import { useToast } from "components/ui/use-toast";
import { showErrorToast } from "utils/showErrorToast";
import { DeltePackingCompanies } from "../api";

export const useDeletePackingCompanies = (id: string) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DeltePackingCompanies(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ["packing_companies"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return { triggerPackingCompaniesDelete: mutate, isSuccess, isError };
};
