import { International, STATUS } from "./type";

const getInternational = (item?: International) => {
  return {
    _id: item?._id ?? "", // Unique identifier
    name: item?.name ?? "", // Country name
    url: item?.url ?? "", // URL for more information
    flag: item?.flag ?? "", // URL for country flag image
    status: STATUS.INPROGRESS, // Default status
    created_at: new Date(), // Created date
  };
};

export const getInternationalList = (data?: International[]) => {
  return data?.length
    ? data.map((item) => {
        return getInternational(item);
      })
    : [];
};
