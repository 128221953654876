import { useMutation } from "@tanstack/react-query";

import { useToast } from "components/ui/use-toast";

import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { InternationalReject } from "../api";

interface IHooke {
  id: string;
  reject_reason: string;
}

export const useRejectStatus = () => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: ({ id, reject_reason }: IHooke) =>
      InternationalReject(id, reject_reason),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Elon muvaffaqiyatli ozgardi.",
      });
      queryClient.invalidateQueries({
        queryKey: ["in_progress_international"],
      });
      queryClient.invalidateQueries({ queryKey: ["reject_international"] });
      queryClient.invalidateQueries({ queryKey: ["aprove_international"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerRejectStatus: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
