import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getGrandInfoList } from '../adapters';
import { GetGrandinfosList } from '../api';

export const useGrandInfoList = (moduleId: string) => {
  const initialData = {
    data: getGrandInfoList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['garndinfo_list', moduleId],
    queryFn: () => GetGrandinfosList(moduleId),
    select: grand_information => ({
      data: getGrandInfoList(get(grand_information, 'data.grand_information')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
