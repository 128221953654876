import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { CreateNews } from '../api';
import { NewsInput } from '../types';

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateNews = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: NewsInput) => CreateNews(values),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Yangilik muvaffaqiyatli yaratildi.',
      });
      queryClient.invalidateQueries({ queryKey: ['news_list'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerNewsCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
