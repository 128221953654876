import { Course } from './types';
export const getCourse = (item?: Course) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    description: item?.description ?? '',
    video: item?.video ?? '',
    thumbnail: item?.thumbnail ?? '',
    duration: item?.duration ?? 0,
    order: item?.order ?? 0,
    category: item?.category ?? '',
  };
};

export const getCoursesList = (data?: Course[]) => {
  return data?.length
    ? data.map(item => {
        return getCourse(item);
      })
    : [];
};
