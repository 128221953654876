import { useMutation } from "@tanstack/react-query";
import { queryClient } from "services/react-query";
import { useToast } from "components/ui/use-toast";
import { showErrorToast } from "utils/showErrorToast";
import { DeleteTopCountries, DeleteTopProducts } from "../api";

//top-products*******************************************************
export const useDeleteTopProducts = (id: string) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DeleteTopProducts(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ["top_products"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return { triggerTopProductsDelete: mutate, isSuccess, isError };
};

//top-countries*******************************************************
export const useDeleteTopCountries = (id: string) => {
  const { toast } = useToast();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: () => DeleteTopCountries(id),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyatli o'chirildi!",
      });
      queryClient.invalidateQueries({ queryKey: ["top_countries"] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerTopCountriesDelete: mutate,
    isSuccess,
    isError,
  };
};
