import { useState } from 'react';

import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';

import { EksportList } from 'modules/eksport/type';
import { useGetEksportList } from 'modules/eksport/hooks/usegetEksportList';
import { useDeleteEksport } from 'modules/eksport/hooks/useDeleteEksport';
import { creatEksportColumns } from './Column';
import EksportForm from './EksportForm';
import { Pagination } from 'components/Pagination';

const Eksport = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [eksport, setEksport] = useState<EksportList>();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: eksportList, isLoading } = useGetEksportList(currentPage);
  const { triggerEksportDelete } = useDeleteEksport(eksport?._id!);

  const getRowData = (eksportState: EksportList) => {
    setEksport(eksportState);
  };

  const columns = creatEksportColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Biz haqimiza malumot qo'shish"
        sheetTitle="Malumot qo'shish."
        TableForm={EksportForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={eksportList.data} />
          <Pagination
            next_page={currentPage + 1}
            currentPage={currentPage}
            prev_page={currentPage - 1}
            setCurrentPage={setCurrentPage}
            total_pages={eksportList.total_pages}
            total_records={eksportList.count}
            className="mt-10"
          />
        </>
      )}

      <Sheet
        sheetTitle="malumot tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <EksportForm setSheetOpen={setSheetOpen} eksport={eksport} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerEksportDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Eksport;
