import { useFileUpload } from 'modules/file-upload/hooks/useFileUpload';

export default function useFileUploader() {
  const { triggerFileUpload, isPending } = useFileUpload();

  async function uploadFile<TData>(
    values: Record<string, any>,
    key: keyof TData & string
  ): Promise<TData> {
    const formData = new FormData();
    formData.append('file', values[key]);

    if (values[key] instanceof File) {
      const { data } = await triggerFileUpload(formData);
      return { ...values, [key]: data.data.url } as TData;
    }

    return values as TData;
  }

  return { uploadFile, isPending };
}

export function useMultiFileUploader() {
  const { triggerFileUpload, isPending } = useFileUpload();

  async function uploadFiles<TData>(
    values: Record<string, any>,
    key: keyof TData & string
  ): Promise<TData> {
    const items = values[key];
    let urls: string[] = [];

    if (Array.isArray(items)) {
      for (const item of items) {
        if (item.file instanceof File) {
          const formData = new FormData();
          formData.append('file', item.file);

          try {
            const { data } = await triggerFileUpload(formData);
            if (data.data.url) {
              urls.push(data.data.url);
            }
          } catch (error) {
            console.error('Upload failed for file:', item, error);
          }
        } else if (typeof item === 'string') {
          urls.push(item);
        } else {
          console.warn('Unexpected item type:', item);
        }
      }

      return { ...values, [key]: urls } as TData;
    }

    return values as TData;
  }

  return { uploadFiles, isPending };
}

export function useMultiFileObjectUploader() {
  const { triggerFileUpload, isPending } = useFileUpload();

  async function uploadFiles<TData>(
    values: Record<string, any>,
    arrayKey: keyof TData & string,
    fieldKey: string
  ): Promise<TData> {
    const items = values[arrayKey];
    let updatedItems: any[] = [];

    if (Array.isArray(items)) {
      for (const item of items) {
        if (typeof item === 'object' && item !== null && fieldKey in item) {
          if (item[fieldKey] instanceof File) {
            const formData = new FormData();
            formData.append('file', item[fieldKey]);

            try {
              const { data } = await triggerFileUpload(formData);
              if (data.data.url) {
                updatedItems.push({
                  ...item,
                  [fieldKey]: data.data.url,
                });
              }
            } catch (error) {
              console.error('Upload failed for file:', item, error);
            }
          } else if (typeof item[fieldKey] === 'string') {
            updatedItems.push(item);
          } else {
            console.warn('Unexpected item type for key:', fieldKey);
          }
        } else {
          updatedItems.push(item);
        }
      }

      return { ...values, [arrayKey]: updatedItems } as TData;
    }

    return values as TData;
  }

  return { uploadFiles, isPending };
}
