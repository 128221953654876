import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Book, BookInput } from "modules/books/types";

import useFileUploader from "hooks/useFileUploader";
import { useCreateBook } from "modules/books/hooks/useCreateBook";
import { useEditBook } from "modules/books/hooks/useEditBook";
import { Form } from "components/ui/form";
import { FileField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";

const bookSchema = z.object({
  title: z.string().min(3, { message: "Kitob nomini kiriting" }),
  image: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Rasm talab qilinadi",
    }),
    z.string().min(1, { message: "Rasm talab qilinadi" }),
  ]),
  file: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Kitob fayli kiritilishi kerak",
    }),
    z.string().min(1, { message: "Kitob fayli kiritilishi kerak" }),
  ]),
  rating: z.union([
    z
      .string({ required_error: "Reyting talab qilinadi!" })
      .min(1, { message: "Kamida 1ta son talab qilinadi" }),
    z.number().min(1, { message: "Kamida 1ta son talab qilinadi" }),
  ]),
  pages: z.union([
    z
      .string({ required_error: "Sahifa talab qilinadi!" })
      .min(1, { message: "Kamida 1ta son talab qilinadi" }),
    z.number().min(1, { message: "Kamida 1ta son talab qilinadi" }),
  ]),
});

type bookFormSchema = z.infer<typeof bookSchema>;

interface IProps {
  book?: Book;
  setSheetOpen: (state: boolean) => void;
}

export default function BookForm({ book, setSheetOpen }: IProps) {
  const { uploadFile, isPending: isFileUploadPending } = useFileUploader();
  const { triggerBookCreate, isPending: isBookCreatePending } = useCreateBook();
  const { triggerBookEdit, isPending: isBookEditPending } = useEditBook({
    id: book?._id,
    setSheetOpen,
  });

  const form = useForm<bookFormSchema>({
    resolver: zodResolver(bookSchema),
    defaultValues: book
      ? {
          title: book.title,
          image: book.image,
          file: book.file,
          rating: book.rating,
          pages: book.pages,
        }
      : {
          title: "",
          image: undefined,
          file: undefined,
          rating: undefined,
          pages: undefined,
        },
  });

  async function onSubmit(formValues: bookFormSchema) {
    try {
      const valuesWithBookFile = await uploadFile<BookInput>(
        formValues,
        "file"
      );
      const values = await uploadFile<BookInput>(valuesWithBookFile, "image");

      if (book) {
        if (formValues.file instanceof File) {
          triggerBookEdit({ ...values });
        } else {
          triggerBookEdit(values);
        }
      } else {
        await triggerBookCreate({
          ...values,
          rating: Number(values.rating),
          pages: Number(values.pages),
        });
      }

      setSheetOpen(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }
  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          <div className="flex gap-4 flex-col my-4">
            <TextField name="title" label="Kitob nomi" required />
            <FileField name="file" label="Kitob fayli" isFileUpload required />
            <TextField
              name="rating"
              label="Kitob reytingi"
              type="number"
              required
            />
            <TextField
              name="pages"
              label="Kitob sahifasi soni"
              type="number"
              required
            />

            <FileField name="image" label="Kitob rasmi" required />
          </div>
          {book ? (
            <LoadingButton isLoading={isFileUploadPending || isBookEditPending}>
              Tahrirlash
            </LoadingButton>
          ) : (
            <LoadingButton
              isLoading={isFileUploadPending || isBookCreatePending}
            >
              Saqlash
            </LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
