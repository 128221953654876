import { Book } from './types';
export const getBook = (item?: Book) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    file: item?.file ?? '',
    image: item?.image ?? '',
    rating: item?.rating ?? 0,
    pages: item?.pages ?? 0,
  };
};

export const getBooksList = (data?: Book[]) => {
  return data?.length
    ? data.map(item => {
      return getBook(item);
    })
    : [];
};
