import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { LabList } from 'modules/lab/type';

interface IProps {
  getRowData: (data: LabList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createNewsColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<LabList>[] => [
  {
    accessorKey: 'title',
    header: 'Labaratoriya nomi',
  },
  {
    accessorKey: 'owner',
    header: 'Rahbar',
  },
  {
    accessorKey: 'tin_number',
    header: 'Tin raqami',
  },
  {
    accessorKey: 'phone',
    header: 'Telfon raqami',
  },
  {
    accessorKey: 'address.region',
    header: 'Viloyat',
  },
  {
    accessorKey: 'address.district',
    header: 'Tuman',
  },
  {
    accessorKey: 'address.neighborhood',
    header: 'Mahalla',
  },

  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
