import { Address, Farmer, Market, STATUS } from './type';

const getAddress = (item?: Address) => {
  return {
    region: item?.region ?? '',
    district: item?.district ?? '',
    neighborhood: item?.neighborhood ?? '',
    region_id: item?.region_id ?? 0,
    district_id: item?.district_id ?? 0,
    neighborhood_id: item?.neighborhood_id ?? 0,
  };
};

const getFarmer = (item?: Farmer) => {
  return {
    fio: item?.fio ?? '',
    phone: item?.phone ?? '',
    _id: item?._id ?? '',
  };
};

const getMarket = (item?: Market) => {
  return {
    _id: item?._id ?? '',
    image: item?.image ?? '',
    category: item?.category ?? '',
    price_per_unit: item?.price_per_unit ?? 0,
    status: item?.status ?? STATUS.INPROGRESS,
    created_at: item?.created_at ?? new Date(),
    address: getAddress(item?.address),
    unit: item?.unit,
    farmer: getFarmer(item?.farmer),
    quantity: item?.quantity ?? 0,
    comment: item?.comment ?? '',
    product: item?.product ?? '',
  };
};

export const getMarketList = (data?: Market[]) => {
  return data?.length
    ? data.map(item => {
        return getMarket(item);
      })
    : [];
};
