import { TopProducts } from "./types";
import { TopCountries } from "./types";

//top-products*******************************************************
export const getTopProductItem = (item?: TopProducts) => {
  return {
    _id: item?._id ?? "",
    export_year: item?.export_year ?? 0,
    export_quarter: item?.export_quarter ?? 0,
    product:
      item?.product?.map((p) => ({
        product_name: p.product_name ?? "",
        product_quantity: p.product_quantity ?? 0,
        product_unit: p.product_unit ?? "",
        export_amount: p.export_amount ?? 0,
        export_unit: p.export_unit ?? "",
      })) ?? [],
  };
};

export const getTopProductList = (data?: TopProducts[]) => {
  return data?.length ? data.map((item) => getTopProductItem(item)) : [];
};

//top-countries*******************************************************
export const getTopCountryItem = (item?: TopCountries) => {
  return {
    _id: item?._id ?? "",
    export_year: item?.export_year ?? 0,
    export_quarter: item?.export_quarter ?? 0,
    country:
      item?.country?.map((c) => ({
        country_name: c.country_name ?? "",
        country_quantity: c.country_quantity ?? 0,
        country_unit: c.country_unit ?? "",
        export_amount: c.export_amount ?? 0,
        export_unit: c.export_unit ?? "",
      })) ?? [],
  };
};

export const getTopCountryList = (data?: TopCountries[]) => {
  return data?.length ? data.map((item) => getTopCountryItem(item)) : [];
};
