import http from "services/api";
import { LocalAdsCreate, LocalAdsEditBody, STATUS } from "./type";

export const GetLocalAdsInProgress = async () => {
  return await http.get(`/market/ads/local?status=${STATUS.INPROGRESS}`);
};
export const LocalAdsAcept = async (id: string) => {
  return await http.patch(`market/ads/local/${id}/approve`);
};
export const CreateLocalAds = async (data: LocalAdsCreate) => {
  return await http.post(`/market/ads/local`, data);
};
export const LocalAdsReject = async (id: string, reject_reason: string) => {
  return await http.patch(`market/ads/local/${id}/reject`, {
    reject_reason: reject_reason,
  });
};
export const GetLocalAdsAprove = async () => {
  return await http.get(`market/ads/local?status=${STATUS.ACTIVE}`);
};
export const DelteLocalAds = async (id: string) => {
  return await http.delete(`market/ads/local/${id}`);
};
export const EditLocalAds = async ({ id, values }: LocalAdsEditBody) => {
  return await http.patch(`market/ads/local/${id}`, values);
};
export const GetLocalAdsReject = async () => {
  return await http.get(`/market/ads/local?status=${STATUS.REJECTED}`);
};
