import { ColumnDef } from "@tanstack/react-table";
import { Informations } from "modules/information/types";
import { DataTableRowActions } from "components/DataTableRowActions";

interface IProps {
  getRowData: (reviewGame: Informations) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createReviewGameColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Informations>[] => [
  {
    accessorKey: "title",
    header: "Ma`lumot nomi",
  },
  {
    accessorKey: "image",
    header: "Ma`lumot rasmi",
    cell: ({ row }) => {
      const imageUrl = row.getValue<string>("image");
      const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
      return imageUrl ? (
        <img src={fullImageUrl} alt="news" style={{ width: 50, height: 50 }} />
      ) : (
        <span>Rasm yuklanmagan</span>
      );
    },
  },
  {
    accessorKey: "file",
    header: "",
    cell: ({ row }) => <span />,
  },
  {
    accessorKey: "types",
    header: "",
    cell: ({ row }) => <span />,
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
