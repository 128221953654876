import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getUserList } from '../adapter';
import { GetUsersList } from '../api';

export const useGetUserList = () => {
  const initialData = {
    data: getUserList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['users_list'],
    queryFn: () => GetUsersList(),
    select: data => ({
      data: getUserList(get(data, 'data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
