import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Informations, InformationsInput } from 'modules/information/types';

import { Form } from 'components/ui/form';
import { FileField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import useFileUploader from 'hooks/useFileUploader';
import { useCreateInformation } from 'modules/information/hooks/useCreateInformations';
import { useEditInformation } from 'modules/information/hooks/useEditInformations';

const infoSchema = z.object({
  title: z.string().min(3),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
  // types: z.array(z.string()),
});

type infoFormSchema = z.infer<typeof infoSchema>;

interface IProps {
  info?: Informations;
  setSheetOpen: (state: boolean) => void;
}

export default function InfoForm({ info, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerInformationCreate, isPending: isCreatePending } =
    useCreateInformation({ setSheetOpen });
  const { triggerInformationEdit, isPending: isEditPendint } =
    useEditInformation({ id: info?._id, setSheetOpen });
  const form = useForm<infoFormSchema>({
    resolver: zodResolver(infoSchema),
    defaultValues: info
      ? {
          title: info.title,
          image: info.image,
          // types: info.types,
        }
      : {
          title: '',
          image: undefined,
          // types: [],
        },
  });

  console.log(form.formState.errors);

  async function onSubmit(formValues: infoFormSchema) {
    console.log(formValues.image);

    const values = await uploadFile<InformationsInput>(formValues, 'image');

    if (info) {
      triggerInformationEdit({ ...values });
    } else {
      triggerInformationCreate({ ...values });
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          <div className="flex gap-4 flex-col my-4">
            <TextField name="title" label="Ma'lumot nomi" required />
            <FileField name="image" label="Ma'lumot rasmi" required />
          </div>
          {info ? (
            <LoadingButton isLoading={isEditPendint}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
