import http from 'services/api';
import { CourseEditBody, CourseInput } from './types';

export const GetCoursesList = async (moduleId: string, currentPage: number) => {
  return await http.get(`/videos/`);
};

export const CreateCourse = async (values: CourseInput) => {
  return await http.post(`/videos/`, values);
};

export const EditCourse = async ({ values, id }: CourseEditBody) => {
  return await http.patch(`/videos/${id}`, values);
};

export const DeleteCourse = async (id: string) => {
  return await http.delete(`/videos/${id}`);
};
