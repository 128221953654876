import { ColumnDef } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { convertDate } from "utils/time";
import normalizeFileUrl from "utils/normalizeFileUrl";
import { Farmer, STATUS } from "modules/market/type";

interface IProps {
  setIsOpen: any;
  setMarket: any;
  updateStatus: (rowId: string, status: string, reason?: string) => void;
}

const status: any = {
  approved: "Tastiqlangan",
  "in progress": "Jarayonda",
  rejected: "Rad etilgan",
};

export const createCustomColumns = ({
  setIsOpen,
  setMarket,
  updateStatus,
}: IProps): ColumnDef<any>[] => {
  return [
    {
      accessorKey: "created_at",
      header: "Jo'natilgan sana",
      cell: ({ row }) => <span>{convertDate(row.getValue("created_at"))}</span>,
    },
    {
      accessorKey: "farmer",
      header: "Fermer",
      cell: ({ row }) => {
        const farmer = row.getValue("farmer") as Farmer;
        const { fio } = farmer;

        return <span>{fio}</span>;
      },
    },
    {
      accessorKey: "image",
      header: "Mahsulot rasmi",
      cell: ({ row }) => (
        <img
          src={normalizeFileUrl(row.getValue("image"))}
          alt={row.getValue("category")}
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      accessorKey: "category",
      header: "Mahsulot turi",
      cell: ({ row }) => <span>{row.getValue("category")}</span>,
    },
    { accessorKey: "product", header: "Ekin turi" },
    {
      accessorKey: "quantity",
      header: "Miqdori",
      cell: ({ row }) => <span>{row.getValue("quantity")}</span>,
    },
    {
      accessorKey: "price_per_unit",
      header: "Birlik narxi",
      cell: ({ row }) => <span>{row.getValue("price_per_unit")}</span>,
    },
    {
      accessorKey: "unit",
      header: "Mahsulot miqdori",
      cell: ({ row }) => <span>{row.getValue("unit")}</span>,
    },
    {
      accessorKey: "comment",
      header: "Izoh",
      cell: ({ row }) => <span>{row.getValue("comment")}</span>,
    },
    {
      accessorKey: "status",
      header: "Holati",
      cell: ({ row }) => {
        const statusKey = row.getValue("status") as keyof typeof status;
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">{status[statusKey]}</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onSelect={() => updateStatus(row.original._id, STATUS.ACTIVE)}
                >
                  Faol qilish
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    setIsOpen(true);
                    setMarket(row.original);
                  }}
                >
                  Bekor qilish
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];
};
