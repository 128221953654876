import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Course, CourseInput } from 'modules/courses/types';

import useFileUploader from 'hooks/useFileUploader';
import { useCreateCourse } from 'modules/courses/hooks/useCreateCourse';
import { useEditCourse } from 'modules/courses/hooks/useEditCourse';
import { Form } from 'components/ui/form';
import {
  FileField,
  TextAreaField,
  TextField,
  TimePickerField,
} from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { useMemo } from 'react';
import { convertSecondsToHMS } from 'utils/timeConverter';
import { useGetVideoCategoryList } from 'modules/video-category/hooks/useGetVideoCategoryList';
import SelectCatField from 'components/fields/SelectCat';

const courseSchema = z.object({
  title: z.string().min(3),
  description: z.string().min(3),
  duration: z.number(),
  _duration: z.date().optional(),
  thumbnail: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
  video: z.string().min(3),
  category: z.string().min(1),
});

type courseFormSchema = z.infer<typeof courseSchema>;

interface IProps {
  course?: Course;

  setSheetOpen: (state: boolean) => void;
}
const initialDate = new Date();
initialDate.setHours(0, 0, 0);

export default function CourseForm({ course, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerCourseCreate, isPending: isCourseCreatePending } =
    useCreateCourse({ setSheetOpen });
  const { triggerCourseEdit, isPending: isCourseEditPending } = useEditCourse({
    id: course?._id,
    setSheetOpen,
  });

  const { data: categoryList } = useGetVideoCategoryList();

  const _duration = useMemo(() => {
    const value = new Date(initialDate);
    if (!course?.duration) return value;
    const { hours, minutes, seconds } = convertSecondsToHMS(course.duration);

    value.setHours(hours, minutes, seconds);
    return value;
  }, [course?.duration]);

  const form = useForm<courseFormSchema>({
    resolver: zodResolver(courseSchema),
    defaultValues: course
      ? {
          title: course.title,
          description: course.description,
          thumbnail: course.thumbnail,
          video: course.video,
          duration: course.duration,
          category: course.category,
          _duration,
        }
      : {
          title: '',
          description: '',
          duration: 0,
          _duration,
          video: '',
          category: '',
          thumbnail: undefined,
        },
  });

  async function onSubmit(formValues: courseFormSchema) {
    const values = await uploadFile<CourseInput>(formValues, 'thumbnail');

    const duration = formValues._duration
      ? (formValues._duration.getTime() - new Date(initialDate).getTime()) /
        1000
      : 0;
    formValues.duration = duration;
    delete formValues._duration;

    if (course) {
      triggerCourseEdit({
        ...formValues,
        id: values.id,
        thumbnail: values.thumbnail,
      });
    } else {
      triggerCourseCreate({
        ...formValues,
        id: values.id,
        thumbnail: values.thumbnail,
      });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Video nomi" required />
          <TextField name="video" label="Video linki" required />

          <TimePickerField
            name="_duration"
            label="Darsning davomiyligi"
            required
          />
          <SelectCatField
            name="category"
            data={categoryList}
            label="Kategoriya"
            required
          />

          <TextAreaField
            name="description"
            label="Video qisqacha tavsifi"
            required
          />
          <FileField name="thumbnail" label="Video rasmi" required />
        </div>
        {course ? (
          <LoadingButton isLoading={isCourseEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isCourseCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
