import { ColumnDef } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { convertDate } from "utils/time";
import { LocalAds, STATUS } from "modules/local-ads/type";
import { DataTableRowActions } from "components/DataTableRowActions";

interface IProps {
  setIsOpen: any;
  setLocalAds: any;
  getRowData: (data: LocalAds) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
  updateStatus: (rowId: string, status: string, reason?: string) => void;
}

const status: any = {
  approved: "Tastiqlangan",
  "in progress": "Jarayonda",
  rejected: "Rad etilgan",
};

export const createCustomColumns = ({
  setIsOpen,
  setLocalAds,
  updateStatus,
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<LocalAds>[] => {
  return [
    {
      accessorKey: "created_at",
      header: "Jo'natilgan sana",
      cell: ({ row }) => <span>{convertDate(row.getValue("created_at"))}</span>,
    },
    {
      accessorKey: "company_name",
      header: "Kompaniya nomi",
    },
    {
      accessorKey: "ad_owner",
      header: "Ariza beruvchi shaxs",
    },
    {
      accessorKey: "ad_type",
      header: "Faoliyat turi",
    },
    {
      accessorKey: "phone",
      header: "Telefon",
    },
    {
      accessorKey: "category",
      header: "Mahsulot turi",
    },
    { accessorKey: "product", header: "Mahsulot nomi" },
    {
      accessorKey: "quantity",
      header: "Miqdori",
    },
    {
      accessorKey: "comment",
      header: "Izoh",
    },
    {
      accessorKey: "status",
      header: "Holati",
      cell: ({ row }) => {
        const statusKey = row.getValue("status") as keyof typeof status;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">{status[statusKey]}</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={() => updateStatus(row.original._id, STATUS.ACTIVE)}
              >
                Faol qilish
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  setIsOpen(true);
                  setLocalAds(row.original);
                }}
              >
                Bekor qilish
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "_id",
      header: () => <span className="sr-only">Actions</span>,
      size: 50,
      cell: ({ row }) => (
        <DataTableRowActions
          row={row}
          getRowData={getRowData}
          setDialogOpen={setDialogOpen}
          setSheetOpen={setSheetOpen}
        />
      ),
    },
  ];
};
