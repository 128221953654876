import http from 'services/api';
import { InspektorCreate, InspektorEdit } from './type';

export const GetInspectorsList = async (currentPage: number) => {
  return await http.get(`agro-service/inspectors?page=${currentPage}&size=10`);
};
export const CreateInspector = async (values: InspektorCreate) => {
  return await http.post(`agro-service/inspector`, values);
};
export const EditInspector = async ({ id, values }: InspektorEdit) => {
  return await http.patch(`agro-service/inspector/${id}`, values);
};
export const DeleteInspector = async (id: string) => {
  return await http.delete(`agro-service/inspector/${id}`);
};
