import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Form } from "components/ui/form";
import { TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";

import { PackingCompaniesList } from "modules/packing-companies/type";

import { useGetReegionList } from "modules/region/hooks/useGetRegionList";
import AddressFilterField from "components/fields/AddressFilter";
import { useGetDistrictsList } from "modules/districts/hooks/useGetDistrictsList";
import { useGetNeighborhoodList } from "modules/neighborhood/hooks/useGetNeighborhoodList";
import { useCreatePackingCompanies } from "modules/packing-companies/hooks/useCreatePackingCompanies";

export const addressSchema = z.object({
  region: z.string().min(1, { message: "Region is required" }),
  district: z.string().min(1, { message: "District is required" }),
  address: z.string().min(3, { message: "Address is required" }),
  neighborhood: z.string().min(1, { message: "Neighborhood is required" }),
  region_id: z.number().min(1),
  district_id: z.number().min(1),
  neighborhood_id: z.number().min(1),
});

export const packingCompaniesFormSchema = z.object({
  address: addressSchema,
  name: z.string().min(3, { message: "Company name is required" }),
  phone: z
    .string()
    .min(9, { message: "Phone number is required" })
    .max(12, { message: "Phone number must be between 9 and 12 characters" }),
  owner: z.string(),
  product_name: z.string(),
  sorting_line_availability: z.boolean().optional(), // New field
  warehouse: z
    .object({
      is_available: z.boolean().optional(),
      volume: z.string().optional(),
      unit: z.string().optional(),
    })
    .optional(),
  refrigerator: z
    .object({
      is_available: z.boolean().optional(),
      volume: z.string().optional(),
      unit: z.string().optional(),
    })
    .optional(),
});

type packingCompaniesFormSchema = z.infer<typeof packingCompaniesFormSchema>;

interface IProps {
  packingCompanies?: PackingCompaniesList;
  setSheetOpen: (state: boolean) => void;
}

export default function PackingCompaniesForm({
  packingCompanies,
  setSheetOpen,
}: IProps) {
  const { triggerPackingCompaniesCreate, isPending: isCreatePending } =
    useCreatePackingCompanies({
      setSheetOpen,
    });

  const form = useForm<packingCompaniesFormSchema>({
    resolver: zodResolver(packingCompaniesFormSchema),
    defaultValues: packingCompanies
      ? {
          name: packingCompanies.name ?? "",
          phone: packingCompanies.phone ?? "",
          address: {
            region: packingCompanies.address.region ?? "",
            region_id: packingCompanies.address.region_id ?? 0,
            district: packingCompanies.address.district ?? "",
            district_id: packingCompanies.address.district_id ?? 0,
            neighborhood: packingCompanies.address.neighborhood ?? "",
            neighborhood_id: packingCompanies.address.neighborhood_id ?? 0,
            address: packingCompanies.address.address ?? "",
          },
          product_name: packingCompanies.product_name ?? "",
          owner: packingCompanies.owner ?? "",
          sorting_line_availability:
            packingCompanies.sorting_line_availability ?? false,
          warehouse: {
            is_available: packingCompanies.warehouse?.is_available ?? false,
            volume: packingCompanies.warehouse?.volume ?? "",
            unit: packingCompanies.warehouse?.unit ?? "",
          },
          refrigerator: {
            is_available: packingCompanies.refrigerator?.is_available ?? false,
            volume: packingCompanies.refrigerator?.volume ?? "",
            unit: packingCompanies.refrigerator?.unit ?? "",
          },
        }
      : {
          name: "",
          phone: "",
          address: undefined,
          owner: "",
          product_name: "",
          sorting_line_availability: false,
          warehouse: {
            is_available: false,
            volume: "",
            unit: "",
          },
          refrigerator: {
            is_available: false,
            volume: "",
            unit: "",
          },
        },
  });

  const { data: region } = useGetReegionList();
  const regionId = form.watch("address.region_id");
  const districtId = form.watch("address.district_id");

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: packingCompaniesFormSchema) {
    await triggerPackingCompaniesCreate(formValues);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="name" label="Korhona nomi" required />
        <AddressFilterField
          name="regionEdit"
          label="Korhona viloyati"
          data={region}
          required
          regionFieldName="address.region"
          regionIdFieldName="address.region_id"
        />
        <AddressFilterField
          name="districtEdit"
          label="Korhona tumani"
          data={districts}
          required
          regionFieldName="address.district"
          regionIdFieldName="address.district_id"
        />
        <AddressFilterField
          name="neigborhoodEdit"
          label="Korhona mahallasi"
          data={neighborhood}
          required
          regionFieldName="address.neighborhood"
          regionIdFieldName="address.neighborhood_id"
        />
        <TextField name="address.address" label="Manzil" required />
        <TextField name="phone" label="Korhona raqami" required />
        <TextField
          name="product_name"
          label="Eksport qiluvchi to'liq nomi"
          required
        />
        <TextField name="owner" label="Korhona rahbari" required />
        <TextField
          name="sorting_line_availability"
          label="Saralash liniyasi mavjudligi"
          type="checkbox" // Assuming you want a checkbox for boolean
        />
        <TextField name="warehouse.volume" label="Ombor hajmi" required />
        <TextField name="warehouse.unit" label="Ombor birligi" required />
        <TextField
          name="refrigerator.volume"
          label="Sovutkich hajmi"
          required
        />
        <TextField
          name="refrigerator.unit"
          label="Sovutkich birligi"
          required
        />
        <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
      </form>
    </Form>
  );
}
