import http from 'services/api';
import { Subinfo, SubinfoBody, SubinfoCreateInput } from './types';

export const GetSubinfoList = async (courseId: string) => {
  return await http.get<Subinfo>(`/information/${courseId}/`);
};

export const CreateSubinfo = async (values: SubinfoCreateInput) => {
  return await http.post(`/information/subinformation/`, values);
};

export const EditSubinfo = async ({ values, id }: SubinfoBody) => {
  return await http.patch(`/information/subinformation/${id}`, values);
};

export const DeleteSubinfo = async (id: string) => {
  return await http.delete(`/information/subinformation/${id}`);
};
