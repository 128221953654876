import { useState } from 'react';

import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import { DataTable } from 'components/DataTable';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';

import { useDeleteTelegram } from 'modules/telegram/hooks/useDeleteTelegram';
import { TelegramList } from 'modules/telegram/type';
import { useTelegramList } from 'modules/telegram/hooks/useGetTelegramList';
import { createNewsColumns } from './Columns';
import TelegramForm from './TelegramForm';

const Telegram = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [tg, setTg] = useState<TelegramList>();

  const { data: tgList, isLoading } = useTelegramList();

  const { triggerTelegramDelete } = useDeleteTelegram(tg?._id!);
  const getRowData = (tgState: TelegramList) => {
    setTg(tgState);
  };

  const columns = createNewsColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Telegram havola qo'shish"
        sheetTitle="Telegram havola qo'shish."
        TableForm={TelegramForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={tgList} />
        </>
      )}
      <Sheet
        sheetTitle="Telegram havola tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <TelegramForm tg={tg} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerTelegramDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Telegram;
