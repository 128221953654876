import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { useToast } from "components/ui/use-toast"
import { AuthContext } from 'providers/auth';
import http from 'services/api';

import { SignIn } from '../api';
import { type IApi } from '../types';

export const useSignIn = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: IApi.SignIn) => SignIn(values),
    onSuccess: (data) => {
      http.defaults.headers.common = {
        Authorization: data.data.access_token,
      };
      localStorage.setItem('access', data.data.access_token);
      setIsAuthenticated(true);
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Yosh fermerlar platformasiga xush kelibsiz!",
      })
      navigate('/');
      console.log(data);

    },
    onError: (error: any) => {
      localStorage.clear();
      error.response.data.non_field_errors.forEach((item: string) => {
        toast({
          variant: "destructive",
          title: "Xatolik!",
          description: item,
        })
      });
      setIsAuthenticated(false);
    },
  });

  return {
    triggerSignIn: mutate,
    isPending,
    isSuccess,
    isError
  };
};
