import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { OurCustomers } from "modules/our-customers/type";

interface IProps {
  getRowData: (data: OurCustomers) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createOurCustomersColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<OurCustomers>[] => [
  {
    accessorKey: "name",
    header: "Hamkor websiti",
  },
  {
    accessorKey: "image",
    header: "Rasm",
    cell: ({ row }) => {
      const imageUrl = row.getValue<string>("image");
      const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
      return imageUrl ? (
        <img
          src={fullImageUrl}
          alt="news"
          style={{ width: 50 }}
          className="bg-cover object-cover"
        />
      ) : (
        <span>Rasm mavjud emas</span>
      );
    },
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
