import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { News, NewsInput } from "modules/news/types";

import useFileUploader from "hooks/useFileUploader";
import { useCreateNews } from "modules/news/hooks/useCreateNews";
import { useEditNews } from "modules/news/hooks/useEditNews";
import { Form } from "components/ui/form";
import { FileField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";
import TextEditor from "components/fields/TextEditor";

const newsSchema = z.object({
  title: z.string().min(3, { message: "Yanglik nomi talab qilinadi" }),
  paragraphs: z.array(
    z.string().min(3, { message: "Paragraf talab qilinadi" })
  ),
  image: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Rasm talab qilinadi",
    }),
    z.string().min(1, { message: "Rasm talab qilinadi" }),
  ]),
});

type newsFormSchema = z.infer<typeof newsSchema>;

interface IProps {
  news?: News;
  setSheetOpen: (state: boolean) => void;
}

export default function NewsForm({ news, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerNewsCreate, isPending: isNewsCreatePending } = useCreateNews({
    setSheetOpen,
  });
  const { triggerNewsEdit, isPending: isNewsEditPending } = useEditNews({
    id: news?._id,
    setSheetOpen,
  });

  const form = useForm<newsFormSchema>({
    resolver: zodResolver(newsSchema),
    defaultValues: news
      ? {
          title: news.title,
          paragraphs: Array.isArray(news.paragraphs)
            ? news.paragraphs
            : [news.paragraphs],
          image: news.image,
        }
      : {
          title: "",
          paragraphs: [""],
          image: undefined,
        },
  });

  async function onSubmit(formValues: newsFormSchema) {
    const values = await uploadFile<NewsInput>(formValues, "image");

    if (news) {
      triggerNewsEdit(values);
    } else {
      await triggerNewsCreate(values);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Yangilik nomi" required />
          <TextEditor name="paragraphs" label="Paragraf" required />
          <FileField name="image" label="Yangilik rasmi" required />

          <LoadingButton isLoading={isNewsCreatePending || isNewsEditPending}>
            Saqlash
          </LoadingButton>
        </form>
      </Form>
    </>
  );
}
