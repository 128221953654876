import { getAddress } from "modules/lab/adapter";
import { LocalAds, STATUS } from "./type";

const getLocalAds = (item?: LocalAds) => {
  return {
    _id: item?._id ?? "",
    ad_owner: item?.ad_owner ?? "",
    ad_type: item?.ad_type ?? "",
    category: item?.category ?? "",
    status: STATUS.INPROGRESS,
    created_at: new Date(),
    unit: item?.unit ?? "",
    quantity: item?.quantity ?? 0,
    comment: item?.comment ?? "",
    product: item?.product ?? "",
    phone: item?.phone ?? "",
    company_name: item?.company_name ?? "",
    image: item?.image ?? "",
    address: getAddress(item?.address),
  };
};

export const getLocalAdsList = (data?: LocalAds[]) => {
  return data?.length
    ? data.map((item) => {
        return getLocalAds(item);
      })
    : [];
};
