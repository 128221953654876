import { ServicesList } from './type';

const getService = (item?: ServicesList) => {
  return {
    title: item?.title ?? '',
    _id: item?._id ?? '',
    type: item?.type ?? '',
    image: item?.image ?? '',
  };
};
export const getServicesList = (data?: ServicesList[]) => {
  return data?.length
    ? data.map(item => {
        return getService(item);
      })
    : [];
};
