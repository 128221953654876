import { getAddress } from 'modules/lab/adapter';
import { Users } from './type';

export const getUser = (item?: Users) => {
  return {
    _id: item?._id ?? '',
    fio: item?.fio ?? '',
    phone: item?.phone ?? '',
    sex: item?.sex ?? 1,
    birth_date: item?.birth_date ?? new Date(),
    address: getAddress(item?.address) ?? undefined,
  };
};

export const getUserList = (data?: Users[]) => {
  return data?.length
    ? data.map(item => {
        return getUser(item);
      })
    : [];
};
