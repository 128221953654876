import { Subinfo } from './types';
export const getSubinfo = (item?: Subinfo) => {
  return {
    _id: item?._id ?? '',
    crop_type: item?.crop_type ?? '',
    activity_type: item?.activity_type ?? '',
    image: item?.image ?? 0,
  };
};

export const getSubinfoList = (data?: Subinfo[]) => {
  return data?.length
    ? data.map(item => {
        return getSubinfo(item);
      })
    : [];
};
