import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { SelectField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

import { useEditLab } from 'modules/lab/hooks/useEditLab';
import { useCreateLab } from 'modules/lab/hooks/useCreateLab';
import { LabList } from 'modules/lab/type';

import { useGetReegionList } from 'modules/region/hooks/useGetRegionList';
import AddressFilterField from 'components/fields/AddressFilter';
import { useGetDistrictsList } from 'modules/districts/hooks/useGetDistrictsList';
import { useGetNeighborhoodList } from 'modules/neighborhood/hooks/useGetNeighborhoodList';
import { regionsType } from 'constants/general';

export const addressSchema = z.object({
  region: z.string().min(1),
  district: z.string().min(1),
  address: z.string().min(3),
  neighborhood: z.string().min(1),
  region_id: z.number().min(1),
  district_id: z.number().min(1),
  neighborhood_id: z.number().min(1),
});
export const editAdrressSvhema = z.object({
  name: z.string().optional(),
  code: z.number().optional(),
});

const labSchema = z.object({
  title: z.string().min(3, { message: ' nomi talab qilinadi' }),
  region: z.string().min(1, { message: 'Region is required' }),
  phone: z
    .string()
    .min(9, { message: 'Phone number is required' })
    .max(12, { message: 'Phone number is required' }),
  trixogramma: z.string().min(3, { message: 'Trixogramma is required' }),
  brakon: z.string().min(3, { message: 'Brakon is required' }),
  address: addressSchema,
  owner: z.string().min(3),
  oltin_kuz: z.string().min(3),
  tin_number: z.string().min(3),
  regionEdit: editAdrressSvhema.optional(),
  districtEdit: editAdrressSvhema.optional(),
  neigborhoodEdit: editAdrressSvhema.optional(),
});

type labFormSchema = z.infer<typeof labSchema>;

interface IProps {
  lab?: LabList;
  setSheetOpen: (state: boolean) => void;
}

export default function LabForm({ lab, setSheetOpen }: IProps) {
  const { triggerLabCreate, isPending: isCreatePending } = useCreateLab({
    setSheetOpen,
  });
  const { triggerLabEdit, isPending: isEditPending } = useEditLab({
    id: lab?._id,
    setSheetOpen,
  });

  const form = useForm<labFormSchema>({
    resolver: zodResolver(labSchema),
    defaultValues: lab
      ? {
          title: lab.title ?? '',
          region: lab.region ?? '',
          phone: lab.phone ?? '',
          trixogramma: lab.trixogramma ?? '',
          brakon: lab.brakon ?? '',
          address: {
            region: lab.address.region ?? '',
            region_id: lab.address.region_id ?? 0,
            district: lab.address.district ?? '',
            district_id: lab.address.district_id ?? 0,
            neighborhood: lab.address.neighborhood ?? '',
            neighborhood_id: lab.address.neighborhood_id ?? 0,
            address: lab.address.address ?? '',
          },
          owner: lab.owner ?? '',
          oltin_kuz: lab.oltin_kuz ?? '',
          tin_number: lab.tin_number ?? '',
          regionEdit: {
            name: lab.address.region ?? '',
            code: lab?.address.region_id ?? 0,
          },
          districtEdit: {
            name: lab.address.district ?? '',
            code: lab.address.district_id ?? 0,
          },
          neigborhoodEdit: {
            name: lab.address.neighborhood ?? '',
            code: lab.address.neighborhood_id ?? 0,
          },
        }
      : {
          title: '',
          region: '',
          phone: '',
          trixogramma: '',
          brakon: '',
          address: undefined,
          owner: '',
          oltin_kuz: '',
          tin_number: '',
        },
  });
  const { data: region } = useGetReegionList();
  const regionId = form.watch('address.region_id');
  const districtId = form.watch('address.district_id');

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: labFormSchema) {
    delete formValues.regionEdit;
    delete formValues.districtEdit;
    delete formValues.neigborhoodEdit;

    if (lab) {
      triggerLabEdit(formValues);
    } else {
      triggerLabCreate(formValues);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Labaratoriya nomi" required />

          <SelectField
            name="region"
            label="Viloyat nomi"
            data={regionsType}
            required
          />

          <AddressFilterField
            name="regionEdit"
            label="Labaratoriya viloyat nomi"
            data={region}
            required
            regionFieldName="address.region"
            regionIdFieldName="address.region_id"
          />
          <AddressFilterField
            name="districtEdit"
            label="Labaratoriya tuman nomi"
            data={districts}
            required
            regionFieldName="address.district"
            regionIdFieldName="address.district_id"
          />
          <AddressFilterField
            name="neigborhoodEdit"
            label="Labaratoriya mahalla nomi"
            data={neighborhood}
            required
            regionFieldName="address.neighborhood"
            regionIdFieldName="address.neighborhood_id"
          />
          <TextField name="address.address" label="Manzili" required />
          <TextField name="phone" label="Labaratoriya raqami" required />
          <TextField name="Trixogramma" label="Trixogramma" required />
          <TextField name="brakon" label="Labaratoriya brakon" required />
          <TextField name="owner" label="Labaratoriya raxbari" required />
          <TextField name="oltin_kuz" label="Oltin kuz" required />
          <TextField name="tin_number" label="Tin raqami" required />

          {lab ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
