export const text = {
  hour: "soat",
};
export const categories = [
  {
    name: "Sabzavot ekinlari",
    type: "Sabzavot ekinlari",
  },
  {
    name: "Poliz ekinlari",
    type: "Poliz ekinlari",
  },
  {
    name: "Don va dukkakli ekinlar",
    type: "Don va dukkakli ekinlar",
  },
  {
    name: "Moyli va yem hashak ekinlari",
    type: "Moyli va yem hashak ekinlari",
  },
  {
    name: "Bog'dorchilik",
    type: "Bog'dorchilik",
  },
  {
    name: "Sitrus ekinlari",
    type: "Sitrus ekinlari",
  },
  {
    name: "Chorvachilik",
    type: "Chorvachilik",
  },
  {
    name: "Parrandachilik",
    type: "Parrandachilik",
  },
  {
    name: "Issiqxonachilik",
    type: "Issiqxonachilik",
  },
  {
    name: "Paxtachilik",
    type: "Paxtachilik",
  },
  {
    name: "G'allachilik",
    type: "G'allachilik",
  },
  {
    name: "Baliqchilik",
    type: "Baliqchilik",
  },
  {
    name: "Gulchilik",
    type: "Gulchilik",
  },
];

export const categoryType: any = {
  "Sabzavot ekinlari": [
    { name: "Piyoz", type: "Piyoz" },
    { name: "Kartoshka", type: "Kartoshka" },
    { name: "Sholg'om", type: "Sholg" },
    { name: "Turp", type: "Turp" },
    { name: "Oq bosh karam", type: "Oq bosh karam" },
    { name: "Rediska", type: "Rediska" },
    { name: "Petrushka", type: "Petrushka" },
    { name: "Salat bargi", type: "Salat bargi" },
    { name: "Ukrop", type: "Ukrop" },
    { name: "Kashnich", type: "Kashnich" },
    { name: "Sabzi", type: "Sabzi" },
    { name: "Osh lavlagi", type: "Osh lavlagi" },
    { name: "Bodring", type: "Bodring" },
    { name: "Pomidor", type: "Pomidor" },
    { name: "Shirin qalampir", type: "Shirin qalampir" },
    { name: "Achchiq qalampir", type: "Achchiq qalampir" },
    { name: "Baqlajon", type: "Baqlajon" },
    { name: "Sarimsoq piyoz", type: "Sarimsoq piyoz" },
  ],
  "Poliz ekinlari": [
    { name: "Qovun", type: "Qovun" },
    { name: "Tarvuz", type: "Tarvuz" },
    { name: "Qovoq", type: "Qovoq" },
  ],
  "Don va dukkakli ekinlar": [
    { name: "Arpa", type: "Arpa" },
    { name: "Sholi", type: "Sholi" },
    { name: "Makkajo'xori", type: "Makkajo'xori" },
    { name: "Jo'xori", type: "Jo'xori" },
    { name: "Suli", type: "Suli" },
    { name: "Tariq", type: "Tariq" },
    { name: "Bug'doy", type: "Bug'doy" },
    { name: "Murjumalak", type: "Murjumalak" },
    { name: "No'xot", type: "No'xot" },
    { name: "Loviya", type: "Loviya" },
    { name: "Soya", type: "Soya" },
    { name: "Mosh", type: "Mosh" },
    { name: "Ko'k No'xot", type: "Ko'k No'xot" },
    { name: "Yasmiq", type: "Yasmiq" },
    { name: "Burchoq", type: "Burchoq" },
    { name: "Xitoy loviyasi (Vigna)", type: "Xitoy loviyasi (Vigna)" },
  ],
  "Moyli va yem hashak ekinlari": [
    { name: "Kungaboqar", type: "Kungaboqar" },
    { name: "Maxsar", type: "Maxsar" },
    { name: "Kunjut", type: "Kunjut" },
    { name: "Yeryong'oq", type: "Yeryong'oq" },
    { name: "Moyli zig'ir", type: "Moyli zig'ir" },
    { name: "Beda", type: "Beda" },
    { name: "Qizil sebarga", type: "Qizil sebarga" },
    { name: "Sudan o'ti", type: "Sudan o'ti" },
  ],
  "Bog'dorchilik": [
    { name: "Olma", type: "Olma" },
    { name: "Nok", type: "Nok" },
    { name: "Bexi", type: "Bexi" },
    { name: "O'rik", type: "O'rik" },
    { name: "Gilos", type: "Gilos" },
    { name: "Shaftoli", type: "Shaftoli" },
    { name: "Olxo'ri", type: "Olxo'ri" },
    { name: "Uzum", type: "Uzum" },
    { name: "Xurmo", type: "Xurmo" },
    { name: "Anor", type: "Anor" },
    { name: "Anjir", type: "Anjir" },
    { name: "Yong'oq", type: "Yong'oq" },
    { name: "Jiyda", type: "Jiyda" },
  ],
  "Sitrus ekinlari": [
    { name: "Limon", type: "Limon" },
    { name: "Apelsin", type: "Apelsin" },
    { name: "Mandarin", type: "Mandarin" },
  ],
  Chorvachilik: [
    { name: "Buqa", type: "Buqa" },
    { name: "Echki", type: "Echki" },
    { name: "Ot", type: "Ot" },
    { name: "Tuya", type: "Tuya" },
    { name: "Qo'y", type: "Qo'y" },
    { name: "Quyon", type: "Quyon" },
  ],
  Parrandachilik: [
    { name: "Tovuq", type: "Tovuq" },
    { name: "Kurka", type: "Kurka" },
    { name: "Bedana", type: "Bedana" },
    { name: "G'oz", type: "G'oz" },
    { name: "O'rdak", type: "O'rdak" },
    { name: "Sesarka", type: "Sesarka" },
    { name: "Asalari", type: "Asalari" },
  ],
  Issiqxonachilik: [
    { name: "Bodring", type: "Bodring" },
    { name: "Pamidor", type: "Pamidor" },
    { name: "Limon", type: "Limon" },
    { name: "Shirin qalampir", type: "Shirin qalampir" },
    { name: "Qulupnay", type: "Qulupnay" },
  ],
  Paxtachilik: [{ name: "Paxtachilik", type: "Paxtachilik" }],
  "G'allachilik": [{ name: "G'allachilik", type: "G'allachilik" }],
  Baliqchilik: [
    { name: "Zog'ora", type: "Zog'ora" },
    { name: "Laqqa", type: "Laqqa" },
    { name: "Cho'rtan", type: "Cho'rtan" },
    { name: "Oq amur", type: "Oq amur" },
    { name: "Oqcha", type: "Oqcha" },
    { name: "Ilon bosh", type: "Ilon bosh" },
    { name: "Bakra", type: "Bakra" },
  ],
  Gulchilik: [
    { name: "Atirgul", type: "Atirgul" },
    { name: "Chinnigul", type: "Chinnigul" },
    { name: "Gladiolus", type: "Gladiolus" },
    { name: "Xrizantema", type: "Xrizantema" },
    { name: "Lola", type: "Lola" },
  ],
};

export const servicesType = [
  { name: "Biolaboratoriyalar ro'yxati", type: "laboratoriyalar" },
  { name: "Tuproq tahlil laboratoriyalari", type: "tuproq" },
  { name: "Eksportchi korxonalar", type: "eksportchi" },
  { name: "Inspektor bilan bog’lanish", type: "inspektor" },
  { name: "Foydali telegram guruhlar", type: "telegram" },
  { name: "“Yosh fermerlar” loyihasi haqida", type: "about" },
];

export const regionsType = [
  {
    name: "Qoraqalpog'iston Respublikasi",
    type: "Qoraqalpog'iston Respublikasi",
  },
  { name: "Buxoro viloyati", type: "Buxoro viloyati" },
  { name: "Jizzax viloyati", type: "Jizzax viloyati" },
  { name: "Andijon viloyati", type: "Andijon viloyati" },
  { name: "Qashqadaryo viloyati", type: "Qashqadaryo viloyati" },
  { name: "Sirdaryo viloyati", type: "Sirdaryo viloyati" },
  { name: "Namangan viloyati", type: "Namangan viloyati" },
  { name: "Navoiy viloyati", type: "Navoiy viloyati" },
  { name: "Surxondaryo viloyati", type: "Surxondaryo viloyati" },
  { name: "Xorazm viloyati", type: "Xorazm viloyati" },
  { name: "Samarqand viloyati", type: "Samarqand viloyati" },
  { name: "Toshkent viloyati", type: "Toshkent viloyati" },
  { name: "Farg'ona viloyati", type: "Farg'ona viloyati" },
];
