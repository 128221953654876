import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getLabList } from '../adapter';
import { GetLabList } from '../api';

export const useLabList = (currentPage: number) => {
  const initialData = {
    data: getLabList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['lab_list', currentPage],
    queryFn: () => GetLabList(currentPage),
    select: data => ({
      data: getLabList(get(data, 'data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
