import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Subinfo } from 'modules/subinfo/types';
import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';
import { createModuleColumns } from './Columns';
import SubInfoForm from './SubInfoForm';
import { useSubinfosList } from 'modules/subinfo/hooks/useSubinfoList';
import { useDeleteSubinfo } from 'modules/subinfo/hooks/useDeleteSubinfo';

const Modules = () => {
  const { courseId } = useParams();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [sub_info, setSub_info] = useState<Subinfo>();
  const { data: subinfoList, isLoading } = useSubinfosList(courseId!);
  const { triggerSubinfoDelete } = useDeleteSubinfo(sub_info?._id!);

  const getRowData = (subInfo: Subinfo) => {
    setSub_info(subInfo);
  };

  const columns = createModuleColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Bo'lim qo'shish"
        sheetTitle="Yangi bo'lim qo'shish."
        TableForm={SubInfoForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={subinfoList} navigateTable />
      )}

      <Sheet
        sheetTitle="Bo'limni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <SubInfoForm setSheetOpen={setSheetOpen} subInfo={sub_info} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerSubinfoDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Modules;
