import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { InspektorList } from 'modules/inspektor/type';

interface IProps {
  getRowData: (data: InspektorList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const creatInspectorColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<InspektorList>[] => [
  {
    accessorKey: 'inspector_full_name',
    header: 'Rahbar',
  },
  {
    accessorKey: 'phone_number',
    header: 'Telefon raqami',
  },
  {
    accessorKey: 'position',
    header: 'Lavozim',
  },

  {
    accessorKey: 'address.region',
    header: 'Viloyat',
  },
  {
    accessorKey: 'address.district',
    header: 'Tuman',
  },
  {
    accessorKey: 'address.neighborhood',
    header: 'Mahalla',
  },

  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
