import http from 'services/api';
import { Informations, InformationsEditBody, InformationsInput } from './types';

export const GetInformationsList = async () => {
  return await http.get(`/information/`);
};

export const CreateInformations = async (values: InformationsInput) => {
  return await http.post<{ data: Informations }>(`/information/`, values);
};

export const GetInformation = async (id: string) => {
  return await http.get<{ data: Informations }>(`/information/${id}`);
};

export const EditInformation = async ({ values, id }: InformationsEditBody) => {
  return await http.patch(`/information/${id}`, values);
};

export const DeleteInformation = async (id: string) => {
  return await http.delete(`/information/${id}`);
};
