import { useQuery } from '@tanstack/react-query';
import { getNeighborhoodList } from '../adapter';
import { GetNeighborhoodList } from '../api';

export const useGetNeighborhoodList = (
  region_code: number,
  district_code: number
) => {
  const initData = {
    data: getNeighborhoodList(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['neighborhood_list', region_code, district_code],
    queryFn: () => GetNeighborhoodList(region_code, district_code),
    enabled: !!region_code && !!district_code,
  });
  return {
    ...data,
    ...args,
  };
};
