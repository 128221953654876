import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getServicesList } from '../adapter';
import { GetServicesList } from '../api';

export const useServicesList = () => {
  const initialData = {
    data: getServicesList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['services_list'],
    queryFn: () => GetServicesList(),
    select: data => ({
      data: getServicesList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
