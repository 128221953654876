import http from "services/api";
import { OurCustomers, OurCustomersCreate, OurCustomersEditBody } from "./type";

export const OurCustomersList = async () => {
  return await http.get(`/landing/our-customers`);
};

export const CreateOurCustomers = async (values: OurCustomersCreate) => {
  return await http.post<{ data: OurCustomers }>(
    `/landing/our-customers`,
    values
  );
};

export const EditOurCustomers = async ({
  values,
  id,
}: OurCustomersEditBody) => {
  return await http.patch(`/landing/our-customers/${id}`, values);
};

export const DeleteOurCustomers = async (id: string) => {
  return await http.delete(`/landing/our-customers/${id}`);
};
