import { useMutation } from '@tanstack/react-query';

import { useToast } from 'components/ui/use-toast';

import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { MarketAcept } from '../api';

export const useAceptStatus = () => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (id: string) => MarketAcept(id),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Elon muvaffaqiyatli ozgardi.',
      });
      queryClient.invalidateQueries({ queryKey: ['in_progress'] });
      queryClient.invalidateQueries({ queryKey: ['aprove'] });
      queryClient.invalidateQueries({ queryKey: ['reject'] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerAceptStatus: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
