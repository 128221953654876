import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';

import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { TelegramCreate } from '../type';
import { EditTelegram } from '../api';

interface IHook {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditTelegram = ({ id = '', setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: TelegramCreate) => EditTelegram({ values, id }),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Telegram havola muvaffaqiyatli tahrirlandi.',
      });
      queryClient.invalidateQueries({ queryKey: ['telegram_list'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerTelegramEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
