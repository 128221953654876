import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

import { SoilList } from 'modules/soil/type';
import { useEditSoil } from 'modules/soil/hooks/useEditSoil';
import { useCreateSoil } from 'modules/soil/hooks/useCreateSoil';
import { addressSchema, editAdrressSvhema } from 'pages/Lab/LabForm';
import { useGetReegionList } from 'modules/region/hooks/useGetRegionList';
import { useGetDistrictsList } from 'modules/districts/hooks/useGetDistrictsList';
import { useGetNeighborhoodList } from 'modules/neighborhood/hooks/useGetNeighborhoodList';
import AddressFilterField from 'components/fields/AddressFilter';

const soilSchema = z.object({
  title: z.string().min(3, { message: 'nomi talab qilinadi' }),
  phone: z
    .string()
    .min(9, { message: 'Phone number is required' })
    .max(12, { message: 'Phone number is required' }),
  address: addressSchema,
  owner: z.string().min(3),
  regionEdit: editAdrressSvhema.optional(),
  districtEdit: editAdrressSvhema.optional(),
  neigborhoodEdit: editAdrressSvhema.optional(),
});

type soilFormSchema = z.infer<typeof soilSchema>;

interface IProps {
  soil?: SoilList;
  setSheetOpen: (state: boolean) => void;
}

export default function SoilForm({ soil, setSheetOpen }: IProps) {
  const { triggerSoilCreate, isPending: isCreatePending } = useCreateSoil({
    setSheetOpen,
  });
  const { triggerSoilEdit, isPending: isEditPending } = useEditSoil({
    id: soil?._id,
    setSheetOpen,
  });

  const form = useForm<soilFormSchema>({
    resolver: zodResolver(soilSchema),
    defaultValues: soil
      ? {
          title: soil.title ?? '',
          address: {
            region: soil.address.region ?? '',
            region_id: soil.address.region_id ?? 0,
            district: soil.address.district ?? '',
            district_id: soil.address.district_id ?? 0,
            neighborhood: soil.address.neighborhood ?? '',
            neighborhood_id: soil.address.neighborhood_id ?? 0,
            address: soil.address.address ?? '',
          },
          regionEdit: {
            name: soil.address.region ?? '',
            code: soil?.address.region_id ?? 0,
          },
          districtEdit: {
            name: soil.address.district ?? '',
            code: soil.address.district_id ?? 0,
          },
          neigborhoodEdit: {
            name: soil.address.neighborhood ?? '',
            code: soil.address.neighborhood_id ?? 0,
          },
          owner: soil.owner ?? '',
          phone: soil.phone ?? '',
        }
      : {
          title: '',
          address: undefined,
          owner: '',
          phone: '',
        },
  });
  const { data: region } = useGetReegionList();
  const regionId = form.watch('address.region_id');
  const districtId = form.watch('address.district_id');

  const { data: districts } = useGetDistrictsList(regionId);
  const { data: neighborhood } = useGetNeighborhoodList(regionId, districtId);

  async function onSubmit(formValues: soilFormSchema) {
    delete formValues.regionEdit;
    delete formValues.districtEdit;
    delete formValues.neigborhoodEdit;
    if (soil) {
      triggerSoilEdit(formValues);
    } else {
      triggerSoilCreate(formValues);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Nomi" required />
          <AddressFilterField
            name="regionEdit"
            label="Labaratoriya viloyat nomi"
            data={region}
            required
            regionFieldName="address.region"
            regionIdFieldName="address.region_id"
          />
          <AddressFilterField
            name="districtEdit"
            label="Labaratoriya tuman nomi"
            data={districts}
            required
            regionFieldName="address.district"
            regionIdFieldName="address.district_id"
          />
          <AddressFilterField
            name="neigborhoodEdit"
            label="Labaratoriya mahalla nomi"
            data={neighborhood}
            required
            regionFieldName="address.neighborhood"
            regionIdFieldName="address.neighborhood_id"
          />
          <TextField name="address.address" label="Manzili" required />
          <TextField name="owner" label="Raxbari" required />
          <TextField name="phone" label="Raqami" required />

          {soil ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
