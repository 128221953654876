import { Link, NavLink } from 'react-router-dom';
import {
  BookAudio,
  Newspaper,
  LibraryBig,
  ScrollText,
  BadgeDollarSign,
  AlignVerticalJustifyCenter,
  Archive,
  MapPin,
  Globe,
  MessageCircle,
  Handshake,
  BarChart2,
  Apple,
  User,
} from 'lucide-react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/styleUtils';

interface IProps {
  isSideNavOpen: boolean;
}

const SideNav = ({ isSideNavOpen }: IProps) => {
  const menuItems = [
    {
      title: "Bozor e'lonlari",
      icon: BadgeDollarSign,
      link: '/',
    },
    {
      title: "Mahalliy e'lonlar",
      icon: MapPin,
      link: '/local-ads',
    },
    {
      title: "Horijiy e'lonlar",
      icon: Globe,
      link: '/international-ads',
    },
    {
      title: 'Video darsliklar',
      icon: BookAudio,
      link: '/courses',
    },

    {
      title: 'Yangiliklar',
      icon: Newspaper,
      link: '/news',
    },
    {
      title: 'Kitoblar',
      icon: LibraryBig,
      link: '/books',
    },
    {
      title: "Ma'lumotlar",
      icon: ScrollText,
      link: '/informations',
    },
    {
      title: 'Xizmatlar',
      icon: Archive,
      link: '/services',
    },
    {
      title: 'Video Kategoriyalar',
      icon: AlignVerticalJustifyCenter,
      link: '/categories',
    },
    {
      title: 'Bizning Hamkorlarimiz',
      icon: Handshake,
      link: '/our-customers',
    },
    {
      title: 'Fermerlarning fikr-mulohazalari',
      icon: MessageCircle,
      link: '/fermer-feedbacks',
    },
    {
      title: 'Mehnat unumdorligi statistika',
      icon: BarChart2,
      link: '/statistics',
    },
    {
      title: 'Faoliyat turi',
      icon: Apple,
      link: '/activity',
    },
    {
      title: 'Oddiy Foydalanuvchilar',
      icon: User,
      link: '/users',
    },
  ];

  return (
    <aside
      className={cn(
        { 'w-full': isSideNavOpen },
        'sticky top-0 max-w-72 flex flex-col border-solid border-r-2 h-screen'
      )}
    >
      <header className="z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-2">
        <Link
          to="/"
          className={`flex h-10 items-center gap-2 px-4 dark:text-white ${
            isSideNavOpen ? 'justify-start' : 'justify-center'
          }`}
        >
          <img src="./Young_fermer_logo.svg" alt="Logo" />
          {isSideNavOpen && <h1 className="font-semibold">Yosh fermerlar</h1>}
          {/* <Dices /> */}
        </Link>
      </header>
      <div className="flex flex-col gap-1 p-2">
        {menuItems.map((item, index) => (
          <NavLink
            to={item.link}
            key={index}
            className={({ isActive }) =>
              cn({ 'bg-secondary': isActive }, 'dark:text-white rounded block')
            }
          >
            <Button
              variant="ghost"
              className={`w-full ${
                isSideNavOpen ? 'justify-start' : 'justify-center'
              }`}
            >
              <item.icon className="size-5 stroke-[1.3px]" />
              {isSideNavOpen && <span className="ml-3">{item.title}</span>}
            </Button>
          </NavLink>
        ))}
      </div>
    </aside>
  );
};

export default SideNav;
