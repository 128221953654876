import { useState } from "react";
import { Informations } from "modules/information/types";
import { DataTable } from "components/DataTable";
import Loader from "components/Loader";
import { TableActions } from "components/TableActions";
import { AlertDialog } from "components/AlertDialog";
import { Sheet } from "components/Sheet";
import InfoForm from "./InformationForm";
import { createReviewGameColumns } from "./Columns";
import { useInformationList } from "modules/information/hooks/useInformationsList";
import { useDeleteInformation } from "modules/information/hooks/useDeleteInformations";

const Info = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [info, setInfo] = useState<Informations>();
  const { data: InfoList, isLoading } = useInformationList();
  const { triggerInformationDelete } = useDeleteInformation(info?._id!);

  const getRowData = (reviewGame: Informations) => {
    setInfo(reviewGame);
  };

  const columns = createReviewGameColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <h1 className="text-xl mb-3 font-medium">
        Ma'lumotlar ({InfoList?.length})
      </h1>
      {/* <TableActions
        sheetTriggerTitle="Ma'lumot qo'shish"
        sheetTitle="Yangi ma'lumot qo'shish"
        TableForm={InfoForm}
      /> */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable columns={columns} data={InfoList} navigateTable />
        </>
      )}

      <Sheet
        sheetTitle="Ma'lumotni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <InfoForm info={info} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerInformationDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Info;
