import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import useFileUploader from 'hooks/useFileUploader';

import { Form } from 'components/ui/form';
import { FileField, SelectField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { ServicesCreate, ServicesList } from 'modules/sevices/type';
import { servicesType } from 'constants/general';
import { useCreateServices } from 'modules/sevices/hooks/useCreateServices';
import { useEditServices } from 'modules/sevices/hooks/useEditServices';

const newsSchema = z.object({
  title: z.string().min(3, { message: 'Yanglik nomi talab qilinadi' }),
  type: z.string().min(3, { message: 'talb' }),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type newsFormSchema = z.infer<typeof newsSchema>;

interface IProps {
  services?: ServicesList;
  setSheetOpen: (state: boolean) => void;
}

export default function ServicesForm({ services, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();

  const { triggerServicesCreate, isPending: isCreatePending } =
    useCreateServices({
      setSheetOpen,
    });
  const { triggerServicesEdit, isPending: isEditPending } = useEditServices({
    id: services?._id,
    setSheetOpen,
  });

  const form = useForm<newsFormSchema>({
    resolver: zodResolver(newsSchema),
    defaultValues: services
      ? {
          title: services.title,
          type: services.type,
          image: services.image,
        }
      : {
          title: '',
          type: '',
          image: undefined,
        },
  });

  async function onSubmit(formValues: newsFormSchema) {
    const values = await uploadFile<ServicesCreate>(formValues, 'image');
    if (services) {
      triggerServicesEdit(values);
    } else {
      triggerServicesCreate(values);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Xizmat nomi" required />

          <FileField name="image" label="Xizmat rasmi" required />
          <SelectField
            name="type"
            label="Bo'lim nomi"
            data={servicesType}
            required
          />
          {services ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
