import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getLocalAdsList } from "../adapter";
import { GetLocalAdsAprove } from "../api";

export const useLocalAdsAproveList = () => {
  const initialData = {
    data: getLocalAdsList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["aprove_locals"],
    queryFn: () => GetLocalAdsAprove(),
    select: (data) => ({
      data: getLocalAdsList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
