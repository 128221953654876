import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { CreateActivity } from '../type';
import { CreateActivities } from '../api';

interface IHook {
  setSheetOpen: (state: boolean) => void;
}
export const useActivityCreate = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();
  const { mutate, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: CreateActivity) => CreateActivities(values),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'muvaffaqiyatli yaratildi.',
      });
      queryClient.invalidateQueries({ queryKey: ['activity_list'] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });
  return {
    triggerActivityCreate: mutate,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
