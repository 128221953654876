import { useQuery } from '@tanstack/react-query';
import { getSoilData } from '../adapter';
import { GetSoilList } from '../api';
import { get } from 'lodash';

export const useGetSoilList = (currentPage: number) => {
  const initData = {
    data: getSoilData(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['soil_list', currentPage],
    queryFn: () => GetSoilList(currentPage),
    select: data => ({ data: getSoilData(get(data, 'data')) }),
  });
  return {
    ...data,
    ...args,
  };
};
