import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grandinfo } from 'modules/grandInformation/types';

import { DataTable } from 'components/DataTable';
import { TableActions } from 'components/TableActions';
import Loader from 'components/Loader';
import GarndinfoForm from './GrandinfoForm';
import { createLessonColumns } from './Columns';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';
import { useGrandInfoList } from 'modules/grandInformation/hooks/useGrandinfoList';
import { useDeleteGrandInfo } from 'modules/grandInformation/hooks/useDeleteGrandinfo';

const GrandInformation = () => {
  const { moduleId } = useParams();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [granInfo, setGranInfo] = useState<Grandinfo>();
  const { data: grandInfoList, isLoading } = useGrandInfoList(moduleId!);
  const { triggerGrandInfoDelete } = useDeleteGrandInfo(granInfo?._id!);

  const getRowData = (lesson: Grandinfo) => {
    setGranInfo(lesson);
  };

  const columns = createLessonColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Zararkunanda qo'shish"
        sheetTitle="Yangi zararkunanda qo'shish."
        TableForm={GarndinfoForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={grandInfoList} />
      )}

      <Sheet
        sheetTitle="Zararkunandani tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <GarndinfoForm granInfo={granInfo} setSheetOpen={setSheetOpen} />
      </Sheet>

      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerGrandInfoDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default GrandInformation;
