import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { CreateOurCustomers } from "../api";
import { OurCustomers, OurCustomersCreate } from "../type";

interface IHook {
  setSheetOpen: (state: boolean) => void;
}

export const useCreateOurCustomers = ({ setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutateAsync, isPending, isSuccess, isError, data } = useMutation({
    mutationFn: (values: OurCustomers | OurCustomersCreate) =>
      CreateOurCustomers(values),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Yangilik muvaffaqiyatli yaratildi.",
      });
      queryClient.invalidateQueries({ queryKey: ["our_customers"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerOurCustomersCreate: mutateAsync,
    isPending,
    isSuccess,
    isError,
    data,
  };
};
