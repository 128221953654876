import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { TelegramList } from 'modules/telegram/type';
import { Link } from 'react-router-dom';

interface IProps {
  getRowData: (data: TelegramList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createNewsColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<TelegramList>[] => [
  {
    accessorKey: 'title',
    header: 'Sarlavha',
  },
  {
    accessorKey: 'link',
    header: 'havola',
    cell: ({ row }) => (
      <Link
        to={row.getValue('link')}
        className="hover:underline text-blue-500"
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        Havola
      </Link>
    ),
  },
  {
    accessorKey: 'image',
    header: 'Havola rasmi',
    cell: ({ row }) => {
      const imageUrl = row.getValue<string>('image');
      const fullImageUrl = `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`;
      return imageUrl ? (
        <img src={fullImageUrl} alt="news" style={{ width: 50, height: 50 }} />
      ) : (
        <span>Rasm yuklanmagan</span>
      );
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
