import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { getEksportData } from '../adapter';
import { GetEksportList } from '../api';

export const useGetEksportList = (currentPage: number) => {
  const initData = {
    data: getEksportData(),
  };
  const { data = initData, ...args } = useQuery({
    queryKey: ['eksport_list', currentPage],
    queryFn: () => GetEksportList(currentPage),
    select: data => ({
      data: getEksportData(get(data, 'data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
