import { ColumnDef } from "@tanstack/react-table";
import { DataTableRowActions } from "components/DataTableRowActions";
import { LocalGrainList } from "modules/information/types";

import { ForeignGrainList } from "modules/information/types";

interface IProps {
  getRowData: (data: LocalGrainList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createInformationTypesColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<LocalGrainList>[] => [
  {
    accessorKey: "company_name",
    header: "Company Name",
    cell: ({ row }) => <span>{row.original.company_name}</span>,
  },
  {
    accessorKey: "company_owner",
    header: "Company Owner",
    cell: ({ row }) => <span>{row.original.company_owner}</span>,
  },
  {
    accessorKey: "company_owner_phone",
    header: "Owner Phone",
    cell: ({ row }) => <span>{row.original.company_owner_phone}</span>,
  },
  {
    accessorKey: "plant_type",
    header: "Plant Type",
    cell: ({ row }) => <span>{row.original.plant_type}</span>,
  },
  {
    accessorKey: "reccomendation_region",
    header: "Recommended Region",
    cell: ({ row }) => <span>{row.original.reccomendation_region}</span>,
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
]; // Assuming ImportType is defined here

interface IPropss {
  getRowDataImportType: (data: ForeignGrainList) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createInformationImportTypesColumns = ({
  getRowDataImportType,
  setSheetOpen,
  setDialogOpen,
}: IPropss): ColumnDef<ForeignGrainList>[] => [
  {
    accessorKey: "address",
    header: "Address",
    cell: ({ row }) => <span>{row.original.address}</span>,
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    cell: ({ row }) => <span>{row.original.company_name}</span>,
  },
  {
    accessorKey: "country",
    header: "Country",
    cell: ({ row }) => <span>{row.original.country}</span>,
  },
  {
    accessorKey: "inn",
    header: "INN",
    cell: ({ row }) => <span>{row.original.inn}</span>,
  },
  {
    accessorKey: "phone_number",
    header: "Phone Number",
    cell: ({ row }) => <span>{row.original.phone_number}</span>,
  },
  {
    accessorKey: "product_name",
    header: "Product Name",
    cell: ({ row }) => <span>{row.original.product_name}</span>,
  },
  {
    accessorKey: "sort_type",
    header: "Sort Type",
    cell: ({ row }) => <span>{row.original.sort_type}</span>,
  },
  {
    accessorKey: "type_of",
    header: "Type Of",
    cell: ({ row }) => <span>{row.original.type_of}</span>,
  },
  {
    accessorKey: "_id",
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowDataImportType}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
