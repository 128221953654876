import { DataTable } from "components/DataTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { useGetInformation } from "modules/information/hooks/useGetInformation";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  createInformationImportTypesColumns,
  createInformationTypesColumns,
} from "./Column";
import { LocalGrainList } from "modules/information/types";
import { ForeignGrainList } from "modules/information/types";
import { AlertDialog } from "components/AlertDialog";

const InformationTabs = () => {
  const { infoId } = useParams();
  const [typePlant, setTypePlant] = useState<LocalGrainList | any>();
  const [typeImport, setTypeImport] = useState<ForeignGrainList | any>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);

  const { data_plant_info: LocalGrainList } = useGetInformation(infoId!);
  const { data_import_info: ForeignGrainList } = useGetInformation(infoId!);

  const [selectedTab, setSelectedTab] = useState("tab1");

  const getRowData = (typePlantState: LocalGrainList) => {
    setTypePlant(typePlantState);
  };

  const getRowDataImportType = (typeImport: ForeignGrainList) => {
    setTypeImport(typeImport);
  };

  const columns = createInformationTypesColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  const importColumns = createInformationImportTypesColumns({
    getRowDataImportType,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <Tabs value={selectedTab} onValueChange={setSelectedTab}>
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="tab1">Mahalliy Urug</TabsTrigger>
            <TabsTrigger value="tab2">Xorijiy Urug</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="tab1">
          <DataTable columns={columns} data={LocalGrainList.slice(0, 20)} />
        </TabsContent>
        <TabsContent value="tab2">
          <DataTable
            columns={importColumns}
            data={ForeignGrainList.slice(0, 20)}
          />
        </TabsContent>
      </Tabs>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={() => {}}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default InformationTabs;
