import { useQuery } from '@tanstack/react-query';
import { getDistrictsList } from '../adapter';
import { GetDistricts } from '../api';

export const useGetDistrictsList = (region_code: number) => {
  const initData = {
    data: getDistrictsList(),
  };

  const { data = initData, ...args } = useQuery({
    queryKey: ['districts_list', region_code],
    queryFn: () => GetDistricts(region_code),
    enabled: !!region_code,
  });
  return {
    ...data,
    ...args,
  };
};
