import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";

import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { InternationalCreate } from "../type";
import { EditInternational } from "../api";

interface IHook {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditInternational = ({ id = "", setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: InternationalCreate) =>
      EditInternational({ values, id }),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Tasdiqlandi!",
        description: "O'yin tahlili muvaffaqiyatli tahrirlandi.",
      });
      queryClient.invalidateQueries({
        queryKey: ["in_progress_international"],
      });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerInternationalEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
