export interface Address {
  region: string;
  district: string;
  neighborhood: string;
  region_id: number;
  district_id: number;
  neighborhood_id: string;
}
export interface Farmer {
  fio: string;
  phone: string;
  _id: string;
}
export interface Market {
  _id: string;
  product: string;
  category: string;
  price_per_unit: number;
  image: string;
  unit: string;
  address: Address;
  status: STATUS;
  created_at: Date;
  farmer: Farmer;
  quantity: number;
  comment: string;
}

export enum STATUS {
  ACTIVE = 'approved',
  INPROGRESS = 'in progress',
  REJECTED = 'rejected',
}
