import { TextField } from "components/fields";
import { Button } from "components/ui/button";
import { FormLabel } from "components/ui/form";
import { Plus, Trash2 } from "lucide-react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface IProps {
  name: string;
  addBtnTitle: string;
  label?: string;
  required?: boolean;
}

export default function TopCountriesRichInputs({
  name,
  label,
  required,
  addBtnTitle,
}: IProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name, control });

  return (
    <div>
      {label && (
        <FormLabel>
          {`${label} `}
          {required && (
            <span className="text-red-500 dark:text-red-900">*</span>
          )}
        </FormLabel>
      )}
      {fields.map((field, index) => (
        <div key={field.id} className="mb-5 mt-[50px] flex flex-col gap-5">
          <TextField
            name={`${name}[${index}].country_name`}
            label="Shahar nomi"
            required
          />
          <TextField
            name={`${name}[${index}].country_quantity`}
            label="Soni"
            type="number"
            required
          />
          <TextField
            name={`${name}[${index}].country_unit`}
            label="Hajmi"
            required
          />
          <TextField
            name={`${name}[${index}].export_amount`}
            label="Eksport summasi"
            type="number"
            required
          />
          <TextField
            name={`${name}[${index}].export_unit`}
            label="Eksport birligi"
            required
          />
          {fields.length >= 1 && (
            <Button
              type="button"
              onClick={() => remove(index)}
              className="absolute right-2 text-red-500 p-2 bg-transparent hover:bg-transparent focus:bg-transparent"
            >
              <Trash2 className="size-5" />
            </Button>
          )}
        </div>
      ))}
      <Button
        variant="default"
        type="button"
        onClick={() =>
          append({
            country_name: "",
            country_quantity: undefined,
            country_unit: "",
            export_amount: undefined,
            export_unit: "",
          })
        }
        className="flex items-center"
      >
        <Plus className="size-5 mr-2 text-blue-500 dark:text-white" />
        {addBtnTitle}
      </Button>
    </div>
  );
}
