import http from "services/api";
import {
  TopCountriesCreate,
  TopProductsCreate,
  TopProductsEdit,
  TopCountriesEdit,
  TopProducts,
  TopCountries,
} from "./types";

//top-products*******************************************************
export const GetTopProducts = async () => {
  return await http.get(`/exports/top-products`);
};

export const CreateTopProducts = async (values: TopProductsCreate) => {
  return await http.post<{ data: TopProducts }>(
    `/exports/top-products/`,
    values
  );
};

export const EditTopProducts = async ({ values, id }: TopProductsEdit) => {
  return await http.patch(`/exports/top-products/${id}`, values);
};

export const DeleteTopProducts = async (id: string) => {
  return await http.delete(`/exports/top-products/${id}`);
};

//top-countries*******************************************************
export const GetTopCountries = async () => {
  return await http.get(`/exports/top-countries`);
};

export const CreateTopCountries = async (values: TopCountriesCreate) => {
  return await http.post<{ data: TopCountries }>(
    `/exports/top-countries/`,
    values
  );
};

export const EditTopCountries = async ({ values, id }: TopCountriesEdit) => {
  return await http.patch(`/exports/top-country/${id}`, values);
};

export const DeleteTopCountries = async (id: string) => {
  return await http.delete(`/exports/top-country/${id}`);
};
