import http from 'services/api';
import { ServicesCreate, ServicesEdit } from './type';

export const GetServicesList = async () => {
  return await http.get(`agro-service`);
};
export const ServicesDelte = async (id: string) => {
  return await http.delete(`agro-service/${id}`);
};

export const CreateServices = async (values: ServicesCreate) => {
  return await http.post(`agro-service`, values);
};

export const EditServices = async ({ id, values }: ServicesEdit) => {
  return await http.patch(`agro-service/${id}`, values);
};
