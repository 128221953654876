import { getAddress } from 'modules/lab/adapter';
import { InspektorData, InspektorList } from './type';

export const getInspector = (item?: InspektorList) => {
  return {
    _id: item?._id ?? '',
    inspector_full_name: item?.inspector_full_name ?? '',
    phone_number: item?.phone_number ?? '',
    address: getAddress(item?.address),
    position: item?.position ?? '',
    post_address: item?.post_address ?? '',
  };
};

export const getInspectorList = (data?: InspektorList[]) => {
  return data?.length
    ? data.map(item => {
        return getInspector(item);
      })
    : [];
};

export const getInspectorData = (data?: InspektorData) => {
  return {
    count: data?.count ?? 0,
    current_page: data?.current_page ?? 0,
    total_pages: data?.total_pages ?? 0,
    data: getInspectorList(data?.data),
  };
};
