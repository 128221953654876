import http from 'services/api';
import { EksportCreate, EksportEdit } from './type';

export const GetEksportList = async (currentPage: number) => {
  return await http.get(
    `agro-service/eksportchi-korxonalar?page=${currentPage}&size=10`
  );
};

export const CreateEksport = async (values: EksportCreate) => {
  return await http.post(`agro-service/eksportchi-korxonalar`, values);
};

export const EditEksport = async ({ id, values }: EksportEdit) => {
  return await http.patch(`agro-service/eksportchi-korxonalar/${id}`, values);
};

export const DeleteEksport = async (id: string) => {
  return await http.delete(`agro-service/eksportchi-korxonalar/${id}`);
};
