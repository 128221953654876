import { getAddress } from "modules/lab/adapter";
import { GiantExportersData, GiantExportersList } from "./type";

export const getGiantExporters = (item?: GiantExportersList) => {
  return {
    _id: item?._id ?? "",
    name: item?.name ?? "",
    owner: item?.owner ?? "",
    phone: item?.phone ?? "",
    product_name: item?.product_name ?? "",
    export_country: item?.export_country ?? "",
    address: getAddress(item?.address),
  };
};

export const getGiantExportersList = (data?: GiantExportersList[]) => {
  return data?.length
    ? data.map((item) => {
        return getGiantExporters(item);
      })
    : [];
};

export const getGiantExportersData = (data?: GiantExportersData) => {
  return {
    count: data?.count ?? 0,
    current_page: data?.current_page ?? 0,
    total_pages: data?.total_pages ?? 0,
    data: getGiantExportersList(data?.data),
  };
};
