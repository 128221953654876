import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { getFermerFeedbacksList } from "../adapter";
import { FermerFeedbacksList } from "../api";

export const useGetFermerFeedbacksList = () => {
  const initialData = {
    data: getFermerFeedbacksList(),
    paginationInfo: null,
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["fermer_feedbacks"],
    queryFn: () => FermerFeedbacksList(),
    select: (data) => ({
      data: getFermerFeedbacksList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
