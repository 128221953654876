import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { EditFermerFeedbacks } from "../api";
import { FermerFeedbacks, FermerFeedbacksEditBody } from "../type";

interface IHook {
  id?: string;
  setSheetOpen: (state: boolean) => void;
}

export const useEditFermerFeedbacks = ({ id = "", setSheetOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: FermerFeedbacksEditBody | FermerFeedbacks | any) =>
      EditFermerFeedbacks({ values, id }),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Muvaffaqiyat!",
        description: "Yangilik muvaffaqiyatli tahrirlandi.",
      });
      queryClient.invalidateQueries({ queryKey: ["fermer_feedbacks"] });
      setSheetOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerFermerFeedbacksEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
