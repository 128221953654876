import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from 'components/LoadingButton';
import { MultiInputField, TextField } from 'components/fields';
import { Form } from 'components/ui/form';
import { useActivityCreate } from 'modules/activity/hooks/useCreateActivity';
import { useEditActivity } from 'modules/activity/hooks/useEditActivity';
import { Activity } from 'modules/activity/type';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const activitySchema = z.object({
  name: z.string().min(3, { message: ' nomi talab qilinadi' }),
  subcategories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

type activityFormSchema = z.infer<typeof activitySchema>;

interface IProps {
  activtiy?: Activity;
  setSheetOpen: (state: boolean) => void;
}

export default function ActivityForm({ activtiy, setSheetOpen }: IProps) {
  const { triggerActivityCreate, isPending: isCreatePending } =
    useActivityCreate({ setSheetOpen });

  const { triggerActivityEdit, isPending: isEditPending } = useEditActivity({
    id: activtiy?._id,
    setSheetOpen,
  });
  const form = useForm<activityFormSchema>({
    resolver: zodResolver(activitySchema),
    defaultValues: activtiy
      ? {
          name: activtiy?.name ?? '',
          subcategories: activtiy.subcategories ?? [],
        }
      : {
          name: '',
          subcategories: [],
        },
  });

  async function onSubmit(formValues: activityFormSchema) {
    if (activtiy) {
      triggerActivityEdit(formValues);
    } else {
      triggerActivityCreate(formValues);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-4"
      >
        <TextField name="name" label="Kategoriya nomi" required />
        <MultiInputField
          typeOne="number"
          name="subcategories"
          objName="id"
          objDesc="name"
          label="Sub categoriy"
          addBtnTitle="Qoshish"
          descriptionOne="sub id"
          descriptionTwo="sub name"
        />
        {activtiy ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
