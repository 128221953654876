import React, { useContext } from 'react';

import MainLayout from 'layout/MainLayout';
import AuthLayout from 'layout/AuthLayout';
import { Routes as DOMRoutes, Route } from 'react-router-dom';
import { Toaster } from 'components/ui/toaster';
import { useRefreshToken } from 'modules/auth/hooks/useRefreshToken';
import AuthPage from 'pages/Auth';
import CoursesPage from 'pages/Courses';
import SubInfoPage from 'pages/SubInfo';
import GrandInformationPage from 'pages/GrandInformation';
import NewsPage from 'pages/News';
import BooksPage from 'pages/Books';
import InfoPage from 'pages/Information';
import { AuthContext } from 'providers/auth';
import MarketPage from 'pages/Market';
import SerivecesPage from 'pages/Services';
import LabPag from 'pages/Lab';
import TelegramPage from 'pages/Telegram';
import AboutPage from 'pages/About';
import SoilPage from 'pages/Soil';
import EksportPage from 'pages/Eksport';
import InspectorsPage from 'pages/Inspector';
import CategoriesPage from 'pages/Categories';
import GiantExportersPage from 'pages/GiantExporters';
import PackingCompaniesPage from 'pages/PackingCompanies';
import LocalAdsPage from 'pages/LocalAds/LocalAds';
import InternationalAdsPage from 'pages/InternationalAds';
import InformationFilePage from 'pages/InformationFile';
import InformationTabsPage from 'pages/InformationTypes/InformationTypes';
import FermerFeedbacksPage from 'pages/FermerFeedbacks/FermerFeedbacks';
import OurCustomersPage from 'pages/OurCustomers/OurCustomers';
import StatisticsPage from 'pages/Statistics/Statistics';
import ActivityPage from 'pages/Activity';
import BasicUsersPage from 'pages/Users/Users';

export const Routes = () => {
  const { isLoading } = useRefreshToken();
  const { isAuthenticated } = useContext(AuthContext);
  const routes = [
    {
      path: '/',
      element: <MarketPage />,
    },
    {
      path: '/local-ads',
      element: <LocalAdsPage />,
    },
    {
      path: '/international-ads',
      element: <InternationalAdsPage />,
    },
    {
      path: '/courses',
      element: <CoursesPage />,
    },
    {
      path: '/news',
      element: <NewsPage />,
    },
    {
      path: '/books',
      element: <BooksPage />,
    },

    {
      path: '/informations',
      element: <InfoPage />,
    },
    {
      path: '/informations/:courseId',
      element: <SubInfoPage />,
    },
    {
      path: '/informations/:courseId/:moduleId',
      element: <GrandInformationPage />,
    },
    {
      path: '/activity',
      element: <ActivityPage />,
    },
    {
      path: 'informations/file/:infoId',
      element: <InformationFilePage />,
    },
    {
      path: 'informations/types/:infoId',
      element: <InformationTabsPage />,
    },
    {
      path: '/services',
      element: <SerivecesPage />,
    },
    {
      path: '/services/laboratoriyalar',
      element: <LabPag />,
    },
    {
      path: '/services/telegram',
      element: <TelegramPage />,
    },
    {
      path: '/services/about',
      element: <AboutPage />,
    },
    {
      path: '/services/tuproq',
      element: <SoilPage />,
    },
    {
      path: '/services/inspektor',
      element: <InspectorsPage />,
    },
    {
      path: '/services/eksportchi',
      element: <EksportPage />,
    },
    {
      path: '/services/giant-exporters',
      element: <GiantExportersPage />,
    },
    {
      path: '/services/packing-companies',
      element: <PackingCompaniesPage />,
    },
    {
      path: '/categories',
      element: <CategoriesPage />,
    },
    {
      path: '/our-customers',
      element: <OurCustomersPage />,
    },
    {
      path: '/fermer-feedbacks',
      element: <FermerFeedbacksPage />,
    },
    {
      path: '/statistics',
      element: <StatisticsPage />,
    },
    {
      path: '/users',
      element: <BasicUsersPage />,
    },
  ];

  return (
    <>
      <>
        {isLoading ? null : isAuthenticated ? (
          <MainLayout>
            <DOMRoutes>
              {routes.map(route => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              ))}
            </DOMRoutes>
          </MainLayout>
        ) : (
          <AuthLayout>
            <DOMRoutes>
              <Route path="/" element={<AuthPage />} />
              <Route path="*" element={<AuthPage />} />
            </DOMRoutes>
          </AuthLayout>
        )}
      </>

      <Toaster />
    </>
  );
};
