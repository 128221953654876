import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FermerFeedbacks } from "modules/fermer-feedbacks/type";
import { useCreateFermerFeedbacks } from "modules/fermer-feedbacks/hooks/useCreateFermerFeedbacks";
import { useEditFermerFeedbacks } from "modules/fermer-feedbacks/hooks/useEditFermerFeedbacks";
import { FileField, TextField } from "components/fields";
import LoadingButton from "components/LoadingButton";
import { Form } from "components/ui/form";
import useFileUploader from "hooks/useFileUploader";

const fermerFeedbacksSchema = z.object({
  link: z.string(),
  title: z.string().min(3, { message: "Video nomi talab qilinadi" }),
  image: z.union([
    z.custom<File>((file) => file instanceof File, {
      message: "Rasm talab qilinadi",
    }),
    z.string().min(1, { message: "Rasm talab qilinadi" }),
  ]),
  description: z.string().min(3, { message: "Paragraf talab qilinadi" }),
});

type fermerFeedBacksFormSchema = z.infer<typeof fermerFeedbacksSchema>;

interface IProps {
  fermerFeedbacks?: FermerFeedbacks;
  setSheetOpen: (state: boolean) => void;
}
export default function FermerFeedbacksForm({
  fermerFeedbacks,
  setSheetOpen,
}: IProps) {
  const { uploadFile } = useFileUploader();

  const { triggerFermerFeedbacksCreate, isPending: isNewsCreatePending } =
    useCreateFermerFeedbacks({
      setSheetOpen,
    });
  const { triggerFermerFeedbacksEdit, isPending: isNewsEditPending } =
    useEditFermerFeedbacks({
      id: fermerFeedbacks?._id,
      setSheetOpen,
    });

  const form = useForm<fermerFeedBacksFormSchema>({
    resolver: zodResolver(fermerFeedbacksSchema),
    defaultValues: fermerFeedbacks
      ? {
          link: fermerFeedbacks.link,
          title: fermerFeedbacks.title,
          image: fermerFeedbacks.image,
          description: fermerFeedbacks.description,
        }
      : {
          link: "",
          title: "",
          image: undefined,
          description: "",
        },
  });

  async function onSubmit(formValues: fermerFeedBacksFormSchema) {
    const values = await uploadFile<any>(formValues, "image");

    if (fermerFeedbacks) {
      triggerFermerFeedbacksEdit(values);
    } else {
      await triggerFermerFeedbacksCreate(values);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="link" label="Video ID (RD5dWeeUIZFgA)" required />
          <TextField name="title" label="Video nomi" required />
          <FileField name="image" label="Video rasmi" required />
          <TextField name="description" label="Video haqida malumot" required />

          <LoadingButton isLoading={isNewsCreatePending || isNewsEditPending}>
            Saqlash
          </LoadingButton>
        </form>
      </Form>
    </>
  );
}
