import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import useFileUploader from 'hooks/useFileUploader';

import { Form } from 'components/ui/form';
import { FileField, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

import { TelegramCreate, TelegramList } from 'modules/telegram/type';
import { useEditTelegram } from 'modules/telegram/hooks/useEditTelegram';
import { useCreateTelegram } from 'modules/telegram/hooks/useCreateTelegram';

const tgSchema = z.object({
  title: z.string().min(3, { message: 'Yanglik nomi talab qilinadi' }),
  link: z.string().min(3, { message: 'talb' }),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type tgFormSchema = z.infer<typeof tgSchema>;

interface IProps {
  tg?: TelegramList;
  setSheetOpen: (state: boolean) => void;
}

export default function TelegramForm({ tg, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();

  const { triggerTelegramCreate, isPending: isCreatePending } =
    useCreateTelegram({
      setSheetOpen,
    });
  const { triggerTelegramEdit, isPending: isEditPending } = useEditTelegram({
    id: tg?._id,
    setSheetOpen,
  });

  const form = useForm<tgFormSchema>({
    resolver: zodResolver(tgSchema),
    defaultValues: tg
      ? {
          title: tg.title,
          link: tg.link,
          image: tg.image,
        }
      : {
          title: '',
          link: '',
          image: undefined,
        },
  });

  async function onSubmit(formValues: tgFormSchema) {
    const values = await uploadFile<TelegramCreate>(formValues, 'image');
    if (tg) {
      triggerTelegramEdit(values);
    } else {
      triggerTelegramCreate(values);
    }
  }

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4"
        >
          <TextField name="title" label="Havola nomi" required />

          <FileField name="image" label="Havola rasmi" required />
          <TextField name="link" label="Havola nomi" required />

          {tg ? (
            <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
          ) : (
            <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
          )}
        </form>
      </Form>
    </>
  );
}
