import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from 'components/ui/form';
import { Grandinfo } from 'modules/grandInformation/types';
import { TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import MultiRichAndTextField from 'components/fields/MultiRichAndText';
import MultiFileField from 'components/fields/MultiFile';
import { useMultiFileUploader } from 'hooks/useFileUploader';
import { useCreateGarndinfo } from 'modules/grandInformation/hooks/useCreateGrandinfo';
import { useEditGrandinfo } from 'modules/grandInformation/hooks/useEditGrandinfo';
import { useParams } from 'react-router-dom';

const lessonSchema = z.object({
  title: z.string().min(3),
  description: z.string().min(3),
  headlines: z.array(
    z.object({
      title: z.string().min(3),
      description: z.string().min(20),
    })
  ),
  images: z.array(z.union([z.custom(file => file), z.string()])).optional(),
});

type lessonFormSchema = z.infer<typeof lessonSchema>;

interface IProps {
  granInfo?: Grandinfo;
  setSheetOpen: (state: boolean) => void;
}

export default function LessonForm({ granInfo, setSheetOpen }: IProps) {
  const { moduleId } = useParams();
  const { uploadFiles } = useMultiFileUploader();
  const { triggerGrandinfoCreate, isPending: isCreatePending } =
    useCreateGarndinfo({ setSheetOpen });
  const { triggerGrandinfoEdit, isPending: isEditPending } = useEditGrandinfo({
    id: granInfo?._id!,
    setSheetOpen,
  });

  const form = useForm<lessonFormSchema>({
    resolver: zodResolver(lessonSchema),
    defaultValues: granInfo
      ? {
          title: granInfo.title,
          description: granInfo.description,
          headlines: granInfo.headlines,
          images: granInfo.images,
        }
      : {
          title: '',
          description: '',
          headlines: [{ title: '', description: '' }],
          images: undefined,
        },
  });

  async function onSubmit(formValues: lessonFormSchema) {
    const values = await uploadFiles<Grandinfo>(formValues, 'images');
    if (granInfo) {
      triggerGrandinfoEdit({ ...values, parent_information: moduleId! });
    } else {
      triggerGrandinfoCreate({ ...values, parent_information: moduleId! });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Nomi" required />
          <TextField name="description" label="Tavsif" required />
          <MultiFileField name="images" label="Rasim" required />
          <MultiRichAndTextField
            name="headlines"
            label="Sarlavhalar"
            descriptionOne="sarlavha"
            descriptionTwo="Tavsifi"
            objDesc="description"
            objName="title"
            addBtnTitle="Qosh"
          />
        </div>
        {granInfo ? (
          <LoadingButton isLoading={isEditPending}>Tahrirlash</LoadingButton>
        ) : (
          <LoadingButton isLoading={isCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
